import { Container, Title, Text, Paper } from '@mantine/core';
import { gradientText, paperGradient } from '../styles/common';

export function AboutPage() {
  return (
    <Container size="md" py="xl">
      <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
        <Title 
          order={1} 
          mb="xl"
          style={gradientText}
        >
          About Us
        </Title>
        <Text mb="xl" size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
          The Fiber Collective is a vibrant community dedicated to celebrating and promoting 
          all forms of fiber arts. Our platform brings together artists, enthusiasts, and 
          learners who share a passion for creating with fiber - whether through knitting, 
          crochet, weaving, spinning, or other textile crafts.
        </Text>
        <Text mb="xl" size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
          Our mission is to make fiber arts accessible to everyone by providing resources, 
          fostering connections between artists, and creating an inclusive space where both 
          beginners and experienced crafters can learn, share, and grow together. Through 
          our platform, we aim to preserve traditional techniques while embracing modern 
          innovations in the fiber arts community.
        </Text>
        <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
          We are a small business located in the United States, and we deeply appreciate 
          your support in helping us grow and nurture this creative community. Every 
          subscription and interaction helps us continue our mission of making fiber arts 
          more accessible while supporting local artisans and creators.
        </Text>
      </Paper>
    </Container>
  );
} 