import { ActionIcon, Stack, Tooltip } from '@mantine/core';
import { IconBrandFacebook, IconBrandX, IconBrandPinterest } from '@tabler/icons-react';

// Custom Threads icon component
const ThreadsIcon = (props: { width: string, height: string }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    width={props.width} 
    height={props.height} 
    strokeWidth="2"
  > 
    <path d="M19 7.5c-1.333 -3 -3.667 -4.5 -7 -4.5c-5 0 -8 2.5 -8 9s3.5 9 8 9s7 -3 7 -5s-1 -5 -7 -5c-2.5 0 -3 1.25 -3 2.5c0 1.5 1 2.5 2.5 2.5c2.5 0 3.5 -1.5 3.5 -5s-2 -4 -3 -4s-1.833 .333 -2.5 1"></path> 
  </svg>
);

export function FloatingSocialButtons() {
  const handleShare = async (platform: 'facebook' | 'twitter' | 'pinterest' | 'threads') => {
    const url = window.location.href;
    const title = document.title;

    switch (platform) {
      case 'facebook':
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
          'facebook-share',
          'width=580,height=296'
        );
        break;

      case 'twitter':
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`,
          'x-share',
          'width=550,height=235'
        );
        break;

      case 'pinterest':
        // Try to get the first image from the page for Pinterest
        const firstImage = document.querySelector('img')?.src || '';
        window.open(
          `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&description=${encodeURIComponent(title)}&media=${encodeURIComponent(firstImage)}`,
          'pinterest-share',
          'width=750,height=550'
        );
        break;
        
      case 'threads':
        // Threads sharing URL format - only sharing the URL
        window.open(
          `https://www.threads.net/intent/post?text=${encodeURIComponent(url)}`,
          'threads-share',
          'width=550,height=700'
        );
        break;
    }
  };

  return (
    <Stack
      style={{
        position: 'fixed',
        bottom: '100px',
        left: '20px',
        zIndex: 1000,
      }}
      gap="xs"
    >
      <Tooltip label="Share on Facebook" position="right">
        <ActionIcon
          variant="filled"
          color="blue"
          size="xl"
          radius="md"
          onClick={() => handleShare('facebook')}
          style={{
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <IconBrandFacebook style={{ width: '60%', height: '60%' }} />
        </ActionIcon>
      </Tooltip>

      <Tooltip label="Share on X" position="right">
        <ActionIcon
          variant="filled"
          color="dark"
          size="xl"
          radius="md"
          onClick={() => handleShare('twitter')}
          style={{
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <IconBrandX style={{ width: '60%', height: '60%' }} />
        </ActionIcon>
      </Tooltip>

      <Tooltip label="Share on Pinterest" position="right">
        <ActionIcon
          variant="filled"
          color="red"
          size="xl"
          radius="md"
          onClick={() => handleShare('pinterest')}
          style={{
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <IconBrandPinterest style={{ width: '60%', height: '60%' }} />
        </ActionIcon>
      </Tooltip>
      
      <Tooltip label="Share on Threads" position="right">
        <ActionIcon
          variant="filled"
          color="dark"
          size="xl"
          radius="md"
          onClick={() => handleShare('threads')}
          style={{
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <ThreadsIcon width="60%" height="60%" />
        </ActionIcon>
      </Tooltip>
    </Stack>
  );
} 