import { QueryClient } from '@tanstack/react-query';

/**
 * Invalidates all event-related queries in the cache.
 * This should be called after any event mutation (create, update, delete, approve, reject).
 * 
 * @param queryClient - The React Query client
 * @param eventId - Optional specific event ID to invalidate
 */
export function invalidateEventQueries(queryClient: QueryClient, eventId?: string) {
  // Invalidate the main events list queries
  queryClient.invalidateQueries({ queryKey: ['events'] });
  
  // Invalidate event-related metadata queries
  queryClient.invalidateQueries({ queryKey: ['event-tags'] });
  queryClient.invalidateQueries({ queryKey: ['event-countries'] });
  
  // Invalidate the specific event if an ID is provided
  if (eventId) {
    queryClient.invalidateQueries({ queryKey: ['event', eventId] });
  }
} 