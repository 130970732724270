import { Modal, Text, Stack, Group, Button } from '@mantine/core';
import { gradientText } from '../styles/common';

interface ConfirmDeleteModalProps {
  opened: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  isLoading?: boolean;
}

/**
 * A reusable modal for confirming deletion actions
 */
export function ConfirmDeleteModal({
  opened,
  onClose,
  onConfirm,
  title,
  message,
  isLoading = false
}: ConfirmDeleteModalProps) {
  return (
    <Modal 
      opened={opened} 
      onClose={onClose}
      title={
        <Text 
          size="lg" 
          fw={600}
          style={gradientText}
        >
          {title}
        </Text>
      }
    >
      <Stack>
        <Text>{message}</Text>
        <Group justify="flex-end">
          <Button variant="light" onClick={onClose}>Cancel</Button>
          <Button color="red" onClick={onConfirm} loading={isLoading}>Delete</Button>
        </Group>
      </Stack>
    </Modal>
  );
} 