import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Title, 
  Text, 
  Paper, 
  Stack, 
  Group, 
  Button, 
  Divider,
  LoadingOverlay,
  Badge,
  Flex
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft, IconArrowRight, IconCheck, IconX } from '@tabler/icons-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { JournalEntry, PatternTestJournal } from '../api/patternTestJournals';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { formatDate } from '../utils/dateUtils';
import { adminApi } from '../api/admin';
import { gradientText, paperGradient } from '../styles/common';
import { JournalEntryCard } from '../components/JournalEntryCard';

export function AdminJournalDetailPage() {
  const { journalId } = useParams<{ journalId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const [journal, setJournal] = useState<PatternTestJournal | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const { data: journalData, isLoading, error, refetch } = useQuery({
    queryKey: ['admin', 'journal', journalId],
    queryFn: () => adminApi.getTestJournalById(journalId!),
    enabled: !!journalId && !!user && user.role === 'Admin'
  });

  useEffect(() => {
    if (journalData) {
      setJournal(journalData);
    }
  }, [journalData]);

  // Handle going back to admin dashboard
  const handleBack = () => {
    navigate('/admin');
  };

  // Handle navigating to pattern page
  const handleGoToPattern = () => {
    if (journal && journal.patternId) {
      navigate(`/patterns/${journal.patternId}`);
    }
  };

  // Handle approval
  const handleApprove = async () => {
    if (!journalId || !journal) return;
    
    if (!journal.isSubmitted) {
      notifications.show({
        title: 'Cannot Approve',
        message: 'The journal must be submitted before it can be approved.',
        color: 'yellow'
      });
      return;
    }

    try {
      setIsUpdating(true);
      await adminApi.approveTestJournal(journalId);
      
      // Refresh the current journal data
      await refetch();
      
      // Invalidate the test journals list
      queryClient.invalidateQueries({ queryKey: ['admin', 'test-journals'] });
      
      notifications.show({
        title: 'Journal Approved',
        message: `Test journal for "${journal.patternName}" has been approved.`,
        color: 'green'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to approve journal',
        color: 'red'
      });
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle rejection
  const handleReject = async () => {
    if (!journalId || !journal) return;
    
    if (!journal.isSubmitted) {
      notifications.show({
        title: 'Cannot Reject',
        message: 'The journal must be submitted before it can be rejected.',
        color: 'yellow'
      });
      return;
    }

    try {
      setIsUpdating(true);
      await adminApi.rejectTestJournal(journalId);
      
      // Refresh the current journal data
      await refetch();
      
      // Invalidate the test journals list
      queryClient.invalidateQueries({ queryKey: ['admin', 'test-journals'] });
      
      notifications.show({
        title: 'Journal Rejected',
        message: `Test journal for "${journal.patternName}" has been rejected.`,
        color: 'red'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to reject journal',
        color: 'red'
      });
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle unapproval
  const handleUnapprove = async () => {
    if (!journalId || !journal) return;

    try {
      setIsUpdating(true);
      await adminApi.unapproveTestJournal(journalId);
      
      // Refresh the current journal data
      await refetch();
      
      // Invalidate the test journals list
      queryClient.invalidateQueries({ queryKey: ['admin', 'test-journals'] });
      
      notifications.show({
        title: 'Journal Unapproved',
        message: `Approval removed from test journal for "${journal.patternName}".`,
        color: 'blue'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to unapprove journal',
        color: 'red'
      });
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle unrejection
  const handleUnreject = async () => {
    if (!journalId || !journal) return;

    try {
      setIsUpdating(true);
      await adminApi.unrejectTestJournal(journalId);
      
      // Refresh the current journal data
      await refetch();
      
      // Invalidate the test journals list
      queryClient.invalidateQueries({ queryKey: ['admin', 'test-journals'] });
      
      notifications.show({
        title: 'Journal Unrejected',
        message: `Rejection removed from test journal for "${journal.patternName}".`,
        color: 'blue'
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to unreject journal',
        color: 'red'
      });
    } finally {
      setIsUpdating(false);
    }
  };

  // Show loading state
  if (isLoading) {
    return (
      <Container size="md" py="xl">
        <LoadingOverlay visible={true} />
      </Container>
    );
  }

  // Handle error state
  if (error || !journal) {
    return (
      <Container size="md" py="xl">
        <Paper p="xl" shadow="md" radius="lg" withBorder>
          <Title order={2} mb="md">Error Loading Journal</Title>
          <Text>
            There was an error loading the test journal. 
            {error instanceof Error ? ` Error: ${error.message}` : ''}
          </Text>
          <Button onClick={handleBack} mt="lg" leftSection={<IconArrowLeft size={16} />}>
            Back to Admin Dashboard
          </Button>
        </Paper>
      </Container>
    );
  }

  // Sort entries by date (newest first) like in the regular journal page
  const sortedEntries = journal.entries
    ? [...journal.entries].sort((a, b) => 
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    : [];

  return (
    <Container size="md" py="xl">
      <Helmet>
        <title>Test Journal for {journal.patternName} | Admin Dashboard</title>
      </Helmet>
      
      <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
        <Stack gap="xs">
          <Flex justify="space-between" align="center" mb={10}>
            <Button
              variant="subtle"
              size="lg"
              onClick={handleBack}
              leftSection={<IconArrowLeft />}
              style={{ paddingBottom: 4 }}
            >
              Back to Admin Dashboard
            </Button>
            
            <Badge 
              size="lg" 
              color={
                journal.isApproved ? "teal" : 
                journal.isRejected ? "red" : 
                journal.isSubmitted ? "blue" : "gray"
              }
              variant="filled"
            >
              {journal.isApproved ? "Approved" : 
               journal.isRejected ? "Rejected" : 
               journal.isSubmitted ? "Submitted" : "In Progress"}
            </Badge>
            
            <Button
              variant="subtle"
              size="lg"
              onClick={handleGoToPattern}
              rightSection={<IconArrowRight />}
              style={{ paddingBottom: 4 }}
            >
              Go to Pattern
            </Button>
          </Flex>

          <Flex align="flex-start">
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '10px',
              marginTop: 0,
              marginBottom: '15px',
              flex: 1
            }}>
              <Title 
                order={1}
                style={gradientText}
              >
                Testing Journal: {journal.patternName}
              </Title>
              
              <Group style={{ marginTop: 5 }}>
                <Text fw={500}>
                  Tester: <Text span fw={700}>{journal.userDisplayName}</Text>
                </Text>
                <Text fw={500}>
                  Completion: <Text span fw={700}>{journal.completionPercentage}%</Text>
                </Text>
                <Text fw={500}>
                  Created: <Text span fw={700}>{formatDate(journal.createdAt)}</Text>
                </Text>
              </Group>
            </div>

            {journal.isSubmitted && (
              <Stack gap="md" style={{ minWidth: '180px', marginLeft: '15px' }}>
                {journal.isRejected ? (
                  <Button 
                    color="red" 
                    variant="outline"
                    fullWidth
                    leftSection={<IconX size={16} />}
                    onClick={handleUnreject}
                    loading={isUpdating}
                  >
                    Unreject Journal
                  </Button>
                ) : journal.isApproved ? (
                  <Button 
                    color="teal" 
                    variant="outline"
                    fullWidth
                    leftSection={<IconCheck size={16} />}
                    onClick={handleUnapprove}
                    loading={isUpdating}
                  >
                    Unapprove Journal
                  </Button>
                ) : (
                  <>
                    <Button 
                      color="teal" 
                      fullWidth
                      leftSection={<IconCheck size={16} />}
                      onClick={handleApprove}
                      loading={isUpdating}
                    >
                      Approve Journal
                    </Button>
                    <Button 
                      color="red" 
                      fullWidth
                      leftSection={<IconX size={16} />}
                      onClick={handleReject}
                      loading={isUpdating}
                    >
                      Reject Journal
                    </Button>
                  </>
                )}
              </Stack>
            )}
          </Flex>

          <Divider 
            label="Journal Entries" 
            labelPosition="center" 
            style={{ marginTop: 15, marginBottom: 20 }}
          />

          {sortedEntries.length === 0 ? (
            <Text c="dimmed" ta="center" fs="italic">No entries in this journal yet.</Text>
          ) : (
            <Stack gap="lg">
              {sortedEntries.map((entry: JournalEntry) => (
                <JournalEntryCard 
                  key={entry.id} 
                  entry={entry} 
                  canEdit={false}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </Paper>
    </Container>
  );
} 