import { Button, TextInput, PasswordInput, Stack, Text, Anchor, Checkbox, Group, Alert } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { IconAt, IconLock, IconUser, IconAlertCircle } from '@tabler/icons-react';
import { useReCaptcha } from '../../hooks/useReCaptcha';

interface RegisterFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  termsAccepted: boolean;
}

interface RegisterFormProps {
  onSuccess?: () => void;
  onSwitchToLogin: () => void;
}

export function RegisterForm({ onSuccess, onSwitchToLogin }: RegisterFormProps) {
  const { register } = useAuth();
  const [registrationError, setRegistrationError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { verify, isVerifying } = useReCaptcha();
  
  const form = useForm<RegisterFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      termsAccepted: false,
    },
    validate: {
      firstName: (value) => (value.trim().length < 1 ? 'First name is required' : null),
      lastName: (value) => (value.trim().length < 1 ? 'Last name is required' : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (value.length < 8 ? 'Password must be at least 8 characters' : null),
      confirmPassword: (value, values: RegisterFormValues) => 
        value !== values.password ? 'Passwords do not match' : null,
      termsAccepted: (value) => (value ? null : 'You must accept the terms and conditions'),
    },
  });

  const handleSubmit = async (values: RegisterFormValues) => {
    setRegistrationError(null);
    setIsSubmitting(true);
    
    try {
      // Get reCAPTCHA token
      const recaptchaToken = await verify('register');
      
      const { confirmPassword, termsAccepted, ...registerData } = values;
      await register({ ...registerData, recaptchaToken });
      
      // Only call onSuccess if registration was successful
      onSuccess?.();
    } catch (error: any) {
      // Set error message and prevent navigation
      setRegistrationError(error.message || 'An error occurred during registration');
      // Do not call onSuccess on error
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormDisabled = isSubmitting || isVerifying;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap="md">
        {registrationError && (
          <Alert color="red" title="Registration Failed" icon={<IconAlertCircle size={16} />}>
            {registrationError}
          </Alert>
        )}

        <Group grow>
          <TextInput
            withAsterisk
            label="First Name"
            placeholder="John"
            leftSection={<IconUser size={16} />}
            {...form.getInputProps('firstName')}
            disabled={isFormDisabled}
          />
          
          <TextInput
            withAsterisk
            label="Last Name"
            placeholder="Doe"
            leftSection={<IconUser size={16} />}
            {...form.getInputProps('lastName')}
            disabled={isFormDisabled}
          />
        </Group>

        <TextInput
          withAsterisk
          label="Email"
          placeholder="your@email.com"
          leftSection={<IconAt size={16} />}
          {...form.getInputProps('email')}
          disabled={isFormDisabled}
        />

        <PasswordInput
          withAsterisk
          label="Password"
          placeholder="Create a strong password"
          leftSection={<IconLock size={16} />}
          {...form.getInputProps('password')}
          disabled={isFormDisabled}
        />

        <PasswordInput
          withAsterisk
          label="Confirm Password"
          placeholder="Confirm your password"
          leftSection={<IconLock size={16} />}
          {...form.getInputProps('confirmPassword')}
          disabled={isFormDisabled}
        />

        <Checkbox
          label={
            <Text size="sm">
              I agree to the{' '}
              <Anchor href="/terms" target="_blank" rel="noopener noreferrer">
                terms and conditions
              </Anchor>
            </Text>
          }
          {...form.getInputProps('termsAccepted', { type: 'checkbox' })}
          disabled={isFormDisabled}
        />

        <Button 
          type="submit" 
          fullWidth 
          mt="md" 
          loading={isSubmitting || isVerifying}
          variant="gradient"
          gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
        >
          Register
        </Button>

        <Text ta="center" size="sm">
          Already have an account?{' '}
          <Anchor component="button" type="button" onClick={onSwitchToLogin}>
            Sign in
          </Anchor>
        </Text>
      </Stack>
    </form>
  );
} 