import React from 'react';

/**
 * Strips HTML tags and decodes HTML entities from a string
 */
export function stripHtmlAndDecode(html: string | undefined): string {
  if (!html) return '';
  
  // Create a temporary element to decode HTML entities
  const doc = new DOMParser().parseFromString(html, 'text/html');
  // Get the text content (this removes HTML tags)
  const text = doc.body.textContent || '';
  return text;
}

/**
 * Highlights occurrences of search terms within a text string
 * @param text The original text to search within
 * @param searchTerm The search term to highlight
 * @param highlightProps Additional props to apply to the highlight span
 * @returns An array of React elements with highlighted terms
 */
export function highlightSearchTerms(
  text: string,
  searchTerm: string | undefined,
  highlightProps: React.HTMLAttributes<HTMLSpanElement> = {}
): React.ReactNode[] {
  if (!text || !searchTerm || searchTerm.trim() === '') {
    return [text];
  }

  // Split the search term into individual words for better matching
  const searchWords = searchTerm.toLowerCase().trim().split(/\s+/);
  if (searchWords.length === 0) return [text];

  // Create a regex pattern that matches any of the search words
  const pattern = new RegExp(`(${searchWords.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})`, 'gi');
  
  // Split the text by search terms and create an array of elements
  const parts = text.split(pattern);
  
  return parts.map((part, i) => {
    // Check if this part matches any search word (case insensitive)
    const isMatch = searchWords.some(word => 
      part.toLowerCase() === word.toLowerCase()
    );
    
    return isMatch ? 
      React.createElement('span', {
        key: i,
        style: {
          backgroundColor: 'rgba(255, 230, 0, 0.4)',
          fontWeight: 500,
          padding: '0 2px',
          borderRadius: '2px'
        },
        ...highlightProps
      }, part) 
      : part;
  });
} 