import { Container, Paper, Title, LoadingOverlay, Group, Stack, Badge, Text, SimpleGrid, Card, Progress, Button, Alert, Modal } from '@mantine/core';
import { useAuth } from '../contexts/AuthContext';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { StripeCheckoutButton } from '../components/StripeCheckoutButton';
import { gradientText, paperGradient, secondaryPaperGradient } from '../styles/common';
import { patternTestJournalsApi } from '../api/patternTestJournals';
import { useNavigate } from 'react-router-dom';
import { IconClipboardCheck, IconMail, IconAlertCircle, IconCheck, IconLock } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { ForgotPasswordForm } from '../components/auth/ForgotPasswordForm';
import { KarmaModal } from '../components/KarmaModal';
import { useDisclosure } from '@mantine/hooks';

export function ProfilePage() {
    const { user, resendVerificationEmail, isEmailVerified } = useAuth();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const isLoading = queryClient.isFetching({ queryKey: ['auth', 'validate'] });
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [karmaModalOpened, { open: openKarmaModal, close: closeKarmaModal }] = useDisclosure(false);

    // Fetch user's test journals
    const { 
        data: testJournals, 
        isLoading: isLoadingJournals 
    } = useQuery({
        queryKey: ['pattern-test-journals', 'my'],
        queryFn: patternTestJournalsApi.getMyJournals,
        enabled: !!user,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    const { mutate: sendVerificationEmail, isPending: isSendingEmail } = useMutation({
        mutationFn: () => {
            if (!user?.email) throw new Error("User email not found");
            return resendVerificationEmail(user.email);
        },
        onSuccess: () => {
            notifications.show({
                title: 'Success',
                message: 'Verification email sent. Please check your inbox.',
                color: 'green',
            });
        },
        onError: (error: any) => {
            notifications.show({
                title: 'Error',
                message: error.message || 'Failed to send verification email',
                color: 'red',
            });
        }
    });

    const getRoleColor = (role?: string) => {
        switch (role) {
            case 'Admin':
                return 'red';
            case 'Subscriber':
                return 'green';
            default:
                return 'blue';
        }
    };

    if (isLoading) {
        return (
            <Container size="md" py="xl">
                <Paper p="md" style={{ position: 'relative', minHeight: '200px' }}>
                    <LoadingOverlay visible={true} />
                </Paper>
            </Container>
        );
    }

    return (
        <Container size="md" py="xl">
            <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
                <Stack gap="xs">
                    <Group justify="space-between" align="flex-start">
                        <Stack gap="xs">
                            <Title 
                                order={2}
                                style={gradientText}
                            >
                                Your Profile
                            </Title>
                            {user?.role && (
                                <Badge 
                                    color={getRoleColor(user.role)}
                                    size="lg"
                                    variant="filled"
                                    style={{ textTransform: 'none' }}
                                >
                                    {user.role}
                                </Badge>
                            )}
                        </Stack>
                        {user?.profileImageUrl && (
                            <img 
                                src={user.profileImageUrl.toString()} 
                                alt="Profile" 
                                style={{ 
                                    width: 100, 
                                    height: 100, 
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    border: '3px solid #fff',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                                }} 
                            />
                        )}
                    </Group>

                    <Stack gap="md">
                        <Stack gap="xs">
                            <Text><strong>Name:</strong> {user?.firstName} {user?.lastName}</Text>
                            <Text><strong>Display Name:</strong> {user?.displayName}</Text>
                            
                            {/* Email with verification status on the same line */}
                            <Group justify="space-between" align="center" wrap="nowrap">
                                <Group align="center" gap="xs" wrap="nowrap">
                                    <Text><strong>Email:</strong> {user?.email}</Text>
                                    {isEmailVerified ? (
                                        <Badge color="green" leftSection={<IconCheck size={14} />}>Verified</Badge>
                                    ) : (
                                        <Badge color="red" leftSection={<IconAlertCircle size={14} />}>Not Verified</Badge>
                                    )}
                                </Group>
                                
                                {!isEmailVerified && (
                                    <Button 
                                        variant="outline" 
                                        size="xs" 
                                        leftSection={<IconMail size={14} />}
                                        onClick={() => sendVerificationEmail()}
                                        loading={isSendingEmail}
                                    >
                                        Resend Verification
                                    </Button>
                                )}
                            </Group>
                            
                            {!isEmailVerified && (
                                <Alert color="yellow" title="Email Not Verified" icon={<IconAlertCircle size={16} />}>
                                    Please verify your email address to ensure you can access all features. 
                                    Check your inbox for a verification link or click the button above to request a new one.
                                </Alert>
                            )}
                            
                            {/* Password Reset Link */}
                            <Group mt="xs" align="center">
                                <Button
                                    variant="subtle"
                                    size="sm"
                                    leftSection={<IconLock size={16} />}
                                    color="blue"
                                    onClick={() => setResetPasswordModalOpen(true)}
                                >
                                    Reset Password
                                </Button>
                            </Group>
                        </Stack>

                        {/* Reset Password Modal */}
                        <Modal 
                            opened={resetPasswordModalOpen} 
                            onClose={() => setResetPasswordModalOpen(false)}
                            title={
                                <Text fw={700} size="lg" style={gradientText}>
                                    Reset Your Password
                                </Text>
                            }
                            centered
                        >
                            <ForgotPasswordForm onSwitchToLogin={() => setResetPasswordModalOpen(false)} />
                        </Modal>

                        {user && (
                            <Paper 
                                shadow="sm" 
                                p="lg" 
                                radius="md" 
                                withBorder 
                                style={{
                                    ...secondaryPaperGradient,
                                    cursor: 'pointer'
                                }}
                                onClick={openKarmaModal}
                            >
                                <Stack gap="md">
                                    <Title 
                                        order={3} 
                                        size="h4"
                                        style={gradientText}
                                    >
                                        Your Karma
                                    </Title>
                                    <Group>
                                        <Stack gap="xs" style={{ flex: 1 }}>
                                            <Text size="lg"><strong>Current Karma:</strong> {user.karma}</Text>
                                            <Text size="sm" c="dimmed">Available karma points you can use</Text>
                                        </Stack>
                                        <Stack gap="xs" style={{ flex: 1 }}>
                                            <Text size="lg"><strong>Lifetime Karma:</strong> {user.lifetimeKarma}</Text>
                                            <Text size="sm" c="dimmed">Total karma points you've earned</Text>
                                        </Stack>
                                    </Group>
                                </Stack>
                            </Paper>
                        )}

                        {/* Patterns Being Tested Section */}
                        {user && (
                            <Paper 
                                shadow="sm" 
                                p="lg" 
                                radius="md" 
                                withBorder 
                                style={secondaryPaperGradient}
                            >
                                <Stack gap="md">
                                    <Title 
                                        order={3} 
                                        size="h4"
                                        style={gradientText}
                                    >
                                        <Group gap="xs">
                                            <IconClipboardCheck size={24} />
                                            <span>Patterns You're Testing</span>
                                        </Group>
                                    </Title>
                                    
                                    {isLoadingJournals ? (
                                        <div style={{ position: 'relative', height: '150px' }}>
                                            <LoadingOverlay visible={true} />
                                        </div>
                                    ) : testJournals && testJournals.length > 0 ? (
                                        <SimpleGrid cols={{ base: 1, sm: 2 }} spacing="md">
                                            {testJournals.map((journal) => (
                                                <Card 
                                                    key={journal.id} 
                                                    shadow="sm" 
                                                    p="md" 
                                                    radius="md" 
                                                    withBorder
                                                >
                                                    <Stack gap="xs">
                                                        <Text 
                                                            fw={600} 
                                                            style={{ 
                                                                cursor: 'pointer',
                                                                color: '#228be6',
                                                                textDecoration: 'none'
                                                            }}
                                                            onClick={() => navigate(`/patterns/${journal.patternId}`)}
                                                        >
                                                            {journal.patternName}
                                                        </Text>
                                                        <Group grow>
                                                            <Text size="sm">Completion:</Text>
                                                            <Group gap="xs" grow>
                                                                <Progress 
                                                                    value={journal.completionPercentage} 
                                                                    color={journal.completionPercentage === 100 ? 'green' : 'blue'}
                                                                    size="sm"
                                                                />
                                                                <Text size="xs" ta="right">{journal.completionPercentage}%</Text>
                                                            </Group>
                                                        </Group>
                                                        <Text size="xs" c="dimmed">
                                                            {journal.entries.length} 
                                                            {journal.entries.length === 1 ? ' entry' : ' entries'}
                                                        </Text>
                                                        <Button 
                                                            variant="light"
                                                            size="xs"
                                                            fullWidth
                                                            onClick={() => navigate(`/pattern-test-journal/${journal.patternId}`)}
                                                        >
                                                            Continue Testing
                                                        </Button>
                                                    </Stack>
                                                </Card>
                                            ))}
                                        </SimpleGrid>
                                    ) : (
                                        <Text c="dimmed">
                                            You are not currently testing any patterns. 
                                            Browse patterns and look for ones that need testers!
                                        </Text>
                                    )}
                                </Stack>
                            </Paper>
                        )}

                        {user && (
                            <Paper 
                                shadow="sm" 
                                p="lg" 
                                radius="md" 
                                withBorder 
                                style={secondaryPaperGradient}
                            >
                                <Stack gap="md">
                                    <Title 
                                        order={3} 
                                        size="h4"
                                        style={gradientText}
                                    >
                                        Your Benefits
                                    </Title>
                                    <Stack gap="xs">
                                        {user.role === 'Subscriber' || user.role === 'Admin' ? (
                                            <>
                                                <Text>• Unlimited pattern views</Text>
                                                <Text>• 20 AI pattern generations per month</Text>
                                                <Text>• Ad-free experience</Text>
                                            </>
                                        ) : (
                                            <>
                                                <Text>• 10 free pattern views per month</Text>
                                                <Text>• 3 AI pattern generations per month</Text>
                                                <Text>• Save your favorite patterns</Text>
                                                <Text>• Participate in discussions</Text>
                                            </>
                                        )}
                                    </Stack>
                                </Stack>
                            </Paper>
                        )}
                    </Stack>

                    {user && user.role !== 'Subscriber' && user.role !== 'Admin' && (
                        <Paper 
                            shadow="sm" 
                            p="xl" 
                            radius="md" 
                            withBorder 
                            style={secondaryPaperGradient}
                        >
                            <Stack align="center" gap="md">
                                <Title 
                                    order={3} 
                                    size="h3"
                                    ta="center"
                                    style={gradientText}
                                >
                                    Upgrade to Subscriber
                                </Title>
                                <Text size="sm" c="dimmed" ta="center">
                                    Get unlimited views, up to 20 pattern generations per month, and enjoy an ad-free experience
                                </Text>
                                <StripeCheckoutButton />
                            </Stack>
                        </Paper>
                    )}
                </Stack>
            </Paper>

            <KarmaModal opened={karmaModalOpened} onClose={closeKarmaModal} />
        </Container>
    );
} 