import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Title, 
  TextInput, 
  Button, 
  Group, 
  Stack, 
  Paper,
  LoadingOverlay,
  Image,
  Box,
  FileButton,
  Select,
  Combobox,
  PillsInput,
  Pill,
  useCombobox
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { blogsApi } from '../api/blogs';
import { BlogPost } from '../types/Blog';
import { IconUpload } from '@tabler/icons-react';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from '../utils/apiUtils';
import { useAuth } from '../contexts/AuthContext';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import '@mantine/tiptap/styles.css';

export function BlogEditPage() {
  const { id: slug } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const isAdmin = user?.role === 'Admin';
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [tagOptions, setTagOptions] = useState<string[]>([
    'beginner', 'intermediate', 'advanced',
    'amigurumi', 'blanket', 'scarf', 'hat',
    'yarn', 'hook sizes', 'techniques',
    'tips', 'tutorial', 'pattern review'
  ]);
  const [post, setPost] = useState<BlogPost>({
    id: '',
    headline: '',
    content: '',
    imageUrl: '',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    slug: '',
    tags: [],
    section: 'Crochet'  // Default section
  });
  const [searchValue, setSearchValue] = useState('');

  const editor = useEditor({
    extensions: [StarterKit, Link],
    content: post.content
  });

  const { data: fetchedPost, isLoading: isLoadingPost } = useQuery({
    queryKey: ['blog', slug],
    queryFn: () => slug ? blogsApi.getBySlug(slug) : null,
    enabled: !!slug,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  useEffect(() => {
    if (fetchedPost) {
      setPost(fetchedPost);
      editor?.commands.setContent(fetchedPost.content);
      if (fetchedPost.imageUrl) setPreviewUrl(fetchedPost.imageUrl);
    }
  }, [fetchedPost, editor]);

  const { mutate: saveBlog, isPending: isSaving } = useMutation({
    mutationFn: async () => {
      const formData = new FormData();
      formData.append('Headline', post.headline);
      formData.append('Content', editor?.getHTML() || '');
      formData.append('Section', post.section);
      post.tags.forEach((tag, index) => {
        formData.append(`Tags[${index}]`, tag);
      });
      if (imageFile) formData.append('image', imageFile);
      return slug ? blogsApi.update(slug, formData) : blogsApi.create(formData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['blogs'] });
      if (slug) {
        queryClient.invalidateQueries({ queryKey: ['blog', slug] });
      }
      notifications.show({
        title: 'Success',
        message: `Blog post ${slug ? 'updated' : 'created'} successfully`,
        color: 'green'
      });
      navigate('/blog');
    },
    onError: (error: ApiError) => {
      notifications.show({
        title: 'Error',
        message: error.message || `Failed to ${slug ? 'update' : 'create'} blog post`,
        color: 'red'
      });
    }
  });

  const handleFileSelect = (file: File | null) => {
    if (file) {
      setImageFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (!isAdmin) navigate('/blog');
  }, [isAdmin, navigate]);

  // Predefined sections for the blog
  const sections = [
    { value: 'Crochet', label: 'Crochet' },
    { value: 'Patterns', label: 'Patterns' },
    { value: 'Tutorials', label: 'Tutorials' },
    { value: 'Tips & Tricks', label: 'Tips & Tricks' },
    { value: 'News', label: 'News' }
  ];

  const handleValueSelect = (val: string) => {
    if (!val) return;
    
    const normalized = val.toLowerCase().trim();
    if (!post.tags.includes(normalized)) {
      setPost(prev => ({
        ...prev,
        tags: [...prev.tags, normalized]
      }));
      
      if (!tagOptions.includes(normalized)) {
        setTagOptions(prev => [...prev, normalized]);
      }
    }
    setSearchValue('');
  };

  const handleValueRemove = (val: string) => {
    setPost(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== val)
    }));
  };

  const combobox = useCombobox();

  return (
    <Container size="md" py="xl">
      <Paper shadow="sm" p="md" pos="relative">
        <LoadingOverlay visible={isLoadingPost || isSaving} />
        <form onSubmit={(e) => { e.preventDefault(); saveBlog(); }}>
          <Stack gap="md">
            <Title order={2}>{slug ? 'Edit' : 'Create'} Blog Post</Title>

            <TextInput
              label="Headline"
              value={post.headline}
              onChange={(e) => setPost(prev => ({ ...prev, headline: e.target.value }))}
              required
            />

            <Select
              label="Section"
              data={sections}
              value={post.section}
              onChange={(value) => setPost(prev => ({ ...prev, section: value || 'Crochet' }))}
              required
            />

            <Combobox
              onOptionSubmit={handleValueSelect}
              store={combobox}
            >
              <Combobox.DropdownTarget>
                <PillsInput
                  label="Tags"
                >
                  <Pill.Group>
                    {post.tags.map((tag) => (
                      <Pill
                        key={tag}
                        withRemoveButton
                        onRemove={() => handleValueRemove(tag)}
                      >
                        {tag}
                      </Pill>
                    ))}
                    
                    <Combobox.EventsTarget>
                      <PillsInput.Field
                        placeholder="Select or create tags"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.currentTarget.value)}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' && searchValue.trim()) {
                            event.preventDefault();
                            handleValueSelect(searchValue);
                          }
                        }}
                      />
                    </Combobox.EventsTarget>
                  </Pill.Group>
                </PillsInput>
              </Combobox.DropdownTarget>

              <Combobox.Dropdown>
                <Combobox.Options>
                  {tagOptions
                    .filter(tag => 
                      tag.toLowerCase().includes(searchValue.toLowerCase().trim()) &&
                      !post.tags.includes(tag)
                    )
                    .map((tag) => (
                      <Combobox.Option value={tag} key={tag}>
                        {tag}
                      </Combobox.Option>
                    ))}
                  {searchValue.trim() && !tagOptions.includes(searchValue.trim().toLowerCase()) && (
                    <Combobox.Option value={searchValue}>
                      + Create "{searchValue}"
                    </Combobox.Option>
                  )}
                </Combobox.Options>
              </Combobox.Dropdown>
            </Combobox>

            <Box>
              {previewUrl && (
                <Image
                  src={previewUrl}
                  alt="Blog preview"
                  fit="contain"
                  height={200}
                  mb="md"
                />
              )}
              <Group justify="space-between" align="center">
                <FileButton onChange={handleFileSelect} accept="image/*">
                  {(props) => (
                    <Button {...props} leftSection={<IconUpload size={14} />}>
                      {previewUrl ? 'Change Image' : 'Upload Image'}
                    </Button>
                  )}
                </FileButton>
                <Group>
                  <Button variant="light" onClick={() => navigate('/blog')}>
                    Cancel
                  </Button>
                  <Button type="submit" loading={isSaving}>
                    {slug ? 'Update' : 'Create'} Post
                  </Button>
                </Group>
              </Group>
            </Box>

            <RichTextEditor editor={editor}>
              <RichTextEditor.Toolbar sticky stickyOffset={60}>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Bold />
                  <RichTextEditor.Italic />
                  <RichTextEditor.Underline />
                  <RichTextEditor.Strikethrough />
                  <RichTextEditor.ClearFormatting />
                  <RichTextEditor.Highlight />
                  <RichTextEditor.Code />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.H1 />
                  <RichTextEditor.H2 />
                  <RichTextEditor.H3 />
                  <RichTextEditor.H4 />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Blockquote />
                  <RichTextEditor.Hr />
                  <RichTextEditor.BulletList />
                  <RichTextEditor.OrderedList />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Link />
                  <RichTextEditor.Unlink />
                </RichTextEditor.ControlsGroup>
              </RichTextEditor.Toolbar>

              <RichTextEditor.Content />
            </RichTextEditor>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
} 