import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useAuth } from '../contexts/AuthContext';

interface UseEmailVerificationOptions {
  /**
   * The action name that requires email verification
   * e.g., "claiming an event", "adding a comment", etc.
   */
  actionName: string;
  
  /**
   * Delay in milliseconds before redirecting to profile page
   * Default: 2000ms (2 seconds)
   */
  redirectDelay?: number;
}

interface UseEmailVerificationResult {
  /**
   * Check if the user is logged in and email is verified
   * If not verified, it shows a notification and sets up redirection
   * Returns true if verification passed, false otherwise
   */
  checkVerification: (e?: React.MouseEvent) => boolean;
  
  /**
   * Whether the verification alert is currently showing
   */
  showVerificationAlert: boolean;
  
  /**
   * Alert component to display when verification fails
   */
  VerificationAlert: React.ReactNode;
}

/**
 * Custom hook for handling email verification requirements
 * Provides standardized verification checking and UI elements
 */
export function useEmailVerification({
  actionName,
  redirectDelay = 2000
}: UseEmailVerificationOptions): UseEmailVerificationResult {
  const { user, isEmailVerified } = useAuth();
  const navigate = useNavigate();
  const [showVerificationAlert, setShowVerificationAlert] = useState(false);
  
  // Handle redirection logic
  useEffect(() => {
    if (showVerificationAlert) {
      const timeoutId = setTimeout(() => {
        navigate('/profile');
      }, redirectDelay);
      
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [showVerificationAlert, navigate, redirectDelay]);
  
  // Verification check function
  const checkVerification = (e?: React.MouseEvent) => {
    // Stop propagation if event is provided
    if (e) {
      e.stopPropagation();
    }
    
    // Check if user is logged in
    if (!user) {
      notifications.show({
        title: 'Login Required',
        message: `You need to login before ${actionName}`,
        color: 'blue'
      });
      return false;
    }
    
    // Check if email is verified
    if (!isEmailVerified) {
      // Show alert and set up redirect
      setShowVerificationAlert(true);
      
      notifications.show({
        title: 'Email Verification Required',
        message: `You need to verify your email before ${actionName}. Redirecting to your profile page...`,
        color: 'yellow',
        autoClose: redirectDelay + 1000 // Give extra time for notification to be visible
      });
      
      return false;
    }
    
    // Verification passed
    return true;
  };
  
  // Pre-built alert component
  const VerificationAlert = showVerificationAlert ? (
    <Alert 
      icon={<IconAlertCircle size={16} />} 
      title="Email verification required" 
      color="yellow" 
      variant="light"
      mb="md"
    >
      You need to verify your email before {actionName}. Redirecting to your profile page...
    </Alert>
  ) : null;
  
  return {
    checkVerification,
    showVerificationAlert,
    VerificationAlert
  };
} 