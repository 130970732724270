import { useEffect } from 'react';
import { Container, Title, Text, Paper, Center, Stack } from '@mantine/core';
import { useAuth } from '../contexts/AuthContext';
import { gradientText, paperGradient } from '../styles/common';

export function SubscriptionSuccessPage() {
    const { signOut } = useAuth();

    useEffect(() => {
        // Sign out automatically when the component mounts
        signOut();
    }, [signOut]);

    return (
        <Container size="sm" py="xl">
            <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
                <Center>
                    <Stack align="center" gap="lg">
                        <Title 
                            order={1}
                            style={gradientText}
                        >
                            Thank You for Subscribing!
                        </Title>
                        <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }} ta="center">
                            Your subscription has been activated. Please sign back in to access your new features.
                        </Text>
                    </Stack>
                </Center>
            </Paper>
        </Container>
    );
} 