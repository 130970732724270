export const getAuthHeaders = (contentType = 'application/json') => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    throw new Error('No authentication token found');
  }

  return {
    'Content-Type': contentType,
    'Authorization': `Bearer ${token}`
  };
}; 