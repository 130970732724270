import { useState, useCallback } from 'react';

/**
 * Hook for managing filter state with type safety
 * @param initialFilters Initial filter values
 * @returns Filter state and handlers
 */
export function useFilters<
  T extends Record<string, any>,
  K extends keyof T
>(initialFilters: T) {
  const [filters, setFilters] = useState<T>(initialFilters);

  /**
   * Handler for updating a specific filter
   * @param key The filter key to update
   * @param value The new value
   */
  const handleFilterChange = useCallback((key: K, value: T[K]) => {
    setFilters(prev => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  /**
   * Reset all filters to their initial values
   */
  const clearFilters = useCallback(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  /**
   * Update multiple filters at once
   * @param updates Object with filter updates
   */
  const updateFilters = useCallback((updates: Partial<T>) => {
    setFilters(prev => ({
      ...prev,
      ...updates,
    }));
  }, []);

  return {
    filters,
    setFilters,
    handleFilterChange,
    clearFilters,
    updateFilters
  };
} 