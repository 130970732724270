export class ApiError extends Error {
  constructor(
    public statusCode: number,
    message: string,
    public originalError?: any
  ) {
    super(message);
    this.name = 'ApiError';
  }
}

export const handleApiError = (error: any): never => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    
    // Extract error message from the response
    let errorMessage = 'An error occurred';
    
    // Check if response.data is a string (direct error message)
    if (typeof error.response.data === 'string') {
      errorMessage = error.response.data;
    }
    // Check if response.data has a message property
    else if (error.response.data?.message) {
      errorMessage = error.response.data.message;
    }
    // ASP.NET responses may just send the error message directly
    else if (typeof error.response.data === 'object') {
      errorMessage = JSON.stringify(error.response.data);
    }
    
    throw new ApiError(
      error.response.status,
      errorMessage,
      error
    );
  } else if (error.request) {
    // The request was made but no response was received
    throw new ApiError(
      0,
      'No response received from server',
      error
    );
  } else {
    // Something happened in setting up the request that triggered an Error
    throw new ApiError(
      0,
      error.message || 'An unknown error occurred',
      error
    );
  }
}; 