import { Table, Paper, TextInput, LoadingOverlay, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState, useMemo } from 'react';
import { PatternTestJournal } from '../../api/patternTestJournals';
import { adminApi } from '../../api/admin';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

export function TestJournalsTable() {
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [updatingJournalId, setUpdatingJournalId] = useState<string | null>(null);

    const { data: journals, isLoading } = useQuery({
        queryKey: ['admin', 'test-journals'],
        queryFn: adminApi.getAllTestJournals
    });

    const filteredJournals = useMemo(() => {
        if (!journals) return [];
        
        return journals.filter(journal => {
            const matchesSearch = search === '' || 
                journal.patternName.toLowerCase().includes(search.toLowerCase()) ||
                journal.userDisplayName.toLowerCase().includes(search.toLowerCase());
            
            return matchesSearch;
        });
    }, [journals, search]);

    const handleJournalClick = (journalId: string) => {
        // Navigate to the journal detail page
        navigate(`/admin/journal/${journalId}`);
    };

    const handleApproveClick = async (journal: PatternTestJournal, event: React.MouseEvent) => {
        event.stopPropagation();
        
        // Toggle logic: if already approved, unapprove it
        if (journal.isApproved) {
            await handleUnapproveClick(journal, event);
            return;
        }
        
        if (!journal.isSubmitted) {
            notifications.show({
                title: 'Cannot Approve',
                message: 'The journal must be submitted before it can be approved.',
                color: 'yellow'
            });
            return;
        }

        try {
            setUpdatingJournalId(journal.id);
            await adminApi.approveTestJournal(journal.id);
            
            // Invalidate queries to refresh data
            queryClient.invalidateQueries({ queryKey: ['admin', 'test-journals'] });
            queryClient.invalidateQueries({ queryKey: ['admin', 'journal', journal.id] });
            
            notifications.show({
                title: 'Journal Approved',
                message: `Test journal for "${journal.patternName}" has been approved.`,
                color: 'green'
            });
        } catch (error) {
            notifications.show({
                title: 'Error',
                message: error instanceof Error ? error.message : 'Failed to approve journal',
                color: 'red'
            });
        } finally {
            setUpdatingJournalId(null);
        }
    };

    const handleUnapproveClick = async (journal: PatternTestJournal, event: React.MouseEvent) => {
        event.stopPropagation();
        
        if (!journal.isApproved) {
            return; // Nothing to do if not approved
        }

        try {
            setUpdatingJournalId(journal.id);
            await adminApi.unapproveTestJournal(journal.id);
            
            // Invalidate queries to refresh data
            queryClient.invalidateQueries({ queryKey: ['admin', 'test-journals'] });
            queryClient.invalidateQueries({ queryKey: ['admin', 'journal', journal.id] });
            
            notifications.show({
                title: 'Journal Unapproved',
                message: `Approval removed from test journal for "${journal.patternName}".`,
                color: 'blue'
            });
        } catch (error) {
            notifications.show({
                title: 'Error',
                message: error instanceof Error ? error.message : 'Failed to unapprove journal',
                color: 'red'
            });
        } finally {
            setUpdatingJournalId(null);
        }
    };

    const handleRejectClick = async (journal: PatternTestJournal, event: React.MouseEvent) => {
        event.stopPropagation();
        
        // Toggle logic: if already rejected, unreject it
        if (journal.isRejected) {
            await handleUnrejectClick(journal, event);
            return;
        }
        
        if (!journal.isSubmitted) {
            notifications.show({
                title: 'Cannot Reject',
                message: 'The journal must be submitted before it can be rejected.',
                color: 'yellow'
            });
            return;
        }

        try {
            setUpdatingJournalId(journal.id);
            await adminApi.rejectTestJournal(journal.id);
            
            // Invalidate queries to refresh data
            queryClient.invalidateQueries({ queryKey: ['admin', 'test-journals'] });
            queryClient.invalidateQueries({ queryKey: ['admin', 'journal', journal.id] });
            
            notifications.show({
                title: 'Journal Rejected',
                message: `Test journal for "${journal.patternName}" has been rejected.`,
                color: 'red'
            });
        } catch (error) {
            notifications.show({
                title: 'Error',
                message: error instanceof Error ? error.message : 'Failed to reject journal',
                color: 'red'
            });
        } finally {
            setUpdatingJournalId(null);
        }
    };

    const handleUnrejectClick = async (journal: PatternTestJournal, event: React.MouseEvent) => {
        event.stopPropagation();
        
        if (!journal.isRejected) {
            return; // Nothing to do if not rejected
        }

        try {
            setUpdatingJournalId(journal.id);
            await adminApi.unrejectTestJournal(journal.id);
            
            // Invalidate queries to refresh data
            queryClient.invalidateQueries({ queryKey: ['admin', 'test-journals'] });
            queryClient.invalidateQueries({ queryKey: ['admin', 'journal', journal.id] });
            
            notifications.show({
                title: 'Journal Unrejected',
                message: `Rejection removed from test journal for "${journal.patternName}".`,
                color: 'blue'
            });
        } catch (error) {
            notifications.show({
                title: 'Error',
                message: error instanceof Error ? error.message : 'Failed to unreject journal',
                color: 'red'
            });
        } finally {
            setUpdatingJournalId(null);
        }
    };

    if (isLoading) {
        return (
            <Paper p="md" style={{ position: 'relative', minHeight: '200px' }}>
                <LoadingOverlay visible={true} />
            </Paper>
        );
    }

    if (!journals?.length) {
        return <Text c="dimmed" ta="center">No test journals found</Text>;
    }

    return (
        <Paper p="md" withBorder>
            <TextInput
                placeholder="Search journals..."
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
                style={{ marginBottom: '1rem' }}
            />

            <Table striped>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>Pattern</Table.Th>
                        <Table.Th>Tester</Table.Th>
                        <Table.Th>Completion</Table.Th>
                        <Table.Th>Entries</Table.Th>
                        <Table.Th>Submitted</Table.Th>
                        <Table.Th>Approved</Table.Th>
                        <Table.Th>Rejected</Table.Th>
                        <Table.Th>Updated</Table.Th>
                        <Table.Th>Created</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {filteredJournals.map((journal) => (
                        <Table.Tr key={journal.id}>
                            <Table.Td>
                                <Text 
                                    style={{ cursor: 'pointer', color: '#228be6', textDecoration: 'underline' }} 
                                    onClick={() => handleJournalClick(journal.id)}
                                >
                                    {journal.patternName}
                                </Text>
                            </Table.Td>
                            <Table.Td>{journal.userDisplayName}</Table.Td>
                            <Table.Td>{journal.completionPercentage}%</Table.Td>
                            <Table.Td>{journal.entries ? journal.entries.length : 0}</Table.Td>
                            <Table.Td>
                                {journal.isSubmitted ? (
                                    <ThemeIcon color="blue" variant="light" radius="xl" size="md">
                                        <IconCheck size={16} />
                                    </ThemeIcon>
                                ) : (
                                    <ThemeIcon color="gray" variant="light" radius="xl" size="md">
                                        <IconX size={16} />
                                    </ThemeIcon>
                                )}
                            </Table.Td>
                            <Table.Td>
                                <Tooltip
                                    label={
                                        !journal.isSubmitted 
                                            ? "Journal must be submitted first" 
                                            : journal.isApproved
                                                ? "Click to unapprove"
                                                : "Click to approve"
                                    }
                                    disabled={updatingJournalId === journal.id}
                                >
                                    <div 
                                        style={{ 
                                            cursor: journal.isSubmitted ? 'pointer' : 'not-allowed',
                                            display: 'inline-block'
                                        }}
                                        onClick={(e) => journal.isSubmitted && handleApproveClick(journal, e)}
                                    >
                                        {journal.isApproved ? (
                                            <ThemeIcon color="teal" variant="light" radius="xl" size="md">
                                                <IconCheck size={16} />
                                            </ThemeIcon>
                                        ) : (
                                            <ThemeIcon 
                                                color={journal.isSubmitted ? "teal" : "gray"} 
                                                variant="outline" 
                                                radius="xl" 
                                                size="md"
                                                opacity={journal.isSubmitted ? 1 : 0.5}
                                            >
                                                <IconCheck size={16} />
                                            </ThemeIcon>
                                        )}
                                    </div>
                                </Tooltip>
                            </Table.Td>
                            <Table.Td>
                                <Tooltip
                                    label={
                                        !journal.isSubmitted 
                                            ? "Journal must be submitted first" 
                                            : journal.isRejected
                                                ? "Click to unreject"
                                                : "Click to reject"
                                    }
                                    disabled={updatingJournalId === journal.id}
                                >
                                    <div 
                                        style={{ 
                                            cursor: journal.isSubmitted ? 'pointer' : 'not-allowed',
                                            display: 'inline-block'
                                        }}
                                        onClick={(e) => journal.isSubmitted && handleRejectClick(journal, e)}
                                    >
                                        {journal.isRejected ? (
                                            <ThemeIcon color="red" variant="light" radius="xl" size="md">
                                                <IconCheck size={16} />
                                            </ThemeIcon>
                                        ) : (
                                            <ThemeIcon 
                                                color={journal.isSubmitted ? "red" : "gray"} 
                                                variant="outline" 
                                                radius="xl" 
                                                size="md"
                                                opacity={journal.isSubmitted ? 1 : 0.5}
                                            >
                                                <IconCheck size={16} />
                                            </ThemeIcon>
                                        )}
                                    </div>
                                </Tooltip>
                            </Table.Td>
                            <Table.Td>{new Date(journal.updatedAt).toLocaleDateString()}</Table.Td>
                            <Table.Td>{new Date(journal.createdAt).toLocaleDateString()}</Table.Td>
                        </Table.Tr>
                    ))}
                </Table.Tbody>
            </Table>
        </Paper>
    );
} 