import { apiClient } from './client';
import { AdminUserDetails } from '../types/api';
import { getAuthHeaders } from '../utils/authHeaders';
import { PatternTestJournal } from './patternTestJournals';
import { API_BASE_URL } from './config';
import { handleApiError } from '../utils/apiUtils';
import { AdminEvent } from '../types/Events';

export const adminApi = {
    getAllUsers: async (): Promise<AdminUserDetails[]> => {
        const response = await apiClient.get('admin/users', {
            headers: getAuthHeaders()
        });
        return response.data;
    },

    updateUser: async (id: string, user: Partial<AdminUserDetails>): Promise<AdminUserDetails> => {
        const response = await apiClient.put(`admin/users/${id}`, user, {
            headers: getAuthHeaders()
        });
        return response.data;
    },
    
    deleteUser: async (id: string): Promise<void> => {
        await apiClient.delete(`admin/users/${id}`, {
            headers: getAuthHeaders()
        });
    },
    
    addKarma: async (userId: string, amount: number): Promise<any> => {
        const response = await apiClient.post(`karma/add/${userId}?amount=${amount}`, null, {
            headers: {
                ...getAuthHeaders(),
                'X-Recaptcha-Token': 'admin-dashboard' // This will need to be handled properly on the backend
            }
        });
        return response.data;
    },

    getAllTestJournals: async (): Promise<PatternTestJournal[]> => {
        try {
            const response = await apiClient.get<PatternTestJournal[]>(
                `${API_BASE_URL}/api/pattern-test-journals`,
                { headers: getAuthHeaders() }
            );
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },
    
    getTestJournalById: async (journalId: string): Promise<PatternTestJournal> => {
        try {
            const response = await apiClient.get<PatternTestJournal>(
                `${API_BASE_URL}/api/pattern-test-journals/${journalId}`,
                { headers: getAuthHeaders() }
            );
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },

    approveTestJournal: async (journalId: string): Promise<PatternTestJournal> => {
        try {
            const response = await apiClient.post<PatternTestJournal>(
                `${API_BASE_URL}/api/pattern-test-journals/${journalId}/approve`,
                {},
                { headers: getAuthHeaders() }
            );
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },
    
    rejectTestJournal: async (journalId: string): Promise<PatternTestJournal> => {
        try {
            const response = await apiClient.post<PatternTestJournal>(
                `${API_BASE_URL}/api/pattern-test-journals/${journalId}/reject`,
                {},
                { headers: getAuthHeaders() }
            );
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },
    
    unapproveTestJournal: async (journalId: string): Promise<PatternTestJournal> => {
        try {
            const response = await apiClient.post<PatternTestJournal>(
                `${API_BASE_URL}/api/pattern-test-journals/${journalId}/unapprove`,
                {},
                { headers: getAuthHeaders() }
            );
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },
    
    unrejectTestJournal: async (journalId: string): Promise<PatternTestJournal> => {
        try {
            const response = await apiClient.post<PatternTestJournal>(
                `${API_BASE_URL}/api/pattern-test-journals/${journalId}/unreject`,
                {},
                { headers: getAuthHeaders() }
            );
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },

    getAllEventsNeedingAttention: async (): Promise<AdminEvent[]> => {
        try {
            const response = await apiClient.get<AdminEvent[]>(
                `${API_BASE_URL}/api/admin/events`,
                { headers: getAuthHeaders() }
            );
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    }
}; 