import { useState } from 'react';
import { notifications } from '@mantine/notifications';

declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (siteKey: string, options: { action: string }) => Promise<string>;
    };
  }
}

export function useReCaptcha() {
  const [isVerifying, setIsVerifying] = useState(false);

  const verify = async (action: string) => {
    setIsVerifying(true);
    try {
      if (!process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
        throw new Error('ReCAPTCHA configuration error');
      }

      await new Promise<void>((resolve) => window.grecaptcha.ready(() => resolve()));

      const token = await window.grecaptcha.execute(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        { action }
      );

      if (!token) {
        throw new Error('ReCAPTCHA verification failed - no token received');
      }

      return token;
    } catch (error) {
      notifications.show({
        title: 'ReCAPTCHA Error',
        message: error instanceof Error ? error.message : 'Failed to verify ReCAPTCHA',
        color: 'red'
      });
      throw error;
    } finally {
      setIsVerifying(false);
    }
  };

  return { verify, isVerifying };
} 