import { Button, TextInput, Stack, Text, Anchor, Alert } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { IconAt, IconAlertCircle } from '@tabler/icons-react';
import { authApi } from '../../api/auth';
import { useReCaptcha } from '../../hooks/useReCaptcha';

interface ForgotPasswordValues {
  email: string;
}

interface ForgotPasswordFormProps {
  onSwitchToLogin: () => void;
}

export function ForgotPasswordForm({ onSwitchToLogin }: ForgotPasswordFormProps) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { verify, isVerifying } = useReCaptcha();
  
  const form = useForm<ForgotPasswordValues>({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const handleSubmit = async (values: ForgotPasswordValues) => {
    try {
      setError(null);
      setIsSubmitting(true);
      
      // Get reCAPTCHA token
      const recaptchaToken = await verify('forgot_password');
      
      await authApi.forgotPassword({
        email: values.email,
        recaptchaToken
      });
      
      setIsSubmitted(true);
    } catch (err: any) {
      setError(err?.message || 'An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormDisabled = isSubmitting || isVerifying;

  if (isSubmitted) {
    return (
      <Stack gap="md">
        <Alert title="Check your email" color="green" icon={<IconAlertCircle />}>
          If an account exists with this email, you will receive instructions to reset your password.
        </Alert>
        <Button 
          variant="subtle" 
          onClick={onSwitchToLogin}
          color="blue"
        >
          Back to sign in
        </Button>
      </Stack>
    );
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap="md">
        <Text size="sm" c="dimmed">
          Enter your email address and we'll send you a link to reset your password.
        </Text>

        {error && (
          <Alert title="Error" color="red" icon={<IconAlertCircle />}>
            {error}
          </Alert>
        )}

        <TextInput
          withAsterisk
          label="Email"
          placeholder="your@email.com"
          leftSection={<IconAt size={16} />}
          {...form.getInputProps('email')}
          disabled={isFormDisabled}
        />

        <Button 
          type="submit" 
          fullWidth 
          mt="md"
          loading={isFormDisabled}
          variant="gradient"
          gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
        >
          Send Reset Link
        </Button>

        <Text ta="center" size="sm">
          Remember your password?{' '}
          <Anchor component="button" type="button" onClick={onSwitchToLogin}>
            Sign in
          </Anchor>
        </Text>
      </Stack>
    </form>
  );
} 