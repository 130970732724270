import { Button, PasswordInput, Stack, Text, Alert } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconLock, IconAlertCircle, IconCheck } from '@tabler/icons-react';
import { authApi } from '../../api/auth';
import { useReCaptcha } from '../../hooks/useReCaptcha';

interface ResetPasswordValues {
  password: string;
  confirmPassword: string;
}

export function ResetPasswordForm() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { verify, isVerifying } = useReCaptcha();
  
  const email = searchParams.get('email') || '';
  const token = searchParams.get('token') || '';
  
  const form = useForm<ResetPasswordValues>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (value) => (value.length < 8 ? 'Password must be at least 8 characters' : null),
      confirmPassword: (value, values: ResetPasswordValues) => 
        value !== values.password ? 'Passwords do not match' : null,
    },
  });

  const handleSubmit = async (values: ResetPasswordValues) => {
    try {
      setError(null);
      setIsSubmitting(true);
      
      // Get reCAPTCHA token
      const recaptchaToken = await verify('reset_password');
      
      await authApi.resetPassword({
        email,
        token,
        password: values.password,
        recaptchaToken
      });
      
      setIsSubmitted(true);
      
      // Redirect to login page after 3 seconds
      setTimeout(() => {
        navigate('/signin');
      }, 3000);
    } catch (err: any) {
      setError(err?.message || 'An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormDisabled = isSubmitting || isVerifying;

  if (!email || !token) {
    return (
      <Alert title="Invalid Link" color="red" icon={<IconAlertCircle />}>
        The password reset link is invalid or has expired. Please request a new link.
      </Alert>
    );
  }

  if (isSubmitted) {
    return (
      <Alert title="Password Reset Successful" color="green" icon={<IconCheck />}>
        Your password has been reset successfully. You will be redirected to the login page.
      </Alert>
    );
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap="md">
        <Text size="sm">Reset password for: {email}</Text>

        {error && (
          <Alert title="Error" color="red" icon={<IconAlertCircle />}>
            {error}
          </Alert>
        )}

        <PasswordInput
          withAsterisk
          label="New Password"
          placeholder="Enter your new password"
          leftSection={<IconLock size={16} />}
          {...form.getInputProps('password')}
          disabled={isFormDisabled}
        />

        <PasswordInput
          withAsterisk
          label="Confirm New Password"
          placeholder="Confirm your new password"
          leftSection={<IconLock size={16} />}
          {...form.getInputProps('confirmPassword')}
          disabled={isFormDisabled}
        />

        <Button 
          type="submit" 
          fullWidth 
          mt="md"
          loading={isFormDisabled}
          variant="gradient"
          gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
        >
          Reset Password
        </Button>
      </Stack>
    </form>
  );
} 