import { useParams, useNavigate } from 'react-router-dom';
import { Container, Title, Text, Image, Group, Paper, Button, Stack, LoadingOverlay, Modal, Box, Badge } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { blogsApi } from '../api/blogs';
import { useAuth } from '../contexts/AuthContext';
import { ApiError } from '../utils/apiUtils';
import '../styles/BlogPost.css';
import { Helmet } from 'react-helmet-async';
import { stripHtmlAndDecode } from '../utils/textUtils';
import InArticleAd from '../components/AdSense/InArticleAd';
import { contentBox, gradientText, imageShadow, paperGradient } from '../styles/common';
import { Link } from 'react-router-dom';
import StructuredData from '../components/SEO/StructuredData';

const splitContentAtParagraph = (content: string, targetParagraph: number) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const paragraphs = Array.from(doc.getElementsByTagName('p'));

  // If we don't have enough paragraphs, return the original content
  if (paragraphs.length <= targetParagraph) {
    return { beforeAd: content, afterAd: '' };
  }

  const beforeContent = document.createElement('div');
  const afterContent = document.createElement('div');
  let currentParagraphCount = 0;

  // Iterate through all elements in the body
  Array.from(doc.body.children).forEach((element) => {
    if (element.tagName.toLowerCase() === 'p') {
      currentParagraphCount++;
    }

    if (currentParagraphCount <= targetParagraph) {
      beforeContent.appendChild(element.cloneNode(true));
    } else {
      afterContent.appendChild(element.cloneNode(true));
    }
  });

  return {
    beforeAd: beforeContent.innerHTML,
    afterAd: afterContent.innerHTML
  };
};

export function BlogPostPage() {
  const { id: slug } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const isAdmin = user?.role === 'Admin';
  const queryClient = useQueryClient();
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);

  // Fetch blog post details
  const { data: post, isLoading } = useQuery({
    queryKey: ['blog', slug],
    queryFn: () => slug ? blogsApi.getBySlug(slug) : null,
    enabled: !!slug,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  // Handle blog deletion
  const { mutate: deleteBlog, isPending: isDeleting } = useMutation({
    mutationFn: blogsApi.delete,
    onSuccess: () => {
      notifications.show({
        title: 'Success',
        message: 'Blog post deleted successfully',
        color: 'green'
      });
      queryClient.invalidateQueries({ queryKey: ['blogs'] });
      navigate('/blog');
    },
    onError: (error: ApiError) => {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to delete blog post',
        color: 'red'
      });
    }
  });

  const handleDelete = () => {
    if (!slug) return;
    deleteBlog(slug);
    closeDeleteModal();
  };

  const handleEdit = () => {
    navigate(`/blog/${slug}/edit`);
  };

  if (isLoading) {
    return (
      <Container size="lg" py="xl">
        <Paper p="md" style={{ position: 'relative', minHeight: '200px' }}>
          <LoadingOverlay visible={true} />
        </Paper>
      </Container>
    );
  }

  if (!post) {
    return (
      <>
        <Helmet>
          <title>Blog Post Not Found - The Fiber Collective</title>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <Container size="lg" py="xl">
          <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
            <Title 
              order={2}
              style={gradientText}
            >
              Blog Post Not Found
            </Title>
          </Paper>
        </Container>
      </>
    );
  }

  const tags = post.tags || [];  // Provide default empty array if tags is undefined
  const keywordTags = tags.length > 0 ? `, ${tags.join(', ')}` : '';

  return (
    <>
      <Helmet>
        <title>{post.headline} - The Fiber Collective</title>
        <meta name="description" content={stripHtmlAndDecode(post.content).substring(0, 155)} />
        <meta name="keywords" content={`crochet, fiber arts, crafting${keywordTags}`} />
        
        {/* Open Graph tags - Critical that these override the default tags */}
        <meta property="og:title" content={post.headline} />
        <meta property="og:description" content={stripHtmlAndDecode(post.content).substring(0, 155)} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="The Fiber Collective" />
        
        {/* Ensure the blog post image is used with absolute URL */}
        {post.imageUrl ? (
          <>
            <meta 
              property="og:image" 
              content={post.imageUrl.startsWith('http') ? post.imageUrl : `https://thefibercollective.com${post.imageUrl}`} 
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content={post.headline} />
          </>
        ) : (
          // Fallback to site logo if no post image
          <meta property="og:image" content="https://thefibercollective.com/logo192.png" />
        )}
        
        {/* Article specific meta tags */}
        <meta property="article:published_time" content={post.createdAt} />
        <meta property="article:modified_time" content={post.updatedAt} />
        {post.section && <meta property="article:section" content={post.section} />}
        {tags.map(tag => (
          <meta property="article:tag" content={tag} key={tag} />
        ))}
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.headline} />
        <meta name="twitter:description" content={stripHtmlAndDecode(post.content).substring(0, 155)} />
        {post.imageUrl && <meta name="twitter:image" content={post.imageUrl.startsWith('http') ? post.imageUrl : `https://thefibercollective.com${post.imageUrl}`} />}

        {/* Canonical URL - Very important for proper sharing */}
        <link rel="canonical" href={`https://thefibercollective.com/blog/${post.slug}`} />
      </Helmet>
      
      {/* Schema.org structured data */}
      <StructuredData
        data={{
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "headline": post.headline,
          "image": post.imageUrl,
          "datePublished": post.createdAt,
          "dateModified": post.updatedAt,
          "author": {
            "@type": "Organization",
            "name": "The Fiber Collective"
          },
          "publisher": {
            "@type": "Organization",
            "name": "The Fiber Collective",
            "logo": {
              "@type": "ImageObject",
              "url": "https://thefibercollective.com/logo192.png"
            }
          },
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": `https://thefibercollective.com/blog/${post.slug}`
          },
          "description": stripHtmlAndDecode(post.content).substring(0, 155),
          "keywords": tags.join(', '),
          "articleSection": post.section,
          "articleBody": stripHtmlAndDecode(post.content)
        }}
      />
      <Container size="lg" py="xl">
        <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
          <LoadingOverlay visible={isDeleting} />
          <Stack gap="xs">
            <Group justify="space-between" mb="xs">
              <Title 
                order={1}
                style={gradientText}
              >
                {post.headline}
              </Title>
              
              {isAdmin && (
                <Group>
                  <Button
                    variant="light"
                    leftSection={<IconEdit size={16} />}
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                  <Button
                    color="red"
                    variant="light"
                    leftSection={<IconTrash size={16} />}
                    onClick={openDeleteModal}
                  >
                    Delete
                  </Button>
                </Group>
              )}
            </Group>

            <Group justify="space-between" align="center">
              <Text size="sm" c="dimmed">
                {new Date(post.createdAt).toLocaleDateString()}
              </Text>

              {tags.length > 0 && (
                <Group gap="xs">
                  {tags.map((tag) => (
                    <Badge
                      key={tag}
                      component={Link}
                      to={`/blog?tag=${encodeURIComponent(tag)}`}
                      variant="light"
                      color="blue"
                      style={{ cursor: 'pointer' }}
                    >
                      {tag}
                    </Badge>
                  ))}
                </Group>
              )}
            </Group>

            <Box style={contentBox}>
              {post.imageUrl && (
                <Box style={{ 
                  float: 'left', 
                  marginRight: '24px', 
                  marginBottom: '16px', 
                  width: '300px',
                  maxWidth: '50%',
                  shapeOutside: 'margin-box',
                }}>
                  <Image
                    src={post.imageUrl}
                    alt={post.headline}
                    fit="cover"
                    style={{
                      ...imageShadow,
                      aspectRatio: '1',
                      width: '100%',
                      height: 'auto'
                    }}
                  />
                </Box>
              )}
              
              {(() => {
                const { beforeAd, afterAd } = splitContentAtParagraph(post.content, 5);
                return (
                  <>
                    <div 
                      dangerouslySetInnerHTML={{ __html: beforeAd }} 
                      className="blog-content blog-content-with-dropcap"
                    />
                    <InArticleAd />
                    <div 
                      dangerouslySetInnerHTML={{ __html: afterAd }} 
                      className="blog-content blog-content-no-dropcap"
                    />
                  </>
                );
              })()}
            </Box>
          </Stack>
        </Paper>

        <Modal 
          opened={deleteModalOpened} 
          onClose={closeDeleteModal}
          title={
            <Text 
              size="lg" 
              fw={600}
              style={gradientText}
            >
              Delete Blog Post
            </Text>
          }
        >
          <Stack>
            <Text>Are you sure you want to delete this blog post? This action cannot be undone.</Text>
            <Group justify="flex-end">
              <Button variant="light" onClick={closeDeleteModal}>Cancel</Button>
              <Button color="red" onClick={handleDelete} loading={isDeleting}>Delete</Button>
            </Group>
          </Stack>
        </Modal>
      </Container>
    </>
  );
} 