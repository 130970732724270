import axios from 'axios';
import { API_BASE_URL } from './config';
import { User } from '../types/api';
import { handleApiError } from '../utils/apiUtils';

interface AuthResponse {
  token: string;
  user: User;
}

interface RegisterRequest {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  recaptchaToken?: string;
}

interface LoginRequest {
  email: string;
  password: string;
  recaptchaToken?: string;
}

interface VerifyEmailRequest {
  email: string;
  token: string;
}

interface ForgotPasswordRequest {
  email: string;
  recaptchaToken?: string;
}

interface ResetPasswordRequest {
  email: string;
  token: string;
  password: string;
  recaptchaToken?: string;
}

interface ResendVerificationEmailRequest {
  email: string;
  recaptchaToken?: string;
}

export const authApi = {
  validateToken: async (token: string): Promise<User> => {
    console.log('[authApi] Validating token');
    try {
      const response = await axios.get<User>(`${API_BASE_URL}/api/auth/validate`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  signInWithGoogle: async (accessToken: string): Promise<AuthResponse> => {
    try {
      const response = await axios.post<AuthResponse>(`${API_BASE_URL}/api/auth/google`, {
        accessToken
      });
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  
  register: async (data: RegisterRequest): Promise<AuthResponse> => {
    try {
      const headers: Record<string, string> = {};
      if (data.recaptchaToken) {
        headers['X-Recaptcha-Token'] = data.recaptchaToken;
      }

      const { recaptchaToken, ...registerData } = data;
      
      const response = await axios.post<AuthResponse>(
        `${API_BASE_URL}/api/auth/register`, 
        registerData,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  
  login: async (data: LoginRequest): Promise<AuthResponse> => {
    try {
      const headers: Record<string, string> = {};
      if (data.recaptchaToken) {
        headers['X-Recaptcha-Token'] = data.recaptchaToken;
      }
      
      const response = await axios.post<AuthResponse>(
        `${API_BASE_URL}/api/auth/login`, 
        { email: data.email, password: data.password },
        { headers }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  
  verifyEmail: async (data: VerifyEmailRequest): Promise<{ message: string }> => {
    try {
      const response = await axios.post<{ message: string }>(`${API_BASE_URL}/api/auth/verify-email`, data);
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  
  forgotPassword: async (data: ForgotPasswordRequest): Promise<{ message: string }> => {
    try {
      const headers: Record<string, string> = {};
      if (data.recaptchaToken) {
        headers['X-Recaptcha-Token'] = data.recaptchaToken;
      }

      const { recaptchaToken, ...requestData } = data;
      
      const response = await axios.post<{ message: string }>(
        `${API_BASE_URL}/api/auth/forgot-password`, 
        requestData,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  
  resetPassword: async (data: ResetPasswordRequest): Promise<{ message: string }> => {
    try {
      const headers: Record<string, string> = {};
      if (data.recaptchaToken) {
        headers['X-Recaptcha-Token'] = data.recaptchaToken;
      }

      const { recaptchaToken, ...requestData } = data;
      
      const response = await axios.post<{ message: string }>(
        `${API_BASE_URL}/api/auth/reset-password`, 
        requestData,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  
  resendVerificationEmail: async (data: ResendVerificationEmailRequest): Promise<{ message: string }> => {
    try {
      const headers: Record<string, string> = {};
      if (data.recaptchaToken) {
        headers['X-Recaptcha-Token'] = data.recaptchaToken;
      }

      const { recaptchaToken, ...requestData } = data;
      
      const response = await axios.post<{ message: string }>(
        `${API_BASE_URL}/api/auth/resend-verification-email`, 
        requestData,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  }
}; 