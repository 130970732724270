import { Button, TextInput, Stack, Text, Alert, Box } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { IconAt, IconCheck } from '@tabler/icons-react';
import { useAuth } from '../../contexts/AuthContext';
import { useReCaptcha } from '../../hooks/useReCaptcha';

interface ResendVerificationEmailProps {
  onSwitchToLogin?: () => void;
}

interface ResendVerificationEmailValues {
  email: string;
}

export function ResendVerificationEmail({ onSwitchToLogin }: ResendVerificationEmailProps) {
  const { resendVerificationEmail } = useAuth();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { verify, isVerifying } = useReCaptcha();
  
  const form = useForm<ResendVerificationEmailValues>({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const handleSubmit = async (values: ResendVerificationEmailValues) => {
    setIsSubmitting(true);
    try {
      // Get reCAPTCHA token
      const recaptchaToken = await verify('resend_verification');
      
      await resendVerificationEmail(values.email, recaptchaToken);
      setIsSubmitted(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormDisabled = isSubmitting || isVerifying;

  if (isSubmitted) {
    return (
      <Stack gap="md">
        <Alert title="Verification Email Sent" color="green" icon={<IconCheck />}>
          If your email is registered and not yet verified, you will receive a new verification link shortly.
          Please check your inbox and spam folders.
        </Alert>
        
        {onSwitchToLogin && (
          <Button 
            variant="subtle" 
            onClick={onSwitchToLogin}
            color="blue"
          >
            Back to Sign In
          </Button>
        )}
      </Stack>
    );
  }

  return (
    <Box maw={400} mx="auto">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="md">
          <Text size="sm" c="dimmed">
            Enter your email address and we'll send you a new verification link.
          </Text>

          <TextInput
            withAsterisk
            label="Email"
            placeholder="your@email.com"
            leftSection={<IconAt size={16} />}
            {...form.getInputProps('email')}
            disabled={isFormDisabled}
          />

          <Button 
            type="submit" 
            fullWidth 
            mt="md"
            loading={isFormDisabled}
            variant="gradient"
            gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
          >
            Resend Verification Email
          </Button>
          
          {onSwitchToLogin && (
            <Button 
              variant="subtle" 
              onClick={onSwitchToLogin}
              color="blue"
              disabled={isFormDisabled}
            >
              Back to Sign In
            </Button>
          )}
        </Stack>
      </form>
    </Box>
  );
} 