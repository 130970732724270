import { Center } from '@mantine/core';
import { IconMessageCircle } from '@tabler/icons-react';

interface CommentCountProps {
    count: number;
    onClick?: (e: React.MouseEvent) => void;
}

export function CommentCount({ count, onClick }: CommentCountProps) {
    return (
        <Center 
            onClick={onClick}
            style={{ 
                cursor: onClick ? 'pointer' : 'default',
                gap: '4px'
            }}
        >
            <IconMessageCircle size={16} />
            {count}
        </Center>
    );
} 