import { Container, Title, Paper, Box, Center } from '@mantine/core';
import { EmailVerification } from '../components/auth/EmailVerification';
import { contentBox, gradientText, paperGradient } from '../styles/common';

export function VerifyEmailPage() {
  return (
    <Container size="sm" py="xl">
      <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
        <Center>
          <Box style={contentBox} maw={450} w="100%">
            <Title 
              order={2}
              ta="center"
              mb="xl"
              style={gradientText}
            >
              Email Verification
            </Title>
            <EmailVerification />
          </Box>
        </Center>
      </Paper>
    </Container>
  );
} 