import { Container, SimpleGrid, Group, Center, Loader, Button, Title, Box, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { blogsApi } from '../api/blogs';
import { BlogCard } from '../components/BlogCard';
import { TagFilter } from '../components/TagFilter';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
import { useInfinitePagination } from '../hooks/useInfinitePagination';
import { PaginationParams } from '../types/common';
import { BlogPostSummary } from '../types/Blog';

export function BlogListPage() {
  const pageSize = 9;
  const { user } = useAuth();
  const isAdmin = user?.role === 'Admin';
  const [searchParams] = useSearchParams();
  const selectedTag = searchParams.get('tag');

  const { data: tags = [] } = useQuery({
    queryKey: ['blog-tags'],
    queryFn: () => blogsApi.getTags(),
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  // Use our custom infinite pagination hook
  const {
    items: blogs,
    isFetching,
    isLoading,
    hasNextPage,
    ref
  } = useInfinitePagination<BlogPostSummary, PaginationParams>({
    queryKey: ['blogs', selectedTag],
    fetchFn: blogsApi.getPaginated,
    baseParams: {
      page: 1,
      pageSize,
      tag: selectedTag || undefined
    },
    pageSize
  });

  return (
    <>
      <Helmet>
        <title>{selectedTag ? `${selectedTag} - Blog` : 'Blog'} - The Fiber Collective</title>
        <meta 
          name="description" 
          content={selectedTag 
            ? `Read our latest blog posts about ${selectedTag} in crochet, fiber arts, and crafting.`
            : "Read our latest blog posts about crochet, fiber arts, and crafting."
          } 
        />
        
        {/* Open Graph tags */}
        <meta property="og:title" content={`${selectedTag ? `${selectedTag} - ` : ''}Blog - The Fiber Collective`} />
        <meta 
          property="og:description" 
          content={selectedTag 
            ? `Read our latest blog posts about ${selectedTag} in crochet, fiber arts, and crafting.`
            : "Read our latest blog posts about crochet, fiber arts, and crafting."
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`${selectedTag ? `${selectedTag} - ` : ''}Blog - The Fiber Collective`} />
        <meta 
          name="twitter:description" 
          content={selectedTag 
            ? `Read our latest blog posts about ${selectedTag} in crochet, fiber arts, and crafting.`
            : "Read our latest blog posts about crochet, fiber arts, and crafting."
          }
        />
      </Helmet>
      
      <Container size="lg" py="xl">
        <TagFilter tags={tags} />

        {selectedTag && (
          <Box mb="xl">
            <Group justify="space-between" align="center">
              <Title order={2}>Posts tagged with "{selectedTag}"</Title>
              <Button 
                variant="subtle"
                component={Link}
                to="/blog"
              >
                Clear filter
              </Button>
            </Group>
          </Box>
        )}

        {isAdmin && (
          <Group justify="flex-end" mb="md">
            <Button
              component={Link}
              to="/blog/create"
              variant="light"
            >
              New Post
            </Button>
          </Group>
        )}

        {isLoading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <SimpleGrid 
            spacing="lg"
            style={{
              justifyItems: 'center',
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))'
            }}
          >
            {blogs.map((post) => (
              <BlogCard
                key={post.id}
                post={post}
              />
            ))}
          </SimpleGrid>
        )}

        <div ref={ref} style={{ height: '20px', margin: '20px 0' }}>
          {isFetching && hasNextPage && (
            <Center>
              <Loader size="sm" />
            </Center>
          )}
          
          {!hasNextPage && blogs.length > 0 && (
            <Text ta="center" c="dimmed" size="sm">
              No more posts to load
            </Text>
          )}
        </div>
      </Container>
    </>
  );
} 