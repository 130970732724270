import React from 'react';
import { Paper, Text, Flex, Badge, ActionIcon } from '@mantine/core';
import { gradientText } from '../styles/common';

interface AiGeneratedPatternNoticeProps {
  onClose?: () => void;
  position?: 'fixed' | 'static';
}

export function AiGeneratedPatternNotice({ 
  onClose, 
  position = 'fixed' 
}: AiGeneratedPatternNoticeProps) {
  return (
    <Paper
      p="xs"
      withBorder
      radius="md"
      style={{
        ...(position === 'fixed' ? {
          position: 'fixed',
          bottom: 16,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1000,
        } : {}),
        backgroundColor: 'var(--mantine-color-gray-0)',
        borderColor: 'var(--mantine-color-blue-3)',
        maxWidth: '600px',
        width: 'calc(100% - 32px)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
      }}
    >
      <div>
        <Flex justify="space-between" align="center" mb={2}>
          <Text fw={500} size="xs" style={gradientText}>
            AI-Generated Pattern Notice
          </Text>
          <Flex align="center" gap="xs">
            <Text c="dimmed" style={{ fontSize: '0.65rem' }}>Tested patterns display:</Text>
            <Badge 
              size="xs"
              variant="filled"
              className="verified-badge"
            >
              Verified
            </Badge>
            {onClose && (
              <ActionIcon 
                size="xs" 
                variant="transparent"
                onClick={onClose}
                aria-label="Close banner"
              >
                ✕
              </ActionIcon>
            )}
          </Flex>
        </Flex>
        <Text c="dimmed" style={{ fontSize: '0.65rem', lineHeight: 1.3 }}>
          Patterns on The Fiber Collective are AI-generated and unverified patterns may contain errors. We're always improving our algorithms and encourage users to earn karma by testing and verifying patterns!
        </Text>
      </div>
    </Paper>
  );
} 