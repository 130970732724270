import { Container, Paper, Title, Tabs, LoadingOverlay } from '@mantine/core';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { PendingComments } from '../components/admin/PendingComments';
import { UsersTable } from '../components/admin/UsersTable';
import { TestJournalsTable } from '../components/admin/TestJournalsTable';
import { EventsTable } from '../components/admin/EventsTable';
import { gradientText, paperGradient } from '../styles/common';
import { IconMessage, IconUsers, IconFileReport, IconCalendarEvent } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

export function AdminDashboard() {
    const { user, isLoading } = useAuth();
    const [activeTab, setActiveTab] = useState(() => {
        return localStorage.getItem('adminDashboardTab') || 'users';
    });

    useEffect(() => {
        localStorage.setItem('adminDashboardTab', activeTab);
    }, [activeTab]);

    const handleTabChange = (value: string | null) => {
        setActiveTab(value || 'users');
    };

    // Show loading state while auth is being checked
    if (isLoading) {
        return (
            <Container size="xl" py="xl">
                <Paper shadow="md" p="xl" radius="lg" withBorder style={{ ...paperGradient, position: 'relative', minHeight: '200px' }}>
                    <LoadingOverlay visible={true} />
                </Paper>
            </Container>
        );
    }

    // Redirect non-admin users only after loading is complete
    if (!user || user.role !== 'Admin') {
        return <Navigate to="/" replace />;
    }

    return (
        <Container size="xl" py="xl">
            <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
                <Title order={2} mb="xl" style={gradientText}>Admin Dashboard</Title>
                
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tabs.List>
                        <Tabs.Tab 
                            value="users" 
                            leftSection={<IconUsers size={16} />}
                        >
                            Users
                        </Tabs.Tab>
                        <Tabs.Tab 
                            value="pending-comments" 
                            leftSection={<IconMessage size={16} />}
                        >
                            Pending Comments
                        </Tabs.Tab>
                        <Tabs.Tab 
                            value="test-journals" 
                            leftSection={<IconFileReport size={16} />}
                        >
                            Test Journals
                        </Tabs.Tab>
                        <Tabs.Tab 
                            value="events" 
                            leftSection={<IconCalendarEvent size={16} />}
                        >
                            Events
                        </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="users" pt="xl">
                        <UsersTable />
                    </Tabs.Panel>

                    <Tabs.Panel value="pending-comments" pt="xl">
                        <PendingComments />
                    </Tabs.Panel>

                    <Tabs.Panel value="test-journals" pt="xl">
                        <TestJournalsTable />
                    </Tabs.Panel>
                    
                    <Tabs.Panel value="events" pt="xl">
                        <EventsTable />
                    </Tabs.Panel>
                </Tabs>
            </Paper>
        </Container>
    );
} 