import { Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { BlogPostSummary } from '../types/Blog';
import { stripHtmlAndDecode } from '../utils/textUtils';
import { ContentCard } from './common/ContentCard';
import { formatDate } from '../utils/dateUtils';

interface BlogCardProps {
  post: BlogPostSummary;
}

export function BlogCard({ post }: BlogCardProps) {
  const navigate = useNavigate();
  
  // Convert to a format ContentCard can use
  const blogAsContent = {
    ...post,
    id: post.id,
    title: post.headline,
    description: stripHtmlAndDecode(post.snippet),
    tags: post.tags || [],
    imageUrl: post.imageUrl,
    isOwner: false, // Blog posts don't track ownership in the client UI
    createdAt: post.createdAt,
    updatedAt: post.createdAt // Blog summary might not include updatedAt
  };
  
  const handleCardClick = () => {
    navigate(`/blog/${post.slug}`);
  };
  
  // Render the date at the bottom of the card
  const renderFooter = () => (
    <Text size="xs" c="dimmed" mt="auto" fs="italic" style={{ marginTop: 'auto', alignSelf: 'flex-start' }}>
      {formatDate(post.createdAt)}
    </Text>
  );
  
  return (
    <ContentCard
      item={blogAsContent}
      onClick={handleCardClick}
      renderFooter={renderFooter}
      maxDescriptionLines={3}
      disableExpand={true}
    />
  );
} 