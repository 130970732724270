import { Card, Image, Text, Group, Tooltip, Badge, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { HeartButton } from './HeartButton';
import { CommentCount } from './CommentCount';
import { PatternCategory, ProjectType } from '../types/api';
import { useQueryClient } from '@tanstack/react-query';
import { getFullImageUrl } from '../utils/imageUtils';
import { PatternBadges } from './PatternBadges';
import { useEffect } from 'react';
import { metallicGoldStyle } from '../styles/common';

interface PatternCardProps {
    id: string;
    name: string;
    description: string;
    imageUrl?: string;
    heartCount: number;
    commentCount: number;
    isHearted: boolean;
    difficultyLevel: string;
    category: PatternCategory;
    projectType: ProjectType;
    categoryDisplayName: string;
    isCreator?: boolean;
    isVerified?: boolean;
}

export function PatternCard({ 
    id, 
    name,
    description,
    imageUrl, 
    heartCount, 
    commentCount,
    isHearted,
    difficultyLevel,
    category,
    projectType,
    categoryDisplayName,
    isCreator = false,
    isVerified = false,
}: PatternCardProps) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!document.getElementById('metallic-gold-style-card')) {
            const styleEl = document.createElement('style');
            styleEl.id = 'metallic-gold-style-card';
            styleEl.innerHTML = metallicGoldStyle;
            document.head.appendChild(styleEl);
        }
        
        return () => {
            const styleEl = document.getElementById('metallic-gold-style-card');
            if (styleEl && document.head.contains(styleEl)) {
                document.head.removeChild(styleEl);
            }
        };
    }, []);

    const handleCardClick = (e: React.MouseEvent) => {
        const target = e.target as HTMLElement;
        if (!target.closest('.heart-button')) {
            navigate(`/patterns/${id}`);
        }
    };

    const handleCommentClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        navigate(`/patterns/${id}#comments`);
    };

    return (
        <Card 
            shadow="sm" 
            padding="xs"
            radius="md" 
            withBorder 
            onClick={handleCardClick}
            style={{ 
                cursor: 'pointer',
                width: '300px',
                display: 'flex',
                flexDirection: 'column',
                background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)',
                boxShadow: '0 4px 20px rgba(34, 139, 230, 0.15)'
            }}
        >
            <Tooltip
                label={description}
                position="bottom"
                multiline
                w={300}
                withArrow
                transitionProps={{ transition: 'fade', duration: 200 }}
            >
                <Card.Section style={{ position: 'relative' }}>
                    <Image
                        src={imageUrl ? getFullImageUrl(imageUrl) : '/placeholder.png'}
                        height={300}
                        alt={name}
                        fit="contain"
                        style={{ 
                            width: '100%'
                        }}
                    />
                    <div 
                        style={{
                            position: 'absolute',
                            top: '8px',
                            left: '8px',
                        }}
                    >
                        <Stack gap={4}>
                            {isVerified && (
                                <Badge 
                                    variant="filled"
                                    size="sm"
                                    className="verified-badge"
                                >
                                    Verified
                                </Badge>
                            )}
                            {isCreator && (
                                <Badge 
                                    variant="filled"
                                    size="sm"
                                    color="grape"
                                    styles={{ root: { opacity: 0.8 } }}
                                >
                                    Created
                                </Badge>
                            )}
                        </Stack>
                    </div>
                    <Group 
                        style={{
                            position: 'absolute',
                            top: '8px',
                            right: '8px',
                            gap: '4px',
                            backgroundColor: 'rgba(255, 255, 255, 0.4)',
                            padding: '2px 4px',
                            borderRadius: '6px',
                            backdropFilter: 'blur(4px)',
                            display: 'inline-flex'
                        }}
                    >
                        <CommentCount 
                            count={commentCount} 
                            onClick={handleCommentClick}
                        />
                        <HeartButton
                            patternId={id}
                            heartCount={heartCount}
                            isHearted={isHearted}
                            onHeartToggle={() => queryClient.invalidateQueries({ queryKey: ['patterns'] })}
                        />
                    </Group>
                    <div 
                        style={{
                            position: 'absolute',
                            bottom: '8px',
                            left: '8px',
                        }}
                    >
                        <PatternBadges
                            difficultyLevel={difficultyLevel}
                            category={category}
                            projectType={projectType}
                            size="sm"
                            isVerified={false}
                        />
                    </div>
                </Card.Section>
            </Tooltip>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginTop: '4px' }}>
                <Text 
                    fw={600} 
                    size="lg" 
                    mt={0}
                    lineClamp={2}
                    style={{
                        background: 'linear-gradient(45deg, #228BE6, #40C057)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        letterSpacing: '0.3px'
                    }}
                >
                    {name}
                </Text>
            </div>
        </Card>
    );
} 