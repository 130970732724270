import React from 'react';
import {
  Card,
  Text,
  Group,
  Stack,
  Box,
  Image,
  ActionIcon
} from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { JournalEntry } from '../api/patternTestJournals';
import { formatDate } from '../utils/dateUtils';
import { getFullImageUrl } from '../utils/imageUtils';

interface JournalEntryCardProps {
  entry: JournalEntry;
  canEdit?: boolean;
  onEdit?: (entry: JournalEntry) => void;
  onDelete?: (entryId: string) => void;
}

export function JournalEntryCard({ entry, canEdit = false, onEdit, onDelete }: JournalEntryCardProps) {
  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Stack gap="md">
        <Group align="flex-start" gap="md">
          {entry.imageUrl ? (
            <>
              <Box style={{ flex: 1, minWidth: 0 }}>
                <Text style={{ wordWrap: 'break-word' }}>{entry.comment}</Text>
              </Box>
              <Box style={{ flexShrink: 0, maxWidth: '200px' }}>
                <Image 
                  src={getFullImageUrl(entry.imageUrl)} 
                  alt="Entry image" 
                  radius="md"
                  height={150}
                  width={200}
                  fit="contain"
                  onClick={() => window.open(getFullImageUrl(entry.imageUrl), '_blank')}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </>
          ) : (
            <Box style={{ flex: 1 }}>
              <Text style={{ wordWrap: 'break-word' }}>{entry.comment}</Text>
            </Box>
          )}
        </Group>
        
        <Group justify="space-between" align="center">
          <Text size="sm" c="dimmed">
            {formatDate(entry.createdAt)}
          </Text>
          {canEdit && (
            <Group gap="xs">
              {onEdit && (
                <ActionIcon 
                  variant="light" 
                  color="blue" 
                  onClick={() => onEdit(entry)}
                  aria-label="Edit entry"
                >
                  <IconEdit size={16} />
                </ActionIcon>
              )}
              {onDelete && (
                <ActionIcon 
                  variant="light" 
                  color="red" 
                  onClick={() => onDelete(entry.id)}
                  aria-label="Delete entry"
                >
                  <IconTrash size={16} />
                </ActionIcon>
              )}
            </Group>
          )}
        </Group>
      </Stack>
    </Card>
  );
} 