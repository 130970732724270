import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { HomePage } from './pages/HomePage';
import { ProfilePage } from './pages/ProfilePage';
import { PatternDetailPage } from './pages/PatternDetailPage';
import { GeneratePatternPage } from './pages/GeneratePatternPage';
import { EditPatternPage } from './pages/EditPatternPage';
import { SignInPage } from './pages/SignInPage';
import { VerifyEmailPage } from './pages/VerifyEmailPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { ResendVerificationPage } from './pages/ResendVerificationPage';
import { ProtectedRoute } from './components/ProtectedRoute';
import './App.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './contexts/AuthContext';
import { EditProfilePage } from './pages/EditProfilePage';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './lib/queryClient';
import { BlogListPage } from './pages/BlogListPage';
import { BlogPostPage } from './pages/BlogPostPage';
import { BlogEditPage } from './pages/BlogEditPage';
import { FloatingHelpButton } from './components/FloatingHelpButton';
import { AboutPage } from './pages/AboutPage';
import { TermsPage } from './pages/TermsPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { FloatingSocialButtons } from './components/FloatingSocialButtons';
import { HelmetProvider } from 'react-helmet-async';
import { SubscriptionSuccessPage } from './pages/SubscriptionSuccessPage';
import { SubscriptionCanceledPage } from './pages/SubscriptionCanceledPage';
import { StripeProvider } from './contexts/StripeContext';
import { AdminDashboard } from './pages/AdminDashboard';
import { PatternTestJournalPage } from './pages/PatternTestJournalPage';
import { AdminJournalDetailPage } from './pages/AdminJournalDetailPage';
import { EventsListPage } from './pages/EventsListPage';
import { EventEditPage } from './pages/EventEditPage';
import { GoogleAnalytics } from './components/GoogleAnalytics';
import GlobalSEO from './components/SEO/GlobalSEO';
import React from 'react';

function AppContent() {
  const location = useLocation();
  const showSocialButtons = location.pathname.startsWith('/blog/') || location.pathname.startsWith('/patterns/');
  // Exclude the edit paths
  const isEditPath = location.pathname.includes('/edit');
  const shouldShowSocialButtons = showSocialButtons && !isEditPath;

  // Scroll to top on route change
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div style={{ paddingTop: '60px' }}>
      <GlobalSEO />
      <GoogleAnalytics />
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/resend-verification" element={<ResendVerificationPage />} />
        <Route 
          path="/profile" 
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          } 
        />
        <Route path="/patterns/:id" element={<PatternDetailPage />} />
        <Route 
          path="/generate" 
          element={
            <ProtectedRoute>
              <GeneratePatternPage />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/patterns/:id/edit" 
          element={
            <ProtectedRoute>
              <EditPatternPage />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/profile/edit" 
          element={
            <ProtectedRoute>
              <EditProfilePage />
            </ProtectedRoute>
          } 
        />
        <Route path="/blog" element={<BlogListPage />} />
        <Route path="/blog/:id" element={<BlogPostPage />} />
        <Route 
          path="/blog/create" 
          element={
            <ProtectedRoute>
              <BlogEditPage />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/blog/:id/edit" 
          element={
            <ProtectedRoute>
              <BlogEditPage />
            </ProtectedRoute>
          } 
        />
        <Route path="/events" element={<EventsListPage />} />
        <Route 
          path="/events/create" 
          element={
            <ProtectedRoute>
              <EventEditPage />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/events/:id/edit" 
          element={
            <ProtectedRoute>
              <EventEditPage />
            </ProtectedRoute>
          } 
        />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/subscription/success" element={<SubscriptionSuccessPage />} />
        <Route path="/subscription/canceled" element={<SubscriptionCanceledPage />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route 
          path="/admin/journal/:journalId" 
          element={
            <ProtectedRoute>
              <AdminJournalDetailPage />
            </ProtectedRoute>
          }
        />
        <Route 
          path="/pattern-test-journal/:patternId" 
          element={
            <ProtectedRoute>
              <PatternTestJournalPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<HomePage />} />
      </Routes>
      {shouldShowSocialButtons && <FloatingSocialButtons />}
      <FloatingHelpButton />
    </div>
  );
}

export default function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
          <AuthProvider>
            <StripeProvider>
              <BrowserRouter>
                <Notifications position="top-right" />
                <AppContent />
              </BrowserRouter>
            </StripeProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
} 