import { Container, Title, Paper, TextInput, Button, Stack, LoadingOverlay, Group } from '@mantine/core';
import { useAuth } from '../contexts/AuthContext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { userApi } from '../api/user';
import { ApiError } from '../utils/apiUtils';
import { queryClient } from '../lib/queryClient';
import { useLocation } from 'react-router-dom';
import { paperGradient, gradientText } from '../styles/common';

export function EditProfilePage() {
    const { user, isLoading } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [displayName, setDisplayName] = useState(user?.displayName || '');

    const updateProfileMutation = useMutation({
        mutationFn: userApi.updateProfile,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['auth', 'validate'] });
            notifications.show({
                title: 'Success',
                message: 'Profile updated successfully',
                color: 'green'
            });
            navigate('/profile');
        },
        onError: (error: ApiError) => {
            notifications.show({
                title: 'Error',
                message: error.message || 'Failed to update profile',
                color: 'red'
            });
        }
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!user) return;

        await updateProfileMutation.mutate({
            displayName: displayName.trim(),
            email: user.email,
            profileImageUrl: user.profileImageUrl
        });
    };

    const handleCancel = () => {
        navigate('/profile');
    };

    if (isLoading) {
        return (
            <Container size="sm" py="xl">
                <Paper shadow="md" p="xl" radius="lg" withBorder style={{ position: 'relative', minHeight: '200px', ...paperGradient }}>
                    <LoadingOverlay visible={true} />
                </Paper>
            </Container>
        );
    }

    if (!user) {
        navigate('/signin', { state: { from: location.pathname } });
        return null;
    }

    return (
        <Container size="sm" py="xl">
            <Paper shadow="md" p="xl" radius="lg" withBorder style={{ position: 'relative', ...paperGradient }}>
                <LoadingOverlay visible={updateProfileMutation.isPending} />
                <Title 
                    order={2} 
                    mb="xl"
                    style={gradientText}
                >
                    Edit Profile
                </Title>
                <form onSubmit={handleSubmit}>
                    <Stack>
                        <TextInput
                            label="Display Name"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                            required
                            size="md"
                        />
                        <Group justify="flex-end" mt="md">
                            <Button 
                                variant="subtle" 
                                onClick={handleCancel}
                                disabled={updateProfileMutation.isPending}
                            >
                                Cancel
                            </Button>
                            <Button 
                                type="submit" 
                                loading={updateProfileMutation.isPending}
                                variant="gradient"
                                gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
                            >
                                Save Changes
                            </Button>
                        </Group>
                    </Stack>
                </form>
            </Paper>
        </Container>
    );
} 