import React, { useState } from 'react';
import { Container, SimpleGrid, Group, Center, Loader, TextInput, MultiSelect, Select, Button, Text } from '@mantine/core';
import { patternsApi } from '../api/patterns';
import { PatternCategory, CrochetPatternSummary, SortOption, ProjectType } from '../types/api';
import { PatternCardWithAds } from '../components/PatternCardWithAds';
import { CATEGORY_OPTIONS, DIFFICULTY_OPTIONS } from '../utils/categoryUtils';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { PaginationParams } from '../types/common';
import { useFilters } from '../hooks/useFilters';
import { useInfinitePagination } from '../hooks/useInfinitePagination';
import { AiGeneratedPatternNotice } from '../components/AiGeneratedPatternNotice';

// Define our custom pattern filter type
interface PatternFilterParams extends PaginationParams {
  difficulties?: string[];
  categories?: PatternCategory[];
  searchTerm?: string;
  hearted?: boolean;
  sortBy: SortOption;
  ownedByUser?: boolean;
  viewedByUser?: boolean;
  projectType?: ProjectType;
}

// Define the filter state type
type PatternFilterState = {
  difficulties: string[];
  categories: string[];
  searchTerm: string;
  hearted: boolean;
  sortBy: SortOption;
  ownedByUser: boolean;
  viewedByUser: boolean;
  projectType: ProjectType | undefined;
}

// Define filter key type
type PatternFilterKey = keyof PatternFilterState;

export function HomePage() {
  const { user } = useAuth();
  const [showBanner, setShowBanner] = useState(true);
  const pageSize = 24; // Least common multiple of 3 and 8
  
  // Use our custom filter hook
  const { 
    filters, 
    handleFilterChange,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateFilters,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clearFilters
  } = useFilters<PatternFilterState, PatternFilterKey>({
    difficulties: [],
    categories: [],
    searchTerm: '',
    hearted: false,
    sortBy: SortOption.Newest,
    ownedByUser: false,
    viewedByUser: false,
    projectType: undefined,
  });

  // Add state for the submitted search term
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState('');
  
  // Function to hide banner
  const hideBanner = () => {
    setShowBanner(false);
  };

  const SORT_OPTIONS = [
    { value: SortOption.Newest, label: 'Newest First' },
    { value: SortOption.Oldest, label: 'Oldest First' },
    { value: SortOption.TitleAsc, label: 'Title (A-Z)' },
    { value: SortOption.TitleDesc, label: 'Title (Z-A)' },
    { value: SortOption.CategoryAsc, label: 'Category (A-Z)' },
    { value: SortOption.CategoryDesc, label: 'Category (Z-A)' },
    { value: SortOption.MostLiked, label: 'Most Liked' }
  ];

  const PROJECT_TYPE_OPTIONS = [
    { value: ProjectType.Crochet, label: 'Crochet' },
    { value: ProjectType.Knit, label: 'Knit' },
    { value: ProjectType.Needlepoint, label: 'Needle Point' }
  ];

  // Handle search form submission
  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmittedSearchTerm(filters.searchTerm);
  };

  // Use our custom infinite pagination hook
  const {
    items: patterns,
    isFetching,
    isLoading,
    hasNextPage,
    ref
  } = useInfinitePagination<CrochetPatternSummary, PatternFilterParams>({
    queryKey: ['patterns', { 
      ...filters, 
      searchTerm: submittedSearchTerm 
    }],
    fetchFn: patternsApi.getPaginated,
    baseParams: {
      page: 1,
      pageSize,
      difficulties: filters.difficulties.length > 0 ? filters.difficulties : undefined,
      searchTerm: submittedSearchTerm.trim() || undefined,
      categories: filters.categories.length > 0 
        ? filters.categories.map(c => Number(c) as PatternCategory) 
        : undefined,
      hearted: filters.hearted,
      ownedByUser: filters.ownedByUser,
      viewedByUser: filters.viewedByUser,
      sortBy: filters.sortBy,
      projectType: filters.projectType
    },
    pageSize
  });

  return (
    <>
      <Helmet>
        <title>The Fiber Collective - Crochet Patterns</title>
        <meta name="description" content="Discover and create beautiful crochet patterns. Join our community of fiber artists and explore a world of creative possibilities." />
      </Helmet>
      <Container fluid py="md">
        <Group>
          <div style={{ width: '100%' }}>
            <Group mb="md" align="center" gap="xs">
              <form onSubmit={handleSearchSubmit} style={{ display: 'flex', flex: 1 }}>
                <TextInput
                  placeholder="Search"
                  value={filters.searchTerm}
                  onChange={(event) => handleFilterChange('searchTerm', event.currentTarget.value)}
                  styles={{
                    root: {
                      flex: 1,
                      minWidth: '100px'
                    },
                    input: {
                      minWidth: '100px'
                    }
                  }}
                />
              </form>
              <Select
                data={PROJECT_TYPE_OPTIONS}
                placeholder="Type"
                value={filters.projectType}
                onChange={(value) => handleFilterChange('projectType', value || undefined)}
                styles={{
                  root: {
                    width: '140px'
                  },
                  wrapper: {
                    width: '140px'
                  },
                  input: {
                    width: '140px',
                    pointerEvents: 'auto'
                  }
                }}
                clearable
              />
              <MultiSelect
                data={CATEGORY_OPTIONS}
                placeholder="Category"
                value={filters.categories}
                onChange={(values) => handleFilterChange('categories', values)}
                styles={{
                  root: {
                    width: '165px'
                  },
                  wrapper: {
                    width: '165px'
                  },
                  input: {
                    width: '165px'
                  }
                }}
              />
              <MultiSelect
                data={DIFFICULTY_OPTIONS}
                placeholder="Difficulty"
                value={filters.difficulties}
                onChange={(values) => handleFilterChange('difficulties', values)}
                styles={{
                  root: {
                    width: '155px'
                  },
                  wrapper: {
                    width: '155px'
                  },
                  input: {
                    width: '155px'
                  }
                }}
              />
              <Select
                data={SORT_OPTIONS}
                placeholder="Sort"
                value={filters.sortBy}
                onChange={(value) => {
                  if (value === null) {
                    // If sortBy is null, default to TitleAsc
                    handleFilterChange('sortBy', SortOption.TitleAsc);
                  } else {
                    handleFilterChange('sortBy', value as SortOption);
                  }
                }}
                styles={{
                  root: {
                    width: '160px'
                  },
                  wrapper: {
                    width: '160px'
                  },
                  input: {
                    width: '160px',
                    pointerEvents: 'auto'
                  }
                }}
              />
              {user && (
                <>
                  <Button
                    variant={filters.hearted ? "filled" : "light"}
                    onClick={() => handleFilterChange('hearted', !filters.hearted)}
                    color="red"
                  >
                    My ❤️
                  </Button>
                  <Button
                    variant={filters.ownedByUser ? "filled" : "light"}
                    onClick={() => handleFilterChange('ownedByUser', !filters.ownedByUser)}
                    color="grape"
                  >
                    Created
                  </Button>
                  {user.role === 'User' && (
                    <Button
                      variant={filters.viewedByUser ? "filled" : "light"}
                      onClick={() => handleFilterChange('viewedByUser', !filters.viewedByUser)}
                      color="teal"
                    >
                      Viewed
                    </Button>
                  )}
                </>
              )}
            </Group>
          </div>
        </Group>
      
        {isLoading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <>
            <SimpleGrid 
                spacing="lg"
                style={{
                    justifyItems: 'center',
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))'
                }}
            >
                {patterns.map((pattern, index) => (
                    <PatternCardWithAds
                        key={pattern.id}
                        pattern={pattern}
                        index={index}
                    />
                ))}
            </SimpleGrid>

            <div ref={ref} style={{ height: '20px', margin: '20px 0' }}>
              {isFetching && hasNextPage && (
                <Center>
                  <Loader size="sm" />
                </Center>
              )}
              
              {!hasNextPage && patterns.length > 0 && (
                <Text ta="center" c="dimmed" size="sm">
                  No more patterns to load
                </Text>
              )}
            </div>
          </>
        )}
      </Container>

      {/* AI Pattern Notification Banner */}
      {showBanner && (
        <AiGeneratedPatternNotice onClose={hideBanner} position="fixed" />
      )}
    </>
  );
} 