import { apiClient } from './client';
import { Comment } from '../types/Comment';
import { getAuthHeaders } from '../utils/authHeaders';
import { handleApiError } from '../utils/apiUtils';

export const commentsApi = {
  getApprovedComments: async (patternId: string): Promise<Comment[]> => {
    try {
      const { data } = await apiClient.get<Comment[]>(
        `/comment/pattern/${patternId}/approved`
      );
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getPendingComments: async (patternId: string): Promise<Comment[]> => {
    try {
      const { data } = await apiClient.get<Comment[]>(
        `/comment/pattern/${patternId}/pending`,
        { headers: getAuthHeaders() }
      );
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  addComment: async (patternId: string, text: string, recaptchaToken: string): Promise<Comment> => {
    try {
      const { data } = await apiClient.post<Comment>(
        `/comment/pattern/${patternId}`,
        { text },
        {
          headers: {
            ...getAuthHeaders(),
            'X-Recaptcha-Token': recaptchaToken,
          }
        }
      );
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  editComment: async (patternId: string, commentId: string, text: string, recaptchaToken: string): Promise<void> => {
    try {
      await apiClient.put(
        `/comment/pattern/${patternId}/${commentId}`,
        { text },
        {
          headers: {
            ...getAuthHeaders(),
            'X-Recaptcha-Token': recaptchaToken,
          }
        }
      );
    } catch (error) {
      throw handleApiError(error);
    }
  },

  deleteComment: async (patternId: string, commentId: string, recaptchaToken: string): Promise<void> => {
    try {
      await apiClient.delete(
        `/comment/pattern/${patternId}/${commentId}`,
        { 
          headers: {
            ...getAuthHeaders(),
            'X-Recaptcha-Token': recaptchaToken,
          }
        }
      );
    } catch (error) {
      throw handleApiError(error);
    }
  },

  approveComment: async (patternId: string, commentId: string): Promise<void> => {
    try {
      await apiClient.post(
        `/comment/pattern/${patternId}/approve/${commentId}`,
        {},
        { headers: getAuthHeaders() }
      );
    } catch (error) {
      throw handleApiError(error);
    }
  },

  rejectComment: async (patternId: string, commentId: string): Promise<void> => {
    try {
      await apiClient.post(
        `/comment/pattern/${patternId}/reject/${commentId}`,
        {},
        { headers: getAuthHeaders() }
      );
    } catch (error) {
      throw handleApiError(error);
    }
  }
}; 