import { Container, Group, Button, Menu, Box, Avatar, Badge, Text, Stack, Burger, Drawer, ActionIcon } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { IconLogout, IconSettings, IconDashboard, IconWand, IconArticle, IconHome, IconCalendarEvent } from '@tabler/icons-react';
import { StripeCheckoutButton } from '../components/StripeCheckoutButton';
import { useDisclosure } from '@mantine/hooks';
import { gradientText } from '../styles/common';
import { KarmaModal } from './KarmaModal';

export function Navbar() {
    const location = useLocation();
    const { user, signOut } = useAuth();
    const navigate = useNavigate();
    const isAdmin = user?.role === 'Admin';
    const showSubscribeButton = user && user.role !== 'Admin' && user.role !== 'Subscriber';
    const [mobileOpened, { toggle: toggleMobile, close: closeMobile }] = useDisclosure(false);
    const [karmaModalOpened, { open: openKarmaModal, close: closeKarmaModal }] = useDisclosure(false);

    // Progressive breakpoints for responsive design - in descending order (from largest to smallest width)
    const isEventsIcon = useMediaQuery('(max-width: 830px)'); // Events turns to icon first
    const isBlogIcon = useMediaQuery('(max-width: 775px)'); // Blog turns to icon next
    const isHomeIcon = useMediaQuery('(max-width: 720px)'); // Home turns to icon after Blog
    const isSubscribeButtonShort = useMediaQuery('(max-width: 700px)');
    const isSubscribeTextShort = useMediaQuery('(max-width: 650px)');
    const isHomeAndBlogInDrawer = useMediaQuery('(max-width: 640px)'); // Home/Blog collapse into drawer last
    const isGenerateIcon = useMediaQuery('(max-width: 585px)');
    const isKarmaInDrawer = useMediaQuery('(max-width: 505px)');
    const isGenerateInDrawer = useMediaQuery('(max-width: 405px)');
    const isViewsInDrawer = useMediaQuery('(max-width: 360px)');

    const handleLogout = () => {
        signOut();
        navigate('/');
        closeMobile();
    };

    const isActivePath = (path: string) => {
        if (path === '/') {
            return location.pathname === '/';
        }
        return location.pathname.startsWith(path);
    };

    // Navigation items that adapt based on screen size
    const homeAndBlogButtons = (
        <>
            {isHomeIcon ? (
                <ActionIcon
                    component={Link}
                    to="/"
                    variant={isActivePath('/') ? 'light' : 'subtle'}
                    color={isActivePath('/') ? 'blue' : 'gray'}
                    onClick={closeMobile}
                    size="lg"
                >
                    <IconHome size={20} />
                </ActionIcon>
            ) : (
                <Button
                    component={Link}
                    to="/"
                    variant={isActivePath('/') ? 'light' : 'subtle'}
                    color={isActivePath('/') ? 'blue' : 'gray'}
                    leftSection={<IconHome size={18} />}
                    onClick={closeMobile}
                >
                    Home
                </Button>
            )}

            {isBlogIcon ? (
                <ActionIcon
                    component={Link}
                    to="/blog"
                    variant={isActivePath('/blog') ? 'light' : 'subtle'}
                    color={isActivePath('/blog') ? 'blue' : 'gray'}
                    onClick={closeMobile}
                    size="lg"
                >
                    <IconArticle size={20} />
                </ActionIcon>
            ) : (
                <Button
                    component={Link}
                    to="/blog"
                    variant={isActivePath('/blog') ? 'light' : 'subtle'}
                    color={isActivePath('/blog') ? 'blue' : 'gray'}
                    leftSection={<IconArticle size={18} />}
                    onClick={closeMobile}
                >
                    Blog
                </Button>
            )}

            {isEventsIcon ? (
                <ActionIcon
                    component={Link}
                    to="/events"
                    variant={isActivePath('/events') ? 'light' : 'subtle'}
                    color={isActivePath('/events') ? 'blue' : 'gray'}
                    onClick={closeMobile}
                    size="lg"
                >
                    <IconCalendarEvent size={20} />
                </ActionIcon>
            ) : (
                <Button
                    component={Link}
                    to="/events"
                    variant={isActivePath('/events') ? 'light' : 'subtle'}
                    color={isActivePath('/events') ? 'blue' : 'gray'}
                    leftSection={<IconCalendarEvent size={18} />}
                    onClick={closeMobile}
                >
                    Events
                </Button>
            )}
        </>
    );

    const generateButton = (
        <>
            {isGenerateIcon ? (
                <ActionIcon
                    component={Link}
                    to="/generate"
                    variant="filled"
                    color="blue"
                    onClick={closeMobile}
                    size="lg"
                    styles={(theme) => ({
                        root: {
                            background: 'linear-gradient(45deg, #228be6, #4dabf7)',
                            boxShadow: '0 2px 8px rgba(34, 139, 230, 0.25)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #1c7ed6, #339af0)',
                                transform: 'translateY(-2px)',
                                boxShadow: '0 4px 12px rgba(34, 139, 230, 0.35)'
                            }
                        }
                    })}
                >
                    <IconWand size={20} />
                </ActionIcon>
            ) : (
                <Button
                    component={Link}
                    to="/generate"
                    variant="filled"
                    color="blue"
                    leftSection={<IconWand size={18} style={{ marginRight: -4 }} />}
                    onClick={closeMobile}
                    styles={(theme) => ({
                        root: {
                            background: 'linear-gradient(45deg, #228be6, #4dabf7)',
                            boxShadow: '0 2px 8px rgba(34, 139, 230, 0.25)',
                            fontWeight: 600,
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #1c7ed6, #339af0)',
                                transform: 'translateY(-2px)',
                                boxShadow: '0 4px 12px rgba(34, 139, 230, 0.35)'
                            }
                        }
                    })}
                >
                    Generate
                </Button>
            )}
        </>
    );

    const subscriptionSection = showSubscribeButton && (
        <Group gap="xs">
            <Stack gap={4} align="center" style={{ display: isViewsInDrawer ? 'none' : 'flex' }}>
                <Badge
                    color={(user.role === 'Admin' || user.role === 'Subscriber') ? "blue" : (user.remainingViews === 0 ? "red" : "teal")}
                    variant="light"
                    title="Pattern views remaining this month"
                >
                    {(user.role === 'Admin' || user.role === 'Subscriber') ? "Unlimited Views" : `${user.remainingViews}/${user.maxViews} Views`}
                </Badge>
                {user.remainingViews <= 2 && (
                    <Text size="xs" c="dimmed" style={{ display: isSubscribeTextShort ? 'none' : 'block' }}>
                        {isSubscribeButtonShort ? "Subscribe for more" : "Subscribe to get unlimited views"}
                    </Text>
                )}
            </Stack>
            <StripeCheckoutButton />
        </Group>
    );

    const userSection = user ? (
        <Group>
            <Group gap="xs" align="center">
                <Badge
                    color="purple"
                    variant="light"
                    title="Your current and lifetime karma points"
                    style={{ display: isKarmaInDrawer ? 'none' : 'flex', cursor: 'pointer' }}
                    onClick={openKarmaModal}
                >
                    {`${user.karma} / ${user.lifetimeKarma} Karma`}
                </Badge>
                {subscriptionSection}
            </Group>
            <Menu shadow="md" width={200} position="bottom-end">
                <Menu.Target>
                    <Group gap="xs">
                        {isAdmin && (
                            <Badge color="blue" variant="filled">
                                Admin
                            </Badge>
                        )}
                        <Avatar
                            src={user.profileImageUrl?.toString()}
                            alt={user.displayName}
                            radius="xl"
                            size="md"
                            style={{ cursor: 'pointer' }}
                        />
                    </Group>
                </Menu.Target>

                <Menu.Dropdown>
                    {isAdmin && (
                        <Menu.Item
                            component={Link}
                            to="/admin"
                            leftSection={<IconDashboard size={16} />}
                            onClick={closeMobile}
                        >
                            Admin Dashboard
                        </Menu.Item>
                    )}
                    <Menu.Item component={Link} to="/profile" onClick={closeMobile}>
                        View Profile
                    </Menu.Item>
                    <Menu.Item
                        component={Link}
                        to="/profile/edit"
                        leftSection={<IconSettings size={16} />}
                        onClick={closeMobile}
                    >
                        Edit Profile
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                        color="red"
                        leftSection={<IconLogout size={16} />}
                        onClick={handleLogout}
                    >
                        Logout
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Group>
    ) : (
        <Button component={Link} to="/signin" state={{ from: location.pathname }} variant="subtle" onClick={closeMobile}>
            Sign In
        </Button>
    );

    return (
        <>
            <Box
                component="header"
                style={{
                    height: '60px',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'white',
                    borderBottom: '1px solid #e9ecef',
                    zIndex: 100,
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Container fluid px="md" style={{ width: '100%', maxWidth: 'unset' }}>
                    <Group justify="space-between" style={{ width: '100%' }}>
                        <Group gap={isHomeIcon ? "xs" : "md"}>
                            {isHomeAndBlogInDrawer ? (
                                <>
                                    <Burger opened={mobileOpened} onClick={toggleMobile} size="sm" />
                                    {!isGenerateInDrawer && generateButton}
                                </>
                            ) : (
                                <>
                                    {homeAndBlogButtons}
                                    {!isGenerateInDrawer && generateButton}
                                </>
                            )}
                        </Group>

                        {userSection}
                    </Group>
                </Container>
            </Box>
            <Drawer
                opened={mobileOpened}
                onClose={closeMobile}
                size="100%"
                padding="md"
                title={
                    <Group justify="space-between" w="100%">
                        <Text fw={700} size="xl" style={gradientText}>The Fiber Collective</Text>
                        <Group gap="xs">
                            {isKarmaInDrawer && user && (
                                <Badge 
                                    color="purple"
                                    variant="light"
                                    title="Your current and lifetime karma points"
                                    style={{ cursor: 'pointer' }}
                                    onClick={openKarmaModal}
                                >
                                    {`${user.karma} / ${user.lifetimeKarma} Karma`}
                                </Badge>
                            )}
                            {isViewsInDrawer && showSubscribeButton && (
                                <Badge
                                    color={(user.role === 'Admin' || user.role === 'Subscriber') ? "blue" : (user.remainingViews === 0 ? "red" : "teal")}
                                    variant="light"
                                    title="Pattern views remaining this month"
                                >
                                    {(user.role === 'Admin' || user.role === 'Subscriber') ? "Unlimited Views" : `${user.remainingViews}/${user.maxViews} Views`}
                                </Badge>
                            )}
                        </Group>
                    </Group>
                }
                zIndex={1000}
            >
                <Stack justify="space-between" style={{ height: '100%' }}>
                    <Stack gap="lg">
                        {isHomeAndBlogInDrawer && (
                            <Button
                                component={Link}
                                to="/"
                                variant={isActivePath('/') ? 'light' : 'subtle'}
                                color={isActivePath('/') ? 'blue' : 'gray'}
                                leftSection={<IconHome size={20} />}
                                onClick={closeMobile}
                                fullWidth
                                size="lg"
                                styles={(theme) => ({
                                    root: {
                                        transition: 'all 0.2s ease',
                                        padding: '12px 16px',
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                                        }
                                    }
                                })}
                            >
                                Home
                            </Button>
                        )}
                        {isViewsInDrawer && showSubscribeButton && (
                            <StripeCheckoutButton fullWidth size="lg" inDrawer={true} />
                        )}
                        {isGenerateInDrawer && (
                            <Button
                                component={Link}
                                to="/generate"
                                variant="filled"
                                color="blue"
                                leftSection={<IconWand size={20} style={{ marginRight: -4 }} />}
                                onClick={closeMobile}
                                fullWidth
                                size="lg"
                                styles={(theme) => ({
                                    root: {
                                        background: 'linear-gradient(45deg, #228be6, #4dabf7)',
                                        boxShadow: '0 2px 8px rgba(34, 139, 230, 0.25)',
                                        fontWeight: 600,
                                        transition: 'all 0.2s ease',
                                        padding: '12px 16px',
                                        '&:hover': {
                                            background: 'linear-gradient(45deg, #1c7ed6, #339af0)',
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 4px 12px rgba(34, 139, 230, 0.35)'
                                        }
                                    }
                                })}
                            >
                                Generate
                            </Button>
                        )}
                        {isHomeAndBlogInDrawer && (
                            <Button
                                component={Link}
                                to="/blog"
                                variant={isActivePath('/blog') ? 'light' : 'subtle'}
                                color={isActivePath('/blog') ? 'blue' : 'gray'}
                                leftSection={<IconArticle size={20} />}
                                onClick={closeMobile}
                                fullWidth
                                size="lg"
                                styles={(theme) => ({
                                    root: {
                                        transition: 'all 0.2s ease',
                                        padding: '12px 16px',
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                                        }
                                    }
                                })}
                            >
                                Blog
                            </Button>
                        )}
                        {isHomeAndBlogInDrawer && (
                            <Button
                                component={Link}
                                to="/events"
                                variant={isActivePath('/events') ? 'light' : 'subtle'}
                                color={isActivePath('/events') ? 'blue' : 'gray'}
                                leftSection={<IconCalendarEvent size={20} />}
                                onClick={closeMobile}
                                fullWidth
                                size="lg"
                                styles={(theme) => ({
                                    root: {
                                        transition: 'all 0.2s ease',
                                        padding: '12px 16px',
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                                        }
                                    }
                                })}
                            >
                                Events
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </Drawer>
            <KarmaModal opened={karmaModalOpened} onClose={closeKarmaModal} />
        </>
    );
} 