import { Modal, Text, Button, Group, Stack } from '@mantine/core';
import { gradientText } from '../styles/common';

interface StopTestingModalProps {
  opened: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

/**
 * A modal for confirming stopping pattern testing
 */
export function StopTestingModal({ opened, onClose, onConfirm, isLoading }: StopTestingModalProps) {
  return (
    <Modal 
      opened={opened} 
      onClose={onClose}
      title={
        <Text 
          size="lg" 
          fw={600}
          style={gradientText}
        >
          Stop Testing This Pattern?
        </Text>
      }
    >
      <Stack>
        <Text>
          <strong>Warning:</strong> This will permanently delete your test journal for this pattern.
        </Text>
        
        <Text c="red">
          You will not receive any karma points for this pattern test.
        </Text>
        
        <Group justify="flex-end">
          <Button variant="light" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button color="red" onClick={onConfirm} loading={isLoading}>
            Stop Testing
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
} 