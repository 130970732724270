import { PatternCard } from './PatternCard';
import { Card, Text } from '@mantine/core';
import { getCategoryDisplayName } from '../utils/categoryUtils';
import { useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { cardBase, gradientText } from '../styles/common';
import { CrochetPatternSummary } from '../types/api';

interface AdCardProps {
  adSlot?: string;
  index?: number;
  adIndex?: number; // Specific index for ad sequencing
}

function AdCard({ adSlot, index, adIndex }: AdCardProps) {
  const adRef = useRef<HTMLDivElement>(null);
  
  // Define multiple ad slots to rotate through
  const adSlots = [
    "1601046221",
    "8322269186",
    "4367578273",
    "1063829919",
    "7397034475",
    "3828522227"
  ];
  
  // Use the specific adIndex for ad slots if provided, otherwise fall back to the regular index
  const indexToUse = typeof adIndex === 'number' ? adIndex : (typeof index === 'number' ? index : 0);
  const slotIndex = indexToUse % adSlots.length;
  const finalAdSlot = adSlot || adSlots[slotIndex];

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      try {
        // Initialize adsbygoogle if not already initialized
        if (!window.adsbygoogle) {
          window.adsbygoogle = [];
        }
        // Push the ad configuration
        window.adsbygoogle.push({});
      } catch (error) {
        console.error('Error initializing AdSense:', error);
      }
    }
  }, []);

  if (process.env.NODE_ENV === 'development') {
    return (
      <Card 
        shadow="sm" 
        padding="lg" 
        radius="md" 
        withBorder 
        style={{ ...cardBase, width: '300px' }}
      >
        <div
          style={{
            background: '#ff000033',
            position: 'relative',
            width: '100%',
            paddingTop: '100%', // 1:1 Aspect ratio
            border: '2px dashed #ff0000'
          }}
        >
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#ff0000',
            fontWeight: 'bold'
          }}>
            Ad Placeholder (Slot #{finalAdSlot})
          </div>
        </div>
        <div style={{
          position: 'absolute',
          bottom: '4px',
          right: '12px'
        }}>
          <Text 
            fw={600} 
            size="xs"
            style={gradientText}
          >
            Sponsored Content
          </Text>
        </div>
      </Card>
    );
  }

  return (
    <Card 
      shadow="sm" 
      p="lg" 
      radius="md" 
      withBorder 
      style={{ ...cardBase, width: '300px' }}
    >
      <div style={{ 
        position: 'relative',
        width: '100%',
        paddingTop: '100%' // 1:1 Aspect ratio
      }}>
        <div ref={adRef} style={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}>
          <ins
            className="adsbygoogle"
            style={{ 
              display: 'block',
              width: '100%',
              height: '100%'
            }}
            data-ad-client="ca-pub-1849973068698538"
            data-ad-slot={finalAdSlot}
            data-ad-format="rectangle"
            data-ad-layout="in-article"
            data-full-width-responsive="false"
          />
        </div>
      </div>
      <div style={{
        position: 'absolute',
        bottom: '4px',
        right: '12px'
      }}>
        <Text 
          fw={600} 
          size="xs"
          style={gradientText}
        >
          Sponsored Content
        </Text>
      </div>
    </Card>
  );
}

// Keep track of how many ads we've shown
let adCounter = 0;

interface PatternCardWithAdsProps {
  index: number;
  pattern: CrochetPatternSummary;
}

export function PatternCardWithAds({ index, pattern }: PatternCardWithAdsProps) {
  const { user } = useAuth();
  const patternWithCategory = {
    ...pattern,
    categoryDisplayName: getCategoryDisplayName(pattern.category)
  };

  // Show an ad after every 8 patterns, but only if it won't create an incomplete row
  // AND only if the user is not a subscriber
  const showAd = index > 0 && index % 8 === 0;
  const isLastInRow = (index + 1) % 3 === 0;
  const shouldShowAd = showAd && isLastInRow && (!user || user.role !== 'Subscriber');

  // Create a reference to the current ad number
  const currentAdIndex = useRef<number>(-1);
  
  // Assign a sequential ad index when this component decides to show an ad
  if (shouldShowAd && currentAdIndex.current === -1) {
    currentAdIndex.current = adCounter++;
  }

  return (
    <>
      <PatternCard {...patternWithCategory} />
      {shouldShowAd && (
        <AdCard 
          key={`ad-${currentAdIndex.current}`} 
          index={index} 
          adIndex={currentAdIndex.current}
        />
      )}
    </>
  );
} 