import { useEffect } from 'react';
import { Paper, Text } from '@mantine/core';
import { useAuth } from '../../contexts/AuthContext';

interface InArticleAdProps {
  index?: number;
}

const InArticleAd = ({ index }: InArticleAdProps) => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const { user } = useAuth();
  const isSubscriber = user?.role === 'Subscriber';
  
  // Define multiple ad slots to rotate through
  const adSlots = [
    "7812560341",
    "7089078567",
    "3602125665",
    "4424252741",
    "2144707795",
    "9831626121"
  ];
  
  // Select ad slot based on index, or use first slot if no index provided
  const slotIndex = typeof index === 'number' ? (index % adSlots.length) : 0;
  const adSlot = adSlots[slotIndex];

  useEffect(() => {
    if (!isDevelopment && !isSubscriber) {
      try {
        // @ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (error) {
        console.error('Error initializing AdSense:', error);
      }
    }
  }, [isDevelopment, isSubscriber]);

  // Don't render anything for subscribers
  if (isSubscriber) {
    return null;
  }

  if (isDevelopment) {
    return (
      <Paper 
        withBorder 
        p="md" 
        style={{ 
          backgroundColor: '#f0f0f0',
          textAlign: 'center',
          margin: '2rem 0'
        }}
      >
        <Text c="dimmed" size="sm">
          [AdSense In-Article Ad Placeholder - Slot #{adSlot}]
          <br />
          This placeholder will be replaced with a real ad in production
        </Text>
      </Paper>
    );
  }

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          textAlign: 'center',
          margin: '2rem 0'
        }}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-1849973068698538"
        data-ad-slot={adSlot}
      />
    </>
  );
};

export default InArticleAd; 