import { Button, TextInput, PasswordInput, Stack, Group, Text, Anchor, Alert } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { IconAt, IconLock, IconAlertCircle } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useReCaptcha } from '../../hooks/useReCaptcha';

interface LoginFormValues {
  email: string;
  password: string;
}

interface LoginFormProps {
  onSuccess?: () => void;
  onSwitchToRegister: () => void;
  onSwitchToForgotPassword: () => void;
}

export function LoginForm({ onSuccess, onSwitchToRegister, onSwitchToForgotPassword }: LoginFormProps) {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<string | null>(null);
  const [isVerificationNeeded, setIsVerificationNeeded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { verify, isVerifying } = useReCaptcha();
  
  const form = useForm<LoginFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (value.length < 6 ? 'Password must be at least 6 characters' : null),
    },
  });

  const handleSubmit = async (values: LoginFormValues) => {
    setLoginError(null);
    setIsVerificationNeeded(false);
    setIsSubmitting(true);
    
    try {
      // Get reCAPTCHA token
      const recaptchaToken = await verify('login');
      
      // Pass the token to the login function (we'll need to update the AuthContext and API)
      await login({ ...values, recaptchaToken });
      
      // Only call onSuccess (which navigates away) if login was successful
      onSuccess?.();
    } catch (error: any) {
      setLoginError(error.message || 'An error occurred during login');
      
      // Check if the error message indicates unverified email
      if (error.message && error.message.toLowerCase().includes('email not verified')) {
        setIsVerificationNeeded(true);
      }
      // Do not call onSuccess on error
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResendVerification = () => {
    navigate('/resend-verification');
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap="md">
        {loginError && (
          <Alert color="red" title="Login Failed" icon={<IconAlertCircle size={16} />}>
            {loginError}
            {isVerificationNeeded && (
              <Button 
                variant="outline" 
                color="red" 
                fullWidth 
                mt="md"
                onClick={handleResendVerification}
              >
                Resend Verification Email
              </Button>
            )}
          </Alert>
        )}

        <TextInput
          withAsterisk
          label="Email"
          placeholder="your@email.com"
          leftSection={<IconAt size={16} />}
          {...form.getInputProps('email')}
          disabled={isSubmitting || isVerifying}
        />

        <PasswordInput
          withAsterisk
          label="Password"
          placeholder="Your password"
          leftSection={<IconLock size={16} />}
          {...form.getInputProps('password')}
          disabled={isSubmitting || isVerifying}
        />

        <Group justify="space-between" mt="xs">
          <Anchor component="button" type="button" size="sm" onClick={onSwitchToForgotPassword}>
            Forgot password?
          </Anchor>
        </Group>

        <Button 
          type="submit" 
          fullWidth 
          mt="md" 
          loading={isSubmitting || isVerifying}
          variant="gradient"
          gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
        >
          Sign in
        </Button>

        <Text ta="center" size="sm">
          Don't have an account?{' '}
          <Anchor component="button" type="button" onClick={onSwitchToRegister}>
            Register
          </Anchor>
        </Text>
      </Stack>
    </form>
  );
} 