import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Title, 
  TextInput, 
  Button, 
  Group, 
  Stack, 
  Paper,
  LoadingOverlay,
  Image,
  Box,
  FileButton,
  Select,
  Combobox,
  PillsInput,
  Pill,
  useCombobox,
  Switch,
  Textarea,
  Grid,
  Text
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { eventsApi } from '../api/events';
import { Event } from '../types/Events';
import { IconUpload, IconCalendar } from '@tabler/icons-react';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from '../utils/apiUtils';
import { useAuth } from '../contexts/AuthContext';
import { useReCaptcha } from '../hooks/useReCaptcha';
import { gradientText, paperGradient, elevatedShadow } from '../styles/common';
import { Helmet } from 'react-helmet-async';
import { invalidateEventQueries } from '../utils/eventCache';
import { extractDateOnly, createDateOnly } from '../utils/dateUtils';

export function EventEditPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, isEmailVerified } = useAuth();
  const queryClient = useQueryClient();
  const isAdmin = user?.role === 'Admin';
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      setSearchValue('');
    },
  });
  const countryCombobox = useCombobox();
  const { verify } = useReCaptcha();
  
  const [event, setEvent] = useState<Partial<Event>>({
    title: '',
    description: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    startDate: new Date().toISOString(),
    endDate: '',
    organizerName: user?.displayName || '',
    eventUrl: '',
    tags: [],
    isVirtual: false,
    isRecurring: false,
    recurrenceFrequency: ''
  });
  
  const [searchValue, setSearchValue] = useState('');
  const [tagOptions, setTagOptions] = useState<string[]>([]);

  // Fetch available tags
  const { data: availableTags = [] } = useQuery({
    queryKey: ['event-tags'],
    queryFn: () => eventsApi.getTags(),
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  // Fetch available countries
  const { data: availableCountries = [] } = useQuery({
    queryKey: ['event-countries'],
    queryFn: () => eventsApi.getCountries(),
    staleTime: 1000 * 60 * 60, // Cache for 1 hour
  });

  // Create country options from available countries
  const countryOptions = availableCountries;

  useEffect(() => {
    // Combine predefined tags with available tags from the API
    const predefinedTags = [
      'workshop', 'meetup', 'class', 'sale',
      'virtual', 'in-person', 'free', 'paid',
      'beginner', 'advanced', 'all-levels'
    ];
    
    // Convert Set to array to avoid iteration issues
    const combinedTags = Array.from(new Set([...predefinedTags, ...availableTags]));
    setTagOptions(combinedTags);
  }, [availableTags]);

  // Fetch event details if editing
  const { data: fetchedEvent, isLoading: isLoadingEvent } = useQuery({
    queryKey: ['event', id],
    queryFn: () => id ? eventsApi.getById(id) : null,
    enabled: !!id,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  useEffect(() => {
    if (fetchedEvent) {
      setEvent(fetchedEvent);
      if (fetchedEvent.imageUrl) setPreviewUrl(fetchedEvent.imageUrl);
    }
  }, [fetchedEvent]);

  const { mutate: saveEvent, isPending: isSaving } = useMutation({
    mutationFn: async () => {
      try {
        // Use different reCAPTCHA action names for create vs update
        const recaptchaAction = id ? 'update_event' : 'create_event';
        const recaptchaToken = await verify(recaptchaAction);
        
        const formData = new FormData();
        
        // For updates, include the Id field
        if (id) {
          formData.append('Id', id);
        }
        
        formData.append('Title', event.title || '');
        formData.append('Description', event.description || '');
        
        // Only include location information for non-virtual events
        if (!event.isVirtual) {
          if (event.addressLine1) formData.append('AddressLine1', event.addressLine1);
          if (event.addressLine2) formData.append('AddressLine2', event.addressLine2);
          formData.append('City', event.city || '');
          formData.append('State', event.state || '');
          formData.append('Country', event.country || '');
          if (event.zipcode) formData.append('Zipcode', event.zipcode);
        } else {
          // For virtual events, ensure we send empty values for location fields
          formData.append('City', '');
          formData.append('State', '');
          formData.append('Country', '');
        }
        
        // Set fixed noon time for dates
        formData.append('StartDate', createDateOnly(event.startDate));
        if (event.endDate) formData.append('EndDate', createDateOnly(event.endDate));
        
        formData.append('OrganizerName', event.organizerName || user?.displayName || '');
        if (event.eventUrl) formData.append('EventUrl', event.eventUrl);
        event.tags?.forEach((tag, index) => {
          formData.append(`Tags[${index}]`, tag);
        });
        formData.append('IsVirtual', String(event.isVirtual));
        formData.append('IsRecurring', String(event.isRecurring));
        if (event.recurrenceFrequency) formData.append('RecurrenceFrequency', event.recurrenceFrequency);
        
        if (imageFile) formData.append('image', imageFile);
        
        return id ? eventsApi.update(id, formData, recaptchaToken) : eventsApi.create(formData, recaptchaToken);
      } catch (error) {
        console.error('Error in saveEvent:', error);
        throw error;
      }
    },
    onSuccess: (data) => {
      invalidateEventQueries(queryClient, data.id);
      notifications.show({
        title: 'Success',
        message: `Event ${id ? 'updated' : 'created'} successfully`,
        color: 'green'
      });
      navigate('/events');
    },
    onError: (error: ApiError) => {
      notifications.show({
        title: 'Error',
        message: error.message || `Failed to ${id ? 'update' : 'create'} event`,
        color: 'red'
      });
    }
  });

  const handleFileSelect = (file: File | null) => {
    if (file) {
      setImageFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate required fields
    if (!event.title || !event.description || !event.startDate) {
      notifications.show({
        title: 'Error',
        message: 'Please fill in all required fields',
        color: 'red'
      });
      return;
    }
    
    // Validate location fields only for non-virtual events
    if (!event.isVirtual && (!event.city || !event.country)) {
      notifications.show({
        title: 'Error',
        message: 'For in-person events, please fill in the city and country fields',
        color: 'red'
      });
      return;
    }
    
    // Validate US state requirement
    if (!event.isVirtual && event.country === "United States of America" && !event.state) {
      notifications.show({
        title: 'Error',
        message: 'For events in the United States, please provide a state',
        color: 'red'
      });
      return;
    }
    
    // Call the saveEvent mutation
    saveEvent();
  };

  const handleValueSelect = (val: string) => {
    if (!event.tags) return;
    const value = val.trim();
    if (value !== '' && !event.tags.includes(value)) {
      setEvent(prev => ({
        ...prev,
        tags: [...(prev.tags || []), value]
      }));
    }
    setSearchValue('');
  };

  const handleValueRemove = (val: string) => {
    setEvent(prev => ({
      ...prev,
      tags: prev.tags?.filter(v => v !== val)
    }));
  };

  // Redirect if not authorized
  useEffect(() => {
    if (!user) {
      navigate('/signin');
      return;
    }
    
    // Only admin or user with verified email can create/edit events
    if (!isAdmin && !isEmailVerified) {
      notifications.show({
        title: 'Email verification required',
        message: 'You need to verify your email before creating events',
        color: 'yellow'
      });
      navigate('/profile');
    }
  }, [user, isAdmin, isEmailVerified, navigate]);

  const recurrenceOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'biweekly', label: 'Bi-Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'quarterly', label: 'Quarterly' },
    { value: 'yearly', label: 'Yearly' }
  ];

  return (
    <>
      <Helmet>
        <title>{id ? 'Edit' : 'Create'} Event | Fiber Collective</title>
        <meta name="description" content={`${id ? 'Edit' : 'Create'} a crochet event, meetup, or workshop`} />
      </Helmet>
      
      <Container size="lg" py="xl">
        <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
          <LoadingOverlay visible={isLoadingEvent || isSaving} />
          <form onSubmit={handleSubmit}>
            <Stack gap="md">
              <Title 
                order={2}
                style={gradientText}
              >
                {id ? 'Edit' : 'Create'} Event
              </Title>

              <TextInput
                label="Event Title"
                placeholder="Enter event title"
                value={event.title || ''}
                onChange={(e) => setEvent(prev => ({ ...prev, title: e.target.value }))}
                required
              />

              <Textarea
                label="Description"
                placeholder="Enter event description"
                value={event.description || ''}
                onChange={(e) => setEvent(prev => ({ ...prev, description: e.target.value }))}
                minRows={4}
                required
              />

              <Grid>
                <Grid.Col span={6}>
                  <Stack gap="xs">
                    <DatePickerInput
                      label="Start Date"
                      placeholder="Select date"
                      value={event.startDate ? extractDateOnly(event.startDate) : null}
                      onChange={(date: Date | null) => {
                        setEvent(prev => ({
                          ...prev,
                          startDate: date ? createDateOnly(date) : undefined
                        }));
                      }}
                      required
                      leftSection={<IconCalendar size={16} />}
                    />
                  </Stack>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Stack gap="xs">
                    <DatePickerInput
                      label="End Date (Optional)"
                      placeholder="Select date"
                      value={event.endDate ? extractDateOnly(event.endDate) : null}
                      onChange={(date: Date | null) => {
                        setEvent(prev => ({
                          ...prev,
                          endDate: date ? createDateOnly(date) : undefined
                        }));
                      }}
                      clearable
                      leftSection={<IconCalendar size={16} />}
                    />
                  </Stack>
                </Grid.Col>
              </Grid>

              <Paper p="md" radius="md" withBorder style={{ background: 'linear-gradient(135deg, #f8f9fa, #e9ecef)' }}>
                <Text fw={500} mb="xs">Event Options</Text>
                <Group grow>
                  <Switch
                    label="Is this a virtual event?"
                    checked={event.isVirtual}
                    onChange={(e) => {
                      // Safely handle the event by using the checked value directly
                      const isChecked = e.currentTarget?.checked ?? event.isVirtual;
                      setEvent(prev => ({ ...prev, isVirtual: isChecked }));
                    }}
                  />
                  <Switch
                    label="Is this a recurring event?"
                    checked={event.isRecurring}
                    onChange={(e) => {
                      // Safely handle the event by using the checked value directly
                      const isChecked = e.currentTarget?.checked ?? event.isRecurring;
                      setEvent(prev => ({ ...prev, isRecurring: isChecked }));
                    }}
                  />
                </Group>

                {event.isRecurring && (
                  <Select
                    label="Recurrence Frequency"
                    placeholder="How often does this event occur?"
                    data={recurrenceOptions}
                    value={event.recurrenceFrequency}
                    onChange={(value) => setEvent(prev => ({ ...prev, recurrenceFrequency: value || undefined }))}
                    mt="md"
                  />
                )}
              </Paper>

              <TextInput
                label="Organizer Name"
                placeholder="Who is organizing this event?"
                value={event.organizerName || ''}
                onChange={(e) => setEvent(prev => ({ ...prev, organizerName: e.target.value }))}
                required
              />

              <TextInput
                label="Event URL (Optional)"
                placeholder="Link to event website"
                value={event.eventUrl || ''}
                onChange={(e) => setEvent(prev => ({ ...prev, eventUrl: e.target.value }))}
              />

              <Paper p="md" radius="md" withBorder style={{ background: 'linear-gradient(135deg, #f8f9fa, #e9ecef)' }}>
                <Text fw={500} mb="xs">Location Information</Text>
                
                <TextInput
                  label="Address Line 1 (Optional)"
                  placeholder="Street address, P.O. box, etc."
                  value={event.addressLine1 || ''}
                  onChange={(e) => setEvent(prev => ({ ...prev, addressLine1: e.target.value }))}
                  mb="sm"
                  disabled={event.isVirtual}
                />
                
                <TextInput
                  label="Address Line 2 (Optional)"
                  placeholder="Apartment, suite, unit, building, floor, etc."
                  value={event.addressLine2 || ''}
                  onChange={(e) => setEvent(prev => ({ ...prev, addressLine2: e.target.value }))}
                  mb="sm"
                  disabled={event.isVirtual}
                />
                
                <Grid>
                  <Grid.Col span={6}>
                    <TextInput
                      label={`City${event.isVirtual ? ' (Optional)' : ' (Required for in-person events)'}`}
                      placeholder="Enter city"
                      value={event.city || ''}
                      onChange={(e) => setEvent(prev => ({ ...prev, city: e.target.value }))}
                      required={!event.isVirtual}
                      disabled={event.isVirtual}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label={`State/Province${event.isVirtual ? ' (Optional)' : event.country === "United States of America" ? ' (Required for US events)' : ' (Optional)'}`}
                      placeholder="Enter state or province"
                      value={event.state || ''}
                      onChange={(e) => setEvent(prev => ({ ...prev, state: e.target.value }))}
                      required={!event.isVirtual && event.country === "United States of America"}
                      disabled={event.isVirtual}
                    />
                  </Grid.Col>
                </Grid>

                <Grid>
                  <Grid.Col span={6}>
                    <Combobox
                      store={countryCombobox}
                      onOptionSubmit={(val) => {
                        setEvent(prev => ({ ...prev, country: val }));
                        countryCombobox.closeDropdown();
                      }}
                    >
                      <Combobox.Target>
                        <TextInput
                          label={`Country${event.isVirtual ? ' (Optional)' : ' (Required for in-person events)'}`}
                          placeholder="Select or search for a country"
                          value={event.country || ''}
                          onChange={(e) => {
                            // Safely handle the event by checking if currentTarget exists
                            const value = e.currentTarget?.value ?? '';
                            setEvent(prev => ({ ...prev, country: value }));
                            countryCombobox.openDropdown();
                            countryCombobox.updateSelectedOptionIndex();
                          }}
                          onClick={() => countryCombobox.openDropdown()}
                          onFocus={() => countryCombobox.openDropdown()}
                          onBlur={() => countryCombobox.closeDropdown()}
                          required={!event.isVirtual}
                          disabled={event.isVirtual}
                        />
                      </Combobox.Target>

                      <Combobox.Dropdown>
                        <Combobox.Options>
                          {countryOptions.map((option) => (
                            <Combobox.Option value={option} key={option}>
                              {option}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      </Combobox.Dropdown>
                    </Combobox>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Zipcode/Postal Code (Optional)"
                      placeholder="Enter postal code"
                      value={event.zipcode || ''}
                      onChange={(e) => setEvent(prev => ({ ...prev, zipcode: e.target.value }))}
                      disabled={event.isVirtual}
                    />
                  </Grid.Col>
                </Grid>
              </Paper>

              <Combobox
                onOptionSubmit={handleValueSelect}
                store={combobox}
              >
                <Combobox.DropdownTarget>
                  <PillsInput
                    label="Tags"
                  >
                    <Pill.Group>
                      {event.tags?.map((tag) => (
                        <Pill
                          key={tag}
                          withRemoveButton
                          onRemove={() => handleValueRemove(tag)}
                        >
                          {tag}
                        </Pill>
                      ))}
                      
                      <Combobox.EventsTarget>
                        <PillsInput.Field
                          placeholder="Select or create tags"
                          value={searchValue}
                          onChange={(e) => {
                            // Safely handle the event
                            const value = e.currentTarget?.value ?? '';
                            setSearchValue(value);
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter' && searchValue.trim()) {
                              event.preventDefault();
                              handleValueSelect(searchValue);
                            }
                          }}
                        />
                      </Combobox.EventsTarget>
                    </Pill.Group>
                  </PillsInput>
                </Combobox.DropdownTarget>

                <Combobox.Dropdown>
                  <Combobox.Options>
                    {tagOptions
                      .filter(tag => 
                        tag.toLowerCase().includes(searchValue.toLowerCase().trim()) &&
                        !event.tags?.includes(tag)
                      )
                      .map((tag) => (
                        <Combobox.Option value={tag} key={tag}>
                          {tag}
                        </Combobox.Option>
                      ))}
                    {searchValue.trim() && !tagOptions.includes(searchValue.trim().toLowerCase()) && (
                      <Combobox.Option value={searchValue}>
                        + Create "{searchValue}"
                      </Combobox.Option>
                    )}
                  </Combobox.Options>
                </Combobox.Dropdown>
              </Combobox>

              <Paper p="md" radius="md" withBorder style={{ background: 'linear-gradient(135deg, #f8f9fa, #e9ecef)' }}>
                <Text fw={500} mb="xs">Event Image</Text>
                <Box>
                  {previewUrl && (
                    <Box mb="md" style={{
                      maxWidth: '300px',
                      margin: '0 auto',
                      ...elevatedShadow
                    }}>
                      <Image
                        src={previewUrl}
                        alt="Event preview"
                        fit="contain"
                        height={200}
                        radius="md"
                      />
                    </Box>
                  )}
                  <Group justify="center">
                    <FileButton onChange={handleFileSelect} accept="image/*">
                      {(props) => (
                        <Button {...props} leftSection={<IconUpload size={14} />}>
                          {previewUrl ? 'Change Image' : 'Upload Image'}
                        </Button>
                      )}
                    </FileButton>
                  </Group>
                </Box>
              </Paper>

              <Group justify="flex-end" mt="xl">
                <Button variant="light" onClick={() => navigate('/events')}>
                  Cancel
                </Button>
                <Button type="submit" loading={isSaving}>
                  {id ? 'Update' : 'Create'} Event
                </Button>
              </Group>
            </Stack>
          </form>
        </Paper>
      </Container>
    </>
  );
} 