import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const GoogleAnalytics: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views when the location changes
    if (window.gtag) {
      window.gtag('config', 'G-19VN8H14V8', {
        page_path: location.pathname + location.search
      });
    }
  }, [location]);

  return null;
};

// Add gtag to the Window interface
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
} 