import { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Container, Title, Text, Image, Group, Paper, List, Box, Space, Stack, Button, Modal, LoadingOverlay, Tabs, Badge } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconEdit, IconTrash, IconCopy } from '@tabler/icons-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { patternsApi } from '../api/patterns';
import { commentsApi } from '../api/comments';
import { HeartButton } from '../components/HeartButton';
import { CommentList } from '../components/CommentList';
import { CommentForm } from '../components/CommentForm';
import { CommentCount } from '../components/CommentCount';
import { useAuth } from '../contexts/AuthContext';
import { ApiError } from '../utils/apiUtils';
import { getFullImageUrl } from '../utils/imageUtils';
import { getCategoryDisplayName, adaptForLeftHanded, getProjectTypeDisplayName } from '../utils/patternUtils';
import { PatternBadges } from '../components/PatternBadges';
import { Helmet } from 'react-helmet-async';
import { StripeCheckoutButton } from '../components/StripeCheckoutButton';
import { metallicGoldStyle } from '../styles/common';
import { useEmailVerification } from '../hooks/useEmailVerification';
import { AiGeneratedPatternNotice } from '../components/AiGeneratedPatternNotice';
import StructuredData from '../components/SEO/StructuredData';

export function PatternDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const commentsRef = useRef<HTMLDivElement>(null);
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);
  const [testerModalOpened, { open: openTesterModal, close: closeTesterModal }] = useDisclosure(false);
  const [patternLimitModalOpened, { open: openPatternLimitModal, close: closePatternLimitModal }] = useDisclosure(false);
  const { user: authUser } = useAuth();
  const queryClient = useQueryClient();
  const [showAiNotice, setShowAiNotice] = useState(true);
  
  // Add our email verification hook
  const { checkVerification, VerificationAlert } = useEmailVerification({
    actionName: 'signing up as a pattern tester'
  });
  
  useEffect(() => {
    // Add metallic gold style for verified badge
    if (!document.getElementById('metallic-gold-style-detail')) {
      const styleElement = document.createElement('style');
      styleElement.id = 'metallic-gold-style-detail';
      styleElement.innerHTML = metallicGoldStyle;
      document.head.appendChild(styleElement);
      
      return () => {
        const styleEl = document.getElementById('metallic-gold-style-detail');
        if (styleEl && document.head.contains(styleEl)) {
          document.head.removeChild(styleEl);
        }
      };
    }
  }, []);
  
  // Fetch pattern details
  const { data: pattern, isLoading: isLoadingPattern, error } = useQuery({
    queryKey: ['pattern', id],
    queryFn: () => id ? patternsApi.getById(id) : null,
    enabled: !!id,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });
  
  // Handle pattern deletion
  const { mutate: deletePattern, isPending: isDeleting } = useMutation({
    mutationFn: patternsApi.deletePattern,
    onSuccess: () => {
      notifications.show({
        title: 'Success',
        message: 'Pattern deleted successfully',
        color: 'green'
      });
      queryClient.invalidateQueries({ queryKey: ['patterns'] });
      navigate('/');
    },
    onError: (error: ApiError) => {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to delete pattern',
        color: 'red'
      });
    }
  });

  const handleDelete = async () => {
    if (!id) return;
    deletePattern(id);
    closeDeleteModal();
  };

  useEffect(() => {
    if (location.state?.scrollToComments) {
      commentsRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [location.state, pattern]);

  const scrollToComments = () => {
    commentsRef.current?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  const handleEdit = () => {
    navigate(`/patterns/${id}/edit`);
  };

  const refreshPattern = () => {
    if (id) {
      queryClient.invalidateQueries({ queryKey: ['pattern', id] });
      queryClient.invalidateQueries({ queryKey: ['patterns'] });
    }
  };

  const { data: pendingComments = [], isLoading: isLoadingPendingComments } = useQuery({
    queryKey: ['comments', id, 'pending'],
    queryFn: () => id ? commentsApi.getPendingComments(id) : [],
    enabled: !!id && !!authUser?.id,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  const { data: comments = [], isLoading: isLoadingComments } = useQuery({
    queryKey: ['comments', id, 'approved'],
    queryFn: () => id ? commentsApi.getApprovedComments(id) : [],
    enabled: !!id,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  const handleCommentApproved = () => {
    // No need to manually invalidate queries - we'll use optimistic updates
  };

  const handleCommentAdded = () => {
    // No need to manually invalidate queries - we'll use optimistic updates
  };

  // Sign up as tester mutation
  const { mutate: signupAsTester, isPending: isSigningUp } = useMutation({
    mutationFn: () => id ? patternsApi.signupAsTester(id) : Promise.reject("No pattern ID"),
    onSuccess: () => {
      notifications.show({
        title: 'Success',
        message: 'You are now registered as a pattern tester!',
        color: 'green'
      });
      
      // Invalidate queries to ensure fresh data
      queryClient.invalidateQueries({ queryKey: ['pattern', id] });
      queryClient.invalidateQueries({ queryKey: ['pattern-test-journals', 'my'] });
      
      closeTesterModal();
      
      // Redirect to the test journal page
      if (id) {
        navigate(`/pattern-test-journal/${id}`);
      }
    },
    onError: (error: ApiError) => {
      // Extract the message from the error
      const errorMessage = typeof error.message === 'string' 
        ? error.message 
        : JSON.stringify(error.message);
        
      if (error.statusCode === 400 && 
          errorMessage && 
          (errorMessage.includes('You can test a maximum of 2 patterns at once') || 
           errorMessage.includes('maximum of 2 patterns'))) {
        // Close the tester modal
        closeTesterModal();
        
        // Wait a small amount of time before opening the new modal for a smoother transition
        setTimeout(() => {
          openPatternLimitModal();
        }, 300);
      } else {
        // Show the regular error notification for other errors
        notifications.show({
          title: 'Error',
          message: error.message || 'Failed to register as a pattern tester',
          color: 'red'
        });
      }
    }
  });

  const duplicatePatternMutation = useMutation({
    mutationFn: (patternId: string) => patternsApi.duplicateAndRefine(patternId),
    onSuccess: (newPattern) => {
      notifications.show({
        title: 'Success',
        message: 'Pattern has been duplicated and is being refined',
        color: 'green',
      });
      
      // Invalidate patterns queries to refresh the lists
      queryClient.invalidateQueries({ queryKey: ['patterns'] });
      
      // Navigate to the new pattern
      navigate(`/patterns/${newPattern.id}`);
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to duplicate pattern',
        color: 'red',
      });
    }
  });
  
  const handleDuplicate = async () => {
    if (!id) return;
    duplicatePatternMutation.mutate(id);
  };

  // Handle tester signup with verification
  const handleTesterSignup = () => {
    // Check email verification first
    if (checkVerification()) {
      signupAsTester();
    }
  };

  // Early return if no id
  if (!id) {
    return (
      <>
        <Helmet>
          <title>Pattern Not Found - The Fiber Collective</title>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <Container><Text>Pattern not found</Text></Container>
      </>
    );
  }

  if (isLoadingPattern || isLoadingComments || isLoadingPendingComments) {
    return (
      <>
        <Helmet>
          <title>Loading Pattern - The Fiber Collective</title>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <Container>
          <LoadingOverlay visible={true} />
        </Container>
      </>
    );
  }

  if (error || !pattern) {
    return (
      <Container>
        <Text c="red">Error loading pattern</Text>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        {/* Basic meta tags */}
        <title>{`${pattern.name} - Free Crochet Pattern - The Fiber Collective`}</title>
        <meta name="description" content={`Learn how to crochet a ${pattern.name.toLowerCase()} with this free step-by-step pattern. ${pattern.description.substring(0, 120)}`} />
        <meta name="keywords" content={`crochet pattern, ${pattern.name}, ${getCategoryDisplayName(pattern.category)}, ${pattern.difficultyLevel} crochet, free pattern, fiber arts, crafting, DIY, crochet instructions`} />
        
        {/* Open Graph tags */}
        <meta property="og:title" content={`${pattern.name} - Free Crochet Pattern`} />
        <meta property="og:description" content={`Learn how to crochet a ${pattern.name.toLowerCase()}. ${pattern.description.substring(0, 120)}`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="The Fiber Collective" />
        
        {/* Ensure pattern image is used with absolute URL */}
        {pattern.imageUrl ? (
          <>
            <meta 
              property="og:image" 
              content={getFullImageUrl(pattern.imageUrl)} 
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="1200" />
            <meta property="og:image:alt" content={pattern.name} />
          </>
        ) : (
          // Fallback to site logo if no pattern image
          <meta property="og:image" content="https://thefibercollective.com/logo192.png" />
        )}
        <meta property="article:published_time" content={pattern.createdAt} />
        
        {/* Twitter Card tags - only include what's different from OG tags */}
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {/* Add structured data for the pattern */}
      <StructuredData 
        data={{
          "@context": "https://schema.org",
          "@type": "HowTo",
          "name": pattern.name,
          "description": pattern.description,
          "image": pattern.imageUrl ? getFullImageUrl(pattern.imageUrl) : undefined,
          "datePublished": pattern.createdAt,
          "supply": pattern.materialsNeeded?.map(material => ({
            "@type": "HowToSupply",
            "name": material.name,
            "requiredQuantity": material.quantity
          })),
          "step": pattern.sections?.flatMap((section, sectionIndex) => 
            section.instructions.map((instruction, index) => ({
              "@type": "HowToStep",
              "position": sectionIndex * 100 + index + 1,
              "name": section.name,
              "text": instruction
            }))
          ),
          "educationalLevel": pattern.difficultyLevel,
          "skill": ["Crochet", getCategoryDisplayName(pattern.category)]
        }}
      />

      {/* Add Product schema for better e-commerce rich snippets */}
      <StructuredData
        data={{
          "@context": "https://schema.org",
          "@type": "Product",
          "name": pattern.name,
          "description": pattern.description,
          "image": pattern.imageUrl ? getFullImageUrl(pattern.imageUrl) : undefined,
          "sku": pattern.id,
          "brand": {
            "@type": "Organization",
            "name": "The Fiber Collective"
          },
          "category": getCategoryDisplayName(pattern.category),
          "audience": {
            "@type": "Audience",
            "audienceType": pattern.difficultyLevel + " Crocheters"
          },
          "offers": {
            "@type": "Offer",
            "price": "0.00",
            "priceCurrency": "USD",
            "availability": "https://schema.org/InStock",
            "url": window.location.href
          },
          "aggregateRating": pattern.heartCount > 0 ? {
            "@type": "AggregateRating",
            "ratingValue": "5",
            "reviewCount": pattern.heartCount,
            "bestRating": "5",
            "worstRating": "1"
          } : undefined,
          "isAccessibleForFree": true,
          "interactionStatistic": pattern.heartCount > 0 ? {
            "@type": "InteractionCounter",
            "interactionType": "https://schema.org/LikeAction",
            "userInteractionCount": pattern.heartCount
          } : undefined
        }}
      />

      <Container size="lg" py="xl">
        <Paper shadow="md" p="xl" radius="lg" withBorder style={{ background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)' }}>
          <LoadingOverlay visible={isDeleting} />
          <Stack gap="xl">
            <Box>
              <Group justify="space-between" mb="lg">
                <Group align="center">
                  <Title 
                    order={1}
                    style={{
                      background: 'linear-gradient(45deg, #228BE6, #40C057)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      letterSpacing: '0.3px'
                    }}
                  >
                    {pattern.name}
                  </Title>
                  {pattern.isVerified && (
                    <Badge className="verified-badge" size="lg">Verified</Badge>
                  )}
                </Group>
                <Group gap="lg">
                  <CommentCount 
                    count={pattern.commentCount} 
                    onClick={scrollToComments}
                  />
                  <HeartButton
                    patternId={pattern.id}
                    heartCount={pattern.heartCount}
                    isHearted={pattern.isHearted}
                    onHeartToggle={refreshPattern}
                  />
                  {(authUser?.id === pattern.ownerId || authUser?.role === 'Admin') && (
                    <Group>
                      <Button
                        variant="light"
                        leftSection={<IconEdit size={16} />}
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                      <Button
                        color="red"
                        variant="light"
                        leftSection={<IconTrash size={16} />}
                        onClick={openDeleteModal}
                      >
                        Delete
                      </Button>
                      {/* Admin-only Duplicate Pattern button */}
                      {authUser?.role === 'Admin' && (
                        <Button
                          color="blue"
                          variant="light"
                          leftSection={<IconCopy size={16} />}
                          onClick={handleDuplicate}
                          loading={duplicatePatternMutation.isPending}
                        >
                          Duplicate Pattern
                        </Button>
                      )}
                    </Group>
                  )}
                </Group>
              </Group>

              <Group justify="space-between" align="center" w="100%">
                <PatternBadges
                  difficultyLevel={pattern.difficultyLevel}
                  category={pattern.category}
                  projectType={pattern.projectType}
                  isCreator={authUser?.id === pattern.ownerId}
                  isVerified={false}
                  size="lg"
                />
                <Box>
                  {authUser && !pattern.isTester && !pattern.isVerified && (
                    <Button 
                      variant="gradient" 
                      gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }} 
                      onClick={openTesterModal}
                    >
                      Signup to Test
                    </Button>
                  )}
                  {authUser && pattern.isTester && (
                    <Button 
                      variant="gradient" 
                      gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }} 
                      onClick={() => navigate(`/pattern-test-journal/${pattern.id}`)}
                    >
                      Testing Journal
                    </Button>
                  )}
                </Box>
              </Group>
            </Box>

            <Box 
              style={{ 
                width: '100%',
                background: 'white',
                padding: '24px',
                borderRadius: '12px',
                boxShadow: '0 4px 20px rgba(34, 139, 230, 0.15)'
              }}
            >
              {pattern.imageUrl && (
                <Box maw="100%" m="auto" mb="xl">
                  <Image
                    src={pattern.imageUrl ? getFullImageUrl(pattern.imageUrl) : '/placeholder.png'}
                    height="auto"
                    w="100%"
                    fit="contain"
                    radius="md"
                    mah={600}
                    bg="transparent"
                    style={{
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    }}
                  />
                </Box>
              )}

              <Text size="lg" mb="xl" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
                {pattern.description}
              </Text>

              <Title 
                order={2} 
                mb="md"
                style={{
                  background: 'linear-gradient(45deg, #228BE6, #40C057)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  letterSpacing: '0.3px'
                }}
              >
                Materials Needed
              </Title>
              <List spacing="xs" mb="xl">
                {pattern.materialsNeeded?.map((material, index) => (
                  <List.Item key={index}>
                    {material.purchaseUrl ? (
                      <Text 
                        component="a"
                        href={material.purchaseUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        fw={500}
                        c="blue"
                        style={{ textDecoration: 'none' }}
                        display="inline"
                      >
                        {material.quantity} {material.name}
                      </Text>
                    ) : (
                      <Text fw={500} display="inline">{material.quantity} {material.name}</Text>
                    )}
                    {material.notes && (
                      <Text display="inline" size="sm" c="dimmed" ml={5}>
                        ({material.notes})
                      </Text>
                    )}
                  </List.Item>
                ))}
              </List>

              <Title
                order={2}
                mb="md"
                style={{
                  background: 'linear-gradient(45deg, #228BE6, #40C057)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  letterSpacing: '0.3px'
                }}
              >
                Instructions
              </Title>
              
              {/* 
                Show content if ANY of these are true:
                1. User is Admin/Subscriber/Has views remaining
                2. Any of the content arrays have data
                Only show subscription message if user has no views AND all arrays are empty
              */}
              {(authUser?.role === 'Admin' || 
                authUser?.role === 'Subscriber' || 
                (authUser?.remainingViews && authUser.remainingViews > 0) ||
                (pattern.sections && pattern.sections.length > 0) || 
                (pattern.assemblyInstructions && pattern.assemblyInstructions.length > 0) || 
                (pattern.specialNotes && pattern.specialNotes.length > 0)) ? (
                <>
                  {getProjectTypeDisplayName(pattern.projectType) === 'Crochet' ? (
                    <Tabs defaultValue="right" mb="md">
                      <Tabs.List style={{ display: 'flex', justifyContent: 'center', gap: '2rem' }}>
                        <Tabs.Tab value="right">Right-Handed</Tabs.Tab>
                        <Tabs.Tab value="left">Left-Handed</Tabs.Tab>
                      </Tabs.List>

                      <Tabs.Panel value="right">
                        <div style={{ height: '45px', marginBottom: 'md' }}>
                          {/* This empty space matches the height of the left-handed description */}
                        </div>
                        {pattern.sections?.map((section, index) => (
                          <div key={index} style={{ marginBottom: '2rem' }}>
                            <Title 
                              order={3} 
                              mb="sm"
                              style={{
                                color: '#1a73e8',
                                letterSpacing: '-0.02em'
                              }}
                            >
                              {section.name}
                            </Title>
                            <List spacing="xs" mb="xl" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
                              {section.instructions.map((instruction: string, idx: number) => (
                                <List.Item key={idx}>
                                  {instruction}
                                </List.Item>
                              ))}
                            </List>
                          </div>
                        ))}

                        {pattern.assemblyInstructions && pattern.assemblyInstructions.length > 0 && (
                          <div style={{ marginBottom: '2rem' }}>
                            <Title 
                              order={3} 
                              mb="sm"
                              style={{
                                color: '#1a73e8',
                                letterSpacing: '-0.02em'
                              }}
                            >
                              Assembly Instructions
                            </Title>
                            <List spacing="xs" mb="xl" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
                              {pattern.assemblyInstructions.map((instruction: string, idx: number) => (
                                <List.Item key={idx}>
                                  {instruction}
                                </List.Item>
                              ))}
                            </List>
                          </div>
                        )}

                        {pattern.specialNotes && pattern.specialNotes.length > 0 && (
                          <div style={{ marginBottom: '2rem' }}>
                            <Title 
                              order={3} 
                              mb="sm"
                              style={{
                                color: '#1a73e8',
                                letterSpacing: '-0.02em'
                              }}
                            >
                              Special Notes
                            </Title>
                            <List spacing="xs" mb="xl" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
                              {pattern.specialNotes.map((note: string, idx: number) => (
                                <List.Item key={idx}>
                                  {note}
                                </List.Item>
                              ))}
                            </List>
                          </div>
                        )}
                      </Tabs.Panel>

                      <Tabs.Panel value="left">
                        <Text size="sm" fs="italic" mb="md" style={{ color: '#228BE6' }}>
                          This pattern has been specially adapted for left-handed crocheters. Directional terms, stitch techniques, and working order have been mirrored to create a more intuitive experience.
                        </Text>
                        {pattern.sections?.map((section, index) => (
                          <div key={index} style={{ marginBottom: '2rem' }}>
                            <Title 
                              order={3} 
                              mb="sm"
                              style={{
                                color: '#1a73e8',
                                letterSpacing: '-0.02em'
                              }}
                            >
                              {section.name}
                            </Title>
                            <List spacing="xs" mb="xl" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
                              {section.instructions.map((instruction: string, idx: number) => (
                                <List.Item key={idx}>
                                  {adaptForLeftHanded(instruction)}
                                </List.Item>
                              ))}
                            </List>
                          </div>
                        ))}

                        {pattern.assemblyInstructions && pattern.assemblyInstructions.length > 0 && (
                          <div style={{ marginBottom: '2rem' }}>
                            <Title 
                              order={3} 
                              mb="sm"
                              style={{
                                color: '#1a73e8',
                                letterSpacing: '-0.02em'
                              }}
                            >
                              Assembly Instructions
                            </Title>
                            <List spacing="xs" mb="xl" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
                              {pattern.assemblyInstructions.map((instruction: string, idx: number) => (
                                <List.Item key={idx}>
                                  {instruction}
                                </List.Item>
                              ))}
                            </List>
                          </div>
                        )}

                        {pattern.specialNotes && pattern.specialNotes.length > 0 && (
                          <div style={{ marginBottom: '2rem' }}>
                            <Title 
                              order={3} 
                              mb="sm"
                              style={{
                                color: '#1a73e8',
                                letterSpacing: '-0.02em'
                              }}
                            >
                              Special Notes
                            </Title>
                            <List spacing="xs" mb="xl" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
                              {pattern.specialNotes.map((note: string, idx: number) => (
                                <List.Item key={idx}>
                                  {note}
                                </List.Item>
                              ))}
                            </List>
                          </div>
                        )}
                      </Tabs.Panel>
                    </Tabs>
                  ) : (
                    <>
                      {pattern.sections?.map((section, index) => (
                        <div key={index} style={{ marginBottom: '2rem' }}>
                          <Title 
                            order={3} 
                            mb="sm"
                            style={{
                              color: '#1a73e8',
                              letterSpacing: '-0.02em'
                            }}
                          >
                            {section.name}
                          </Title>
                          <List spacing="xs" mb="xl" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
                            {section.instructions.map((instruction: string, idx: number) => (
                              <List.Item key={idx}>
                                {instruction}
                              </List.Item>
                            ))}
                          </List>
                        </div>
                      ))}

                      {pattern.assemblyInstructions && pattern.assemblyInstructions.length > 0 && (
                        <div style={{ marginBottom: '2rem' }}>
                          <Title 
                            order={3} 
                            mb="sm"
                            style={{
                              color: '#1a73e8',
                              letterSpacing: '-0.02em'
                            }}
                          >
                            Assembly Instructions
                          </Title>
                          <List spacing="xs" mb="xl" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
                            {pattern.assemblyInstructions.map((instruction: string, idx: number) => (
                              <List.Item key={idx}>
                                {instruction}
                              </List.Item>
                            ))}
                          </List>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Paper p="xl" bg="blue.1" radius="md">
                  {!authUser ? (
                    <>
                      <Text size="lg" fw={500} c="blue.9">
                        Sign in to view pattern instructions
                      </Text>
                      <Text mt="md">
                        Create a free account to get 10 pattern views per month! Sign in to start viewing patterns today.
                      </Text>
                      <Group mt="lg">
                        <Button onClick={() => navigate('/signin', { state: { from: location.pathname } })}>Sign In</Button>
                      </Group>
                    </>
                  ) : (
                    <>
                      <Text size="lg" fw={500} c="blue.9">
                        You have used all your pattern views for this month
                      </Text>
                      <Text mt="md">
                        Subscribe to unlock unlimited pattern views and increased pattern generations!
                      </Text>
                      <Group mt="lg">
                        <StripeCheckoutButton />
                      </Group>
                    </>
                  )}
                </Paper>
              )}
            </Box>

            {/* AI-Generated Pattern Notice (floats at the bottom of the page) */}
            {showAiNotice && (
              <AiGeneratedPatternNotice 
                position="fixed" 
                onClose={() => setShowAiNotice(false)} 
              />
            )}

            <Box 
              style={{ 
                width: '100%',
                background: 'white',
                padding: '24px',
                borderRadius: '12px',
                boxShadow: '0 4px 20px rgba(34, 139, 230, 0.15)'
              }}
            >
              <div ref={commentsRef}>
                <Title 
                  order={2} 
                  mb="md"
                  style={{
                    background: 'linear-gradient(45deg, #228BE6, #40C057)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    letterSpacing: '0.3px'
                  }}
                >
                  Comments
                </Title>
                <CommentList 
                  patternId={id}
                  isLoading={isLoadingPattern || isLoadingComments || isLoadingPendingComments}
                  onCommentApproved={handleCommentApproved}
                  comments={comments}
                  pendingComments={pendingComments}
                />
                
                <Space h="xl" />
                
                <Title 
                  order={3} 
                  mb="md"
                  style={{
                    background: 'linear-gradient(45deg, #228BE6, #40C057)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    letterSpacing: '0.3px'
                  }}
                >
                  Add a Comment
                </Title>
                <CommentForm 
                  patternId={id}
                  onCommentAdded={handleCommentAdded}
                />
              </div>
            </Box>
          </Stack>
        </Paper>

        <Modal 
          opened={deleteModalOpened} 
          onClose={closeDeleteModal}
          title={
            <Text 
              size="lg" 
              fw={600}
              style={{
                background: 'linear-gradient(45deg, #228BE6, #40C057)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Delete Pattern
            </Text>
          }
        >
          <Stack>
            <Text>Are you sure you want to delete this pattern? This action cannot be undone.</Text>
            <Group justify="flex-end">
              <Button variant="light" onClick={closeDeleteModal}>Cancel</Button>
              <Button color="red" onClick={handleDelete} loading={isDeleting}>Delete</Button>
            </Group>
          </Stack>
        </Modal>

        <Modal 
          opened={testerModalOpened} 
          onClose={closeTesterModal} 
          title={
            <Text 
              fw={700}
              size="lg"
              style={{
                background: 'linear-gradient(45deg, #228BE6, #40C057)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                letterSpacing: '0.3px',
                marginBottom: 0
              }}
            >
              Our Pattern Testing Program
            </Text>
          }
          centered
          radius="lg"
          padding="xl"
          size="lg"
          styles={{
            body: {
              padding: '1.5rem',
              paddingTop: '0.5rem'
            },
            header: {
              marginBottom: 0,
              paddingBottom: '0.5rem'
            },
            content: {
              background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)',
              boxShadow: '0 4px 20px rgba(34, 139, 230, 0.15)',
              border: '1px solid #e9ecef',
            }
          }}
        >
          <Stack gap="sm">
            <Text size="md" style={{ lineHeight: 1.5, color: '#2c3e50' }}>
              Our Pattern Testing Program lets you improve patterns and earn karma points by testing them before they're officially verified.
            </Text>
            
            <Paper withBorder p="md" radius="md" bg="rgba(245, 247, 250, 0.8)">
              <Stack gap="xs">
                <Text fw={500} size="sm" style={{ color: '#228BE6' }}>
                  When you sign up as a tester:
                </Text>
                <Text size="sm" style={{ lineHeight: 1.5, color: '#2c3e50' }}>
                  • You'll create a testing journal to track progress and provide feedback
                </Text>
                {authUser?.role !== "Subscriber" && (
                  <Text size="sm" style={{ lineHeight: 1.5, color: '#2c3e50' }}>
                    • Free users can test up to 2 patterns at a time
                  </Text>
                )}
                <Text size="sm" style={{ lineHeight: 1.5, color: '#2c3e50' }}>
                  • Subscribers can test up to 4 patterns at a time
                </Text>
                {authUser?.role !== "Subscriber" && (
                  <Text size="sm" style={{ lineHeight: 1.5, color: '#2c3e50', fontWeight: 500 }}>
                    • Patterns you're testing will always be visible, even if you've reached your pattern view limit
                  </Text>
                )}
              </Stack>
            </Paper>
            
            <Paper withBorder p="md" radius="md" bg="rgba(255, 227, 227, 0.8)">
              <Text size="sm" fw={600} style={{ lineHeight: 1.5, color: '#C92A2A' }}>
                ⚠️ Important: You cannot stop testing a pattern until one of the following:
              </Text>
              <Text size="sm" style={{ lineHeight: 1.5, color: '#2c3e50', paddingLeft: '24px', marginTop: '4px' }}>
                • At least 30 days have passed since you started testing
              </Text>
              <Text size="sm" style={{ lineHeight: 1.5, color: '#2c3e50', paddingLeft: '24px' }}>
                • Your testing is completed and has been approved
              </Text>
              <Text size="sm" style={{ lineHeight: 1.5, color: '#2c3e50', paddingLeft: '24px' }}>
                • Testing has been completed by another crafter and the pattern has been verified
              </Text>
            </Paper>
            
            <Group justify="flex-end" mt="md">
              <Button 
                variant="subtle" 
                onClick={closeTesterModal}
                size="md"
              >
                Cancel
              </Button>
              <Button 
                variant="gradient" 
                gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
                onClick={handleTesterSignup}
                loading={isSigningUp}
                size="md"
                style={{ boxShadow: '0 4px 12px rgba(34, 139, 230, 0.25)' }}
              >
                Signup as Tester
              </Button>
            </Group>
          </Stack>
        </Modal>

        {/* Pattern Limit Modal */}
        <Modal
          opened={patternLimitModalOpened}
          onClose={closePatternLimitModal}
          title={
            <Text
              fw={700}
              size="lg"
              style={{
                background: 'linear-gradient(45deg, #228BE6, #40C057)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                letterSpacing: '0.3px',
                marginBottom: 0
              }}
            >
              Subscription Required
            </Text>
          }
          centered
          radius="lg"
          padding="xl"
          size="lg"
          styles={{
            body: {
              padding: '1.5rem',
              paddingTop: '0.5rem'
            },
            header: {
              marginBottom: 0,
              paddingBottom: '0.5rem'
            },
            content: {
              background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)',
              boxShadow: '0 4px 20px rgba(34, 139, 230, 0.15)',
              border: '1px solid #e9ecef',
            }
          }}
        >
          <Stack gap="md">
            <Text size="md" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              You can test a maximum of 2 patterns at once. Upgrade to a subscription to test up to 4 patterns simultaneously.
            </Text>
            
            <Paper withBorder p="md" radius="md" bg="rgba(255, 255, 255, 0.8)" mt="md">
              <Stack gap="xs">
                <Text size="md" fw={500} style={{ lineHeight: 1.6, color: '#2c3e50' }}>
                  Subscriber Benefits:
                </Text>
                <List spacing="xs" style={{ paddingLeft: '1rem', color: '#2c3e50' }}>
                  <List.Item>
                    <Text>
                      <span style={{ fontWeight: 500 }}>Test up to 4 patterns</span> simultaneously
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text>
                      <span style={{ fontWeight: 500 }}>Unlimited pattern views</span> each month
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text>
                      <span style={{ fontWeight: 500 }}>20 AI pattern generations</span> each month
                    </Text>
                  </List.Item>
                </List>
              </Stack>
            </Paper>
            
            <Group justify="center" mt="lg">
              <StripeCheckoutButton />
            </Group>
          </Stack>
        </Modal>

        {/* Add the verification alert near the top of the component or where appropriate */}
        {VerificationAlert}
      </Container>
    </>
  );
} 