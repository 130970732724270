import { CSSProperties } from 'react';

// Gradient text style used in titles and headings
export const gradientText: CSSProperties = {
  background: 'linear-gradient(45deg, #228BE6, #40C057)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.3px',
};

// Metallic gold style for verified badges
export const metallicGoldStyle = `
  .verified-badge {
    background: linear-gradient(135deg, #ffd84c 0%, #eac141 20%, #fff388 45%, #ffd700 60%, #d4af37 80%, #c5a028 100%) !important;
    color: #000 !important;
    font-weight: bold !important;
    border: 1px solid rgba(212, 175, 55, 0.6) !important;
    text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
  }
`;

// Paper container with gradient background
export const paperGradient: CSSProperties = {
  background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)',
};

// Secondary paper with lighter gradient
export const secondaryPaperGradient: CSSProperties = {
  background: 'linear-gradient(135deg, #f8f9fa, #e9ecef)',
  border: '1px solid #dee2e6',
};

// Box shadow for cards and containers
export const elevatedShadow: CSSProperties = {
  boxShadow: '0 4px 20px rgba(34, 139, 230, 0.15)',
};

// Image shadow
export const imageShadow: CSSProperties = {
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
};

// Content box style (white background with shadow)
export const contentBox: CSSProperties = {
  width: '100%',
  background: 'white',
  padding: '24px',
  borderRadius: '12px',
  ...elevatedShadow,
};

// Card base style
export const cardBase: CSSProperties = {
  textDecoration: 'none',
  color: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  ...paperGradient,
  ...elevatedShadow,
}; 