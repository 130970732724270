import { Modal, Paper, Stack, Text, List, ThemeIcon, Group, Button } from '@mantine/core';
import { IconArrowRight, IconGift, IconStar } from '@tabler/icons-react';
import { gradientText, contentBox, paperGradient, elevatedShadow } from '../styles/common';

interface KarmaModalProps {
    opened: boolean;
    onClose: () => void;
}

export function KarmaModal({ opened, onClose }: KarmaModalProps) {
    return (
        <Modal 
            opened={opened} 
            onClose={onClose} 
            title={<Text fw={700} size="lg" style={gradientText}>Karma System</Text>}
            size="md"
            centered
            styles={{
                title: { width: '100%' },
                header: { borderBottom: '1px solid #e9ecef', paddingBottom: 16, marginBottom: 5 }
            }}
        >
            <Paper p="md" style={{...paperGradient, ...elevatedShadow}} radius="md">
                <Stack gap="md">
                    <Text fw={600} size="lg" style={gradientText}>Coming Soon!</Text>
                    <Text>
                        The Karma system allows you to earn and spend Karma points across The Fiber Collective.
                    </Text>
                    
                    <Paper p="md" style={contentBox} radius="md">
                        <Text fw={600} mb="xs">How to earn Karma:</Text>
                        <List
                            spacing="xs"
                            size="sm"
                            center
                            icon={
                                <ThemeIcon color="blue" size={22} radius="xl">
                                    <IconStar size={14} />
                                </ThemeIcon>
                            }
                        >
                            <List.Item>Testing Patterns</List.Item>
                            <List.Item>More ways to earn Karma coming in the future!</List.Item>
                        </List>
                    </Paper>
                    
                    <Paper p="md" style={contentBox} radius="md">
                        <Text fw={600} mb="xs">How to spend Karma:</Text>
                        <List
                            spacing="xs"
                            size="sm"
                            center
                            icon={
                                <ThemeIcon color="purple" size={22} radius="xl">
                                    <IconGift size={14} />
                                </ThemeIcon>
                            }
                        >
                            <List.Item>Get extra pattern generations beyond your monthly limit</List.Item>
                            <List.Item>More ways to use Karma coming in the future!</List.Item>
                        </List>
                    </Paper>
                    
                    <Group mt="md" justify="center">
                        <Button 
                            variant="light" 
                            color="blue" 
                            onClick={onClose}
                            rightSection={<IconArrowRight size={14} />}
                            style={{
                                background: 'linear-gradient(45deg, #228be6, #4dabf7)',
                                color: 'white',
                                fontWeight: 600,
                                boxShadow: '0 2px 8px rgba(34, 139, 230, 0.25)',
                            }}
                        >
                            Got it!
                        </Button>
                    </Group>
                    
                    <Text c="dimmed" size="sm" ta="center">
                        Keep an eye out for the full Karma system release - we're working on making it even better!
                    </Text>
                </Stack>
            </Paper>
        </Modal>
    );
} 