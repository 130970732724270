import { ActionIcon, Menu, Text } from '@mantine/core';
import { IconQuestionMark } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../package.json';

export function FloatingHelpButton() {
  const navigate = useNavigate();

  return (
    <Menu 
      position="right-start"
      offset={12}
      shadow="md"
      width={200}
    >
      <Menu.Target>
        <ActionIcon
          variant="filled"
          color="gray.0"
          size="xl"
          radius="md"
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '20px',
            zIndex: 1000,
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <IconQuestionMark 
            style={{ 
              color: 'black',
              width: '60%',
              height: '60%'
            }} 
          />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item onClick={() => navigate('/about')}>
          About Us
        </Menu.Item>
        <Menu.Item onClick={() => navigate('/terms')}>
          Terms of Service
        </Menu.Item>
        <Menu.Item onClick={() => navigate('/privacy')}>
          Privacy Policy
        </Menu.Item>
        <Text size="xs" c="dimmed" ta="right" pr={8} pb={4} style={{ fontSize: '0.5rem' }}>
          v{packageJson.version}
        </Text>
      </Menu.Dropdown>
    </Menu>
  );
} 