import { PatternCategory, ProjectType } from '../types/api';

// Create a new file for these shared utilities
export const getDifficultyColor = (level: string): string => {
  switch (level.toLowerCase()) {
    case 'beginner': return 'green';
    case 'intermediate': return 'yellow';
    case 'advanced': return 'orange';
    case 'expert': return 'red';
    default: return 'gray';
  }
};

export const getCategoryDisplayName = (category: PatternCategory): string => {
  switch (category.toString()) {
    case '0': return 'Apparel';
    case '1': return 'Accessories';
    case '2': return 'Toys';
    case '3': return 'Granny Squares';
    case '4': return 'Home Decor';
    case '5': return 'Other';
    default: return 'Other';
  }
};

export const getProjectTypeDisplayName = (projectType: ProjectType): string => {
  switch (projectType.toString()) {
    case '0': return 'Crochet';
    case '1': return 'Knit';
    case '2': return 'Needlepoint';
    default: return 'Unknown';
  }
};

/**
 * Adapts crochet instructions for left-handed users
 * @param instruction The original right-handed instruction
 * @returns The adapted instruction for left-handed crocheters
 */
export const adaptForLeftHanded = (instruction: string): string => {
  if (!instruction) return instruction;
  
  // Helper function to preserve case when replacing words
  const replacePreservingCase = (text: string, search: string, replacement: string): string => {
    const regex = new RegExp(search, 'gi');
    return text.replace(regex, match => {
      if (match === match.toLowerCase()) return replacement.toLowerCase();
      if (match === match.toUpperCase()) return replacement.toUpperCase();
      if (match.charAt(0) === match.charAt(0).toUpperCase()) 
        return replacement.charAt(0).toUpperCase() + replacement.slice(1).toLowerCase();
      return replacement.toLowerCase();
    });
  };

  let result = instruction;
  
  // First, protect the compound terms that shouldn't be affected by the left/right swap
  // Use unique placeholders that won't conflict with normal text
  const protectedTerms = [
    // Format: [original term, placeholder]
    ['right side', '%%RIGHT_SIDE_TERM%%'],
    ['right-side', '%%RIGHT_HYPHEN_SIDE_TERM%%'],
    ['left side', '%%LEFT_SIDE_TERM%%'],
    ['left-side', '%%LEFT_HYPHEN_SIDE_TERM%%'],
  ];
  
  // Protect the special terms first
  protectedTerms.forEach(([term, placeholder]) => {
    result = result.replace(new RegExp(term, 'gi'), placeholder);
  });
  
  // Replace direction-related terms
  const directionalPairs = [
    ['left', 'right'],
    ['clockwise', 'counterclockwise'],
    ['front', 'back'],
    ['forward', 'backward']
  ];
  
  directionalPairs.forEach(([term1, term2]) => {
    // Use a temporary placeholder to avoid double replacements
    const temp = '%%TEMP_PLACEHOLDER%%';
    result = replacePreservingCase(result, term1, temp);
    result = replacePreservingCase(result, term2, term1);
    result = replacePreservingCase(result, temp, term2);
  });
  
  // Now handle the special "side" terms with their correct pairings
  const sidePairs = [
    ['%%RIGHT_SIDE_TERM%%', '%%WRONG_SIDE_TERM%%'],
    ['%%RIGHT_HYPHEN_SIDE_TERM%%', '%%WRONG_HYPHEN_SIDE_TERM%%'],
  ];
  
  // Create the wrong side placeholders
  result = result.replace(/wrong side/gi, '%%WRONG_SIDE_TERM%%');
  result = result.replace(/wrong-side/gi, '%%WRONG_HYPHEN_SIDE_TERM%%');
  
  // Now swap the side terms
  sidePairs.forEach(([term1, term2]) => {
    const temp = '%%SIDE_TEMP%%';
    result = result.replace(new RegExp(term1, 'gi'), temp);
    result = result.replace(new RegExp(term2, 'gi'), term1);
    result = result.replace(new RegExp(temp, 'gi'), term2);
  });
  
  // Restore any protected terms that weren't part of a swap
  result = result.replace(/%%LEFT_SIDE_TERM%%/g, 'left side');
  result = result.replace(/%%LEFT_HYPHEN_SIDE_TERM%%/g, 'left-side');
  result = result.replace(/%%RIGHT_SIDE_TERM%%/g, 'right side');
  result = result.replace(/%%RIGHT_HYPHEN_SIDE_TERM%%/g, 'right-side');
  result = result.replace(/%%WRONG_SIDE_TERM%%/g, 'wrong side');
  result = result.replace(/%%WRONG_HYPHEN_SIDE_TERM%%/g, 'wrong-side');
  
  // Handle specific crochet terminology
  const stitchPairs = [
    ['front loop only', 'back loop only'],
    ['FLO', 'BLO'],
    ['front post', 'back post'],
    ['FP', 'BP']
  ];
  
  stitchPairs.forEach(([term1, term2]) => {
    const temp = '%%TEMP_PLACEHOLDER%%';
    result = result.replace(new RegExp(term1, 'gi'), temp);
    result = result.replace(new RegExp(term2, 'gi'), term1);
    result = result.replace(new RegExp(temp, 'gi'), term2);
  });
  
  // Reverse numbering for certain increases/decreases if they specify working from left to right
  // This is a simplified approach - complete adaptation would require parsing complex instructions
  if (result.match(/increase across/i) || result.match(/decrease across/i)) {
    const numbers = result.match(/\d+/g);
    if (numbers && numbers.length > 1) {
      // Check if this is a sequence of numbers that might need to be reversed
      const isSequence = numbers.every((n, i) => i === 0 || parseInt(n) === parseInt(numbers[i-1]) + 1 || parseInt(n) === parseInt(numbers[i-1]) - 1);
      if (isSequence) {
        // Replace numbers in reverse order
        const reversedNumbers = [...numbers].reverse();
        let tempResult = result;
        numbers.forEach((num, i) => {
          tempResult = tempResult.replace(num, `[${reversedNumbers[i]}]`);
        });
        result = tempResult.replace(/\[\d+\]/g, match => match.substring(1, match.length - 1));
      }
    }
  }
  
  return result;
}; 