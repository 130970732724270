import { Group, Badge, Button, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { EventSummary } from '../types/Events';
import { formatDate } from '../utils/dateUtils';
import { IconMapPin, IconCalendar, IconExternalLink } from '@tabler/icons-react';
import { useAuth } from '../contexts/AuthContext';
import { ContentCard } from './common/ContentCard';
import { ModeratableEntity } from '../types/common';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { eventsApi } from '../api/events';
import { invalidateEventQueries } from '../utils/eventCache';
import { useEmailVerification } from '../hooks/useEmailVerification';

interface EventCardProps {
  event: EventSummary;
  onApprove?: (id: string) => void;
  onDelete?: (id: string) => void;
  searchTerm?: string;
}

export function EventCard({ event, onApprove, onDelete, searchTerm }: EventCardProps) {
  const { user } = useAuth();
  const isAdmin = user?.role === 'Admin';
  const isOwner = event.isOwner;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  
  // Use the email verification hook
  const { checkVerification, VerificationAlert } = useEmailVerification({
    actionName: 'claiming an event'
  });
  
  // Use the formatDate function which now preserves UTC timezone
  const formattedDate = formatDate(event.startDate, false);
  const formattedEndDate = event.endDate ? formatDate(event.endDate, false) : null;
  
  // For virtual events, don't show any location text - we'll just display the virtual badge
  const hasAddressLine1 = !!event.addressLine1;
  const hasAddressLine2 = !!event.addressLine2;

  // Create the city, state, country display
  const cityStateCountryDisplay = [
    event.city ? event.city : null,
    event.state ? event.state : null,
    event.country ? (event.country === "United States of America" ? "USA" : event.country) : null
  ]
  .filter(Boolean)
  .join(", ");

  // Full location for Google Maps query
  const fullLocationDisplay = [
    event.addressLine1,
    event.addressLine2,
    cityStateCountryDisplay
  ]
  .filter(Boolean)
  .join(", ");
  
  // Format the recurrence info
  const recurrenceInfo = event.isRecurring
    ? `${event.recurrenceFrequency || 'Recurring'}`
    : null;

  const handleEdit = () => {
    navigate(`/events/${event.id}/edit`);
  };

  // Add mutation for removing owner
  const { mutate: removeOwner } = useMutation({
    mutationFn: (eventId: string) => eventsApi.removeOwner(eventId),
    onSuccess: (_, eventId) => {
      notifications.show({
        title: 'Success',
        message: 'Event owner has been removed',
        color: 'green'
      });
      invalidateEventQueries(queryClient, eventId);
    },
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to remove event owner',
        color: 'red'
      });
    }
  });
  
  // Handle removing the owner (admin only)
  const handleRemoveOwner = () => {
    if (event.id && isAdmin) {
      removeOwner(event.id);
    }
  };

  const { mutate: claimEvent } = useMutation({
    mutationFn: (eventId: string) => eventsApi.claim(eventId),
    onSuccess: (_, eventId) => {
      notifications.show({
        title: 'Success',
        message: 'Claim request submitted successfully',
        color: 'green'
      });
      invalidateEventQueries(queryClient, eventId);
    },
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to claim event',
        color: 'red'
      });
    }
  });

  const handleClaim = (e: React.MouseEvent) => {
    // Use the checkVerification function from our hook
    if (checkVerification(e)) {
      claimEvent(event.id);
    }
  };

  // Render the organizer name as a subheading
  const renderSubheading = (item: EventSummary) => (
    item.organizerName ? (
      <Text size="xs" c="dimmed" fs="italic">
        Organizer: {item.organizerName}
      </Text>
    ) : null
  );

  // Render the location and date information
  const renderMeta = (item: EventSummary) => (
    <>
      {/* Use the VerificationAlert from our hook */}
      {VerificationAlert}
    
      <Group mb={0} gap={5} align="center" justify="space-between">
        <Group gap={5} align="center">
          <IconCalendar size={16} />
          <Group gap={5}>
            {formattedDate}
            {recurrenceInfo && (
              <Badge size="sm" variant="light" color="blue" ml={5}>
                {recurrenceInfo}
              </Badge>
            )}
          </Group>
        </Group>
        
        {/* Ownership and claiming badges */}
        <Group gap={5}>
          {!item.isApproved && (
            <Badge size="sm" variant="filled" color="yellow">
              Pending Approval
            </Badge>
          )}
          {item.isClaimPending && (
            <Badge size="sm" variant="filled" color="yellow">
              Claim Pending
            </Badge>
          )}
          {/* Show "Claimed" badge only to admins when the event has an owner */}
          {isAdmin && item.isClaimed && (
            <Badge size="sm" variant="filled" color="cyan">
              Claimed
            </Badge>
          )}
          {item.isOwner && (
            <Badge size="sm" variant="filled" color="green">
              Owned
            </Badge>
          )}
          {!item.isOwner && !item.isClaimPending && !item.isClaimed && (
            <Badge 
              size="sm" 
              variant="filled" 
              color="blue"
              style={{ cursor: 'pointer' }}
              onClick={handleClaim}
              title="If you are the owner of this event, click here to claim it"
            >
              Claim Now!
            </Badge>
          )}
        </Group>
      </Group>
      
      {formattedEndDate && (
        <Group mt={2} gap={5} align="center">
          <div style={{ width: '16px' }}></div>
          <span style={{ fontSize: '14px', color: 'var(--mantine-color-dimmed)' }}>
            to {formattedEndDate}
          </span>
        </Group>
      )}

      <Group mt={5} gap={5} justify="space-between">
        <div>
          <Group gap={5} style={{ alignItems: 'flex-start' }}>
            <IconMapPin size={16} style={{ marginTop: '3px' }} />
            {event.isVirtual ? (
              <Badge size="xs" variant="light" color="cyan">
                Virtual
              </Badge>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {hasAddressLine1 && (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullLocationDisplay)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ 
                      fontSize: '14px', 
                      color: 'var(--mantine-color-blue-6)',
                      textDecoration: 'none',
                      lineHeight: '1.4'
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'}
                    onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}
                  >
                    {event.addressLine1}
                  </a>
                )}
                {hasAddressLine2 && (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullLocationDisplay)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ 
                      fontSize: '14px', 
                      color: 'var(--mantine-color-blue-6)',
                      textDecoration: 'none',
                      lineHeight: '1.4'
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'}
                    onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}
                  >
                    {event.addressLine2}
                  </a>
                )}
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullLocationDisplay)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ 
                    fontSize: '14px', 
                    color: 'var(--mantine-color-blue-6)',
                    textDecoration: 'none',
                    lineHeight: '1.4'
                  }}
                  onClick={(e) => e.stopPropagation()}
                  onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'}
                  onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}
                >
                  {cityStateCountryDisplay}
                </a>
              </div>
            )}
          </Group>
        </div>
        
        {event.eventUrl && (
          <Button
            component="a"
            href={event.eventUrl}
            target="_blank"
            rel="noopener noreferrer"
            variant="filled"
            color="blue"
            size="xs"
            rightSection={<IconExternalLink size={14} />}
            onClick={(e) => e.stopPropagation()}
          >
            Event Site
          </Button>
        )}
      </Group>
    </>
  );

  // Use our common ContentCard component
  return (
    <ContentCard<EventSummary & ModeratableEntity>
      item={event as EventSummary & ModeratableEntity}
      imagePlaceholder="/event-placeholder.jpg"
      imageAlt={event.title}
      onEdit={isOwner || isAdmin ? handleEdit : undefined}
      onDelete={isOwner || isAdmin ? () => onDelete?.(event.id) : undefined}
      onApprove={isAdmin && !event.isApproved ? () => onApprove?.(event.id) : undefined}
      onRemoveOwner={isAdmin && event.isClaimed ? handleRemoveOwner : undefined}
      renderMeta={renderMeta}
      renderSubheading={renderSubheading}
      layout="horizontal"
      width="100%"
      imageHeight={200}
      imageObjectFit="contain"
      searchTerm={searchTerm}
    />
  );
} 