import { Alert, Button, Stack, Group } from '@mantine/core';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconAlertCircle, IconCheck } from '@tabler/icons-react';
import { authApi } from '../../api/auth';
import { useAuth } from '../../contexts/AuthContext';

export function EmailVerification() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [verifying, setVerifying] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { signOut } = useAuth();
  
  const email = searchParams.get('email');
  const token = searchParams.get('token');

  useEffect(() => {
    async function verifyEmail() {
      if (!email || !token) {
        setVerifying(false);
        setError('Invalid verification link. The link may be expired or invalid.');
        return;
      }

      try {
        await authApi.verifyEmail({ email, token });
        setSuccess(true);
        setVerifying(false);
        
        // Sign out the user and redirect to sign-in page after 3 seconds
        setTimeout(() => {
          signOut();
          navigate('/signin', { 
            state: { 
              message: 'Your email has been verified. Please sign in again to update your session.' 
            } 
          });
        }, 3000);
      } catch (err: any) {
        setVerifying(false);
        setError(err?.message || 'Email verification failed. Please try again or contact support.');
      }
    }

    verifyEmail();
  }, [email, token, navigate, signOut]);

  const handleResendEmail = () => {
    navigate('/resend-verification');
  };

  if (verifying) {
    return (
      <Alert title="Verifying your email" color="blue" icon={<IconAlertCircle />}>
        Please wait while we verify your email address...
      </Alert>
    );
  }

  if (success) {
    return (
      <Stack gap="md">
        <Alert title="Email Verified" color="green" icon={<IconCheck />}>
          Your email has been verified successfully. You will be redirected to the sign-in page to update your session.
        </Alert>
        <Button onClick={() => {
          signOut();
          navigate('/signin');
        }}>Go to Sign In</Button>
      </Stack>
    );
  }

  return (
    <Stack gap="md">
      <Alert title="Verification Failed" color="red" icon={<IconAlertCircle />}>
        {error || 'An error occurred during email verification.'}
      </Alert>
      <Group>
        <Button onClick={() => navigate('/signin')}>Go to Sign In</Button>
        <Button variant="outline" onClick={handleResendEmail}>Request New Verification Email</Button>
      </Group>
    </Stack>
  );
} 