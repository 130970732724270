import { Stack, Paper, Text, Group, Button, LoadingOverlay, Badge } from '@mantine/core';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { patternsApi } from '../../api/patterns';
import { Comment } from '../../types/Comment';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { secondaryPaperGradient } from '../../styles/common';

export function PendingComments() {
    const queryClient = useQueryClient();

    const { data: comments, isLoading } = useQuery<Comment[]>({
        queryKey: ['pending-comments'],
        queryFn: () => patternsApi.getAllPendingComments(),
    });

    const approveMutation = useMutation<void, Error, { patternId: string, commentId: string }>({
        mutationFn: ({ patternId, commentId }) =>
            patternsApi.approveComment(patternId, commentId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['pending-comments'] });
            notifications.show({
                title: 'Success',
                message: 'Comment approved successfully',
                color: 'green',
            });
        },
        onError: () => {
            notifications.show({
                title: 'Error',
                message: 'Failed to approve comment',
                color: 'red',
            });
        },
    });

    const rejectMutation = useMutation<void, Error, { patternId: string, commentId: string }>({
        mutationFn: ({ patternId, commentId }) =>
            patternsApi.rejectComment(patternId, commentId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['pending-comments'] });
            notifications.show({
                title: 'Success',
                message: 'Comment rejected successfully',
                color: 'green',
            });
        },
        onError: () => {
            notifications.show({
                title: 'Error',
                message: 'Failed to reject comment',
                color: 'red',
            });
        },
    });

    if (isLoading) {
        return (
            <Paper p="md" style={{ position: 'relative', minHeight: '200px' }}>
                <LoadingOverlay visible={true} />
            </Paper>
        );
    }

    if (!comments?.length) {
        return (
            <Text c="dimmed" ta="center">No pending comments to review</Text>
        );
    }

    return (
        <Stack gap="md">
            {comments.map((comment) => (
                <Paper 
                    key={comment.id} 
                    p="md" 
                    withBorder 
                    shadow="sm"
                    style={secondaryPaperGradient}
                >
                    <Stack gap="md">
                        <Group justify="space-between" wrap="nowrap">
                            <Stack gap={4}>
                                <Group gap="xs">
                                    <Text size="sm" fw={500}>{comment.commenterDisplayName}</Text>
                                    <Badge size="sm" variant="light">
                                        Pending
                                    </Badge>
                                </Group>
                                <Text size="xs" c="dimmed">
                                    {new Date(comment.createdAt).toLocaleString()}
                                </Text>
                            </Stack>
                            <Group gap="xs">
                                <Button
                                    size="xs"
                                    variant="outline"
                                    color="red"
                                    leftSection={<IconX size={16} />}
                                    onClick={() => rejectMutation.mutate({
                                        patternId: (comment as any).patternId,
                                        commentId: comment.id
                                    })}
                                    loading={rejectMutation.isPending}
                                >
                                    Reject
                                </Button>
                                <Button
                                    size="xs"
                                    variant="outline"
                                    color="green"
                                    leftSection={<IconCheck size={16} />}
                                    onClick={() => approveMutation.mutate({
                                        patternId: (comment as any).patternId,
                                        commentId: comment.id
                                    })}
                                    loading={approveMutation.isPending}
                                >
                                    Approve
                                </Button>
                            </Group>
                        </Group>
                        <Text size="sm">{comment.text}</Text>
                        <Text size="xs" component={Link} to={`/patterns/${(comment as any).patternId}`} c="dimmed">
                            View Pattern →
                        </Text>
                    </Stack>
                </Paper>
            ))}
        </Stack>
    );
} 