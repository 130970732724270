import { Badge, Stack, Group, MantineSize } from '@mantine/core';
import { getDifficultyColor, getCategoryDisplayName, getProjectTypeDisplayName } from '../utils/patternUtils';
import { PatternCategory, ProjectType } from '../types/api';
import { useEffect } from 'react';
import { metallicGoldStyle } from '../styles/common';

interface PatternBadgesProps {
  difficultyLevel?: string;
  category?: PatternCategory;
  projectType?: ProjectType;
  isCreator?: boolean;
  isVerified?: boolean;
  size?: MantineSize;
  stacked?: boolean;
}

export function PatternBadges({ 
  difficultyLevel, 
  category,
  projectType,
  isCreator = false,
  isVerified = false,
  size = 'md',
  stacked = false
}: PatternBadgesProps) {
  
  // Add metallic gold CSS only once
  useEffect(() => {
    if (!document.getElementById('metallic-gold-style')) {
      const styleEl = document.createElement('style');
      styleEl.id = 'metallic-gold-style';
      styleEl.innerHTML = metallicGoldStyle;
      document.head.appendChild(styleEl);
    }
    
    return () => {
      // Clean up the style if component unmounts
      const styleEl = document.getElementById('metallic-gold-style');
      if (styleEl && document.head.contains(styleEl)) {
        document.head.removeChild(styleEl);
      }
    };
  }, []);

  const badgeStyles = {
    root: {
      opacity: 0.8
    }
  };

  const badges = [
    projectType !== undefined && (
      <Badge
        key="project-type"
        variant="filled"
        size={size}
        color="blue"
        styles={badgeStyles}
      >
        {getProjectTypeDisplayName(projectType)}
      </Badge>
    ),
    category !== undefined && (
      <Badge
        key="category"
        variant="filled"
        size={size}
        color="gray"
        styles={badgeStyles}
      >
        {getCategoryDisplayName(category)}
      </Badge>
    ),
    difficultyLevel && (
      <Badge 
        key="difficulty"
        color={getDifficultyColor(difficultyLevel)}
        variant="filled"
        size={size}
        styles={badgeStyles}
      >
        {difficultyLevel}
      </Badge>
    ),
    !stacked && isCreator && (
      <Badge 
        key="creator"
        variant="filled"
        size={size}
        color="grape"
        styles={badgeStyles}
      >
        Created
      </Badge>
    ),
    isVerified && (
      <Badge 
        key="verified"
        variant="filled"
        size={size}
        className="verified-badge"
      >
        Verified
      </Badge>
    )
  ].filter(Boolean);

  const Container = stacked ? Stack : Group;
  
  return (
    <Container gap={stacked ? 4 : "xs"}>
      {badges}
    </Container>
  );
} 