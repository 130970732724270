import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Title, 
  Text, 
  Paper, 
  Stack, 
  Group, 
  Button, 
  Textarea, 
  FileInput, 
  Card,
  Divider,
  LoadingOverlay,
  NumberInput,
  Badge
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft, IconUpload, IconAlertTriangle } from '@tabler/icons-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { patternTestJournalsApi, JournalEntry } from '../api/patternTestJournals';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { useDisclosure } from '@mantine/hooks';
import { ConfirmDeleteModal } from '../components/ConfirmDeleteModal';
import { StopTestingModal } from '../components/StopTestingModal';
import { useReCaptcha } from '../hooks/useReCaptcha';
import { JournalEntryCard } from '../components/JournalEntryCard';
import { useEmailVerification } from '../hooks/useEmailVerification';

export function PatternTestJournalPage() {
  const { patternId } = useParams<{ patternId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  useAuth(); // Keep the hook if needed for authentication status
  const fileInputRef = useRef<HTMLButtonElement>(null);
  const { verify } = useReCaptcha();

  // Form state
  const [comment, setComment] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [completionPercentage, setCompletionPercentage] = useState<number | ''>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingJournal, setIsSubmittingJournal] = useState(false);
  const isInitialValueRef = useRef(true);
  const journalPercentageRef = useRef<number | null>(null);

  // Edit state
  const [editingEntryId, setEditingEntryId] = useState<string | null>(null);
  const [editComment, setEditComment] = useState('');
  const [editImage, setEditImage] = useState<File | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  // Delete state
  const [entryToDelete, setEntryToDelete] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);

  // Stop testing state
  const [isStoppingTest, setIsStoppingTest] = useState(false);
  const [stopTestingModalOpened, { open: openStopTestingModal, close: closeStopTestingModal }] = useDisclosure(false);

  // Add the email verification hook
  const { checkVerification, VerificationAlert } = useEmailVerification({
    actionName: 'submitting pattern test feedback'
  });

  // Fetch pattern test journal
  const { 
    data: journal, 
    isLoading
  } = useQuery({
    queryKey: ['pattern-test-journal', patternId],
    queryFn: async () => {
      if (!patternId) return null;
      try {
        return await patternTestJournalsApi.getByPatternId(patternId);
      } catch (error) {
        // Returning null instead of throwing the error
        // This prevents React Query from treating it as an error
        return null;
      }
    },
    enabled: !!patternId,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
    retry: false
  });

  // Initialize completion percentage from journal data
  useEffect(() => {
    if (journal && 'completionPercentage' in journal && typeof journal.completionPercentage === 'number') {
      setCompletionPercentage(journal.completionPercentage);
      journalPercentageRef.current = journal.completionPercentage;
      isInitialValueRef.current = true;
    }
  }, [journal]);

  // Handle user changes to completion percentage
  const handlePercentageChange = (value: string | number) => {
    // Mark as user edited
    isInitialValueRef.current = false;
    
    // Update local state
    setCompletionPercentage(value === '' ? '' : Number(value));
    
    // Update backend if it's a valid number
    if ((typeof value === 'number' || (typeof value === 'string' && value !== '')) && patternId) {
      const numericValue = typeof value === 'number' ? value : Number(value);
      const updateBackend = async () => {
        try {
          // Get reCAPTCHA token
          const recaptchaToken = await verify('update_journal_completion');
          await patternTestJournalsApi.updateCompletionPercentage(patternId, numericValue, recaptchaToken);
          
          // Invalidate queries to refresh data
          queryClient.invalidateQueries({ queryKey: ['pattern-test-journal', patternId] });
          queryClient.invalidateQueries({ queryKey: ['pattern-test-journals', 'my'] });
          
          // Success notification removed - no popup needed for routine updates
        } catch (error) {
          // Keep error notification
          notifications.show({
            title: 'Error',
            message: 'Failed to update completion percentage',
            color: 'red'
          });
        }
      };
      
      updateBackend();
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    // First verify email
    if (!checkVerification()) {
      return;
    }
    
    // Rest of the existing submission logic
    if (!comment.trim() && !image) {
      notifications.show({
        title: 'Missing Entry Data',
        message: 'Please provide a comment or an image',
        color: 'red'
      });
      return;
    }

    setIsSubmitting(true);

    const createEntryWithJournal = async () => {
      try {
        if (patternId) {
          // Get reCAPTCHA token
          const recaptchaToken = await verify('add_journal_entry');
          
          // Let backend handle journal creation if needed
          await patternTestJournalsApi.addEntry(patternId, {
            comment: comment,
            image: image || undefined,
            recaptchaToken
          });
          
          notifications.show({
            title: 'Success',
            message: 'Entry added successfully',
            color: 'green'
          });
          
          setComment('');
          setImage(null);
          
          // Invalidate queries to refresh data
          queryClient.invalidateQueries({ queryKey: ['pattern-test-journal', patternId] });
          queryClient.invalidateQueries({ queryKey: ['pattern-test-journals', 'my'] });
        }
      } catch (error: any) {
        notifications.show({
          title: 'Error',
          message: error.message || 'Failed to add entry',
          color: 'red'
        });
      } finally {
        setIsSubmitting(false);
      }
    };
    
    createEntryWithJournal();
  };

  // Go back to pattern detail page
  const handleBack = () => {
    navigate(`/patterns/${patternId}`);
  };

  // Handle edit entry
  const handleEditEntry = (entry: JournalEntry) => {
    setEditingEntryId(entry.id);
    setEditComment(entry.comment);
    setEditImage(null);
  };

  // Handle cancel edit
  const handleCancelEdit = () => {
    setEditingEntryId(null);
    setEditComment('');
    setEditImage(null);
  };

  // Handle save edit
  const handleSaveEdit = async () => {
    if (!journal || !editingEntryId) return;
    
    // First verify email
    if (!checkVerification()) {
      return;
    }
    
    setIsEditing(true);
    try {
      // Get reCAPTCHA token
      const recaptchaToken = await verify('edit_journal_entry');
      
      await patternTestJournalsApi.updateEntry(journal.id, editingEntryId, {
        comment: editComment,
        image: editImage || undefined,
        recaptchaToken
      });
      
      notifications.show({
        title: 'Success',
        message: 'Entry updated successfully',
        color: 'green'
      });
      
      // Reset edit state
      setEditingEntryId(null);
      setEditComment('');
      setEditImage(null);
      
      // Invalidate queries to refresh data
      queryClient.invalidateQueries({ queryKey: ['pattern-test-journal', patternId] });
      queryClient.invalidateQueries({ queryKey: ['pattern-test-journals', 'my'] });
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to update entry',
        color: 'red'
      });
    } finally {
      setIsEditing(false);
    }
  };

  // Handle delete entry
  const handleDeleteClick = (entryId: string) => {
    setEntryToDelete(entryId);
    openDeleteModal();
  };

  // Handle confirm delete
  const handleConfirmDelete = async () => {
    if (!journal || !entryToDelete) return;
    
    // First verify email
    if (!checkVerification()) {
      return;
    }
    
    setIsDeleting(true);
    try {
      // Get reCAPTCHA token
      const recaptchaToken = await verify('delete_journal_entry');
      
      await patternTestJournalsApi.deleteEntry(journal.id, entryToDelete, recaptchaToken);
      
      notifications.show({
        title: 'Success',
        message: 'Entry deleted successfully',
        color: 'green'
      });
      
      // Close modal and reset state
      closeDeleteModal();
      setEntryToDelete(null);
      
      // Invalidate queries to refresh data
      queryClient.invalidateQueries({ queryKey: ['pattern-test-journal', patternId] });
      queryClient.invalidateQueries({ queryKey: ['pattern-test-journals', 'my'] });
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to delete entry',
        color: 'red'
      });
    } finally {
      setIsDeleting(false);
    }
  };

  // Handle stopping test
  const handleStopTesting = async () => {
    if (!journal) return;
    
    // First verify email
    if (!checkVerification()) {
      return;
    }
    
    try {
      setIsStoppingTest(true);
      // Get reCAPTCHA token
      const recaptchaToken = await verify('delete_journal');
      await patternTestJournalsApi.deleteJournal(journal.id, recaptchaToken);
      
      // Invalidate the pattern detail query to refresh the UI
      queryClient.invalidateQueries({ queryKey: ['pattern', patternId] });
      
      // Redirect to the pattern page instead of my-account
      navigate(`/patterns/${patternId}`);
      
      notifications.show({
        title: 'Success',
        message: 'You have stopped testing this pattern',
        color: 'green'
      });
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to stop testing pattern',
        color: 'red'
      });
    } finally {
      setIsStoppingTest(false);
      closeStopTestingModal();
    }
  };

  const handleSubmitJournal = async () => {
    if (!journal) return;
    
    // First verify email
    if (!checkVerification()) {
      return;
    }
    
    try {
      // First check if there's unsaved content in the form fields
      if (comment.trim().length > 0) {
        notifications.show({
          title: 'Cannot Submit',
          message: 'You have unsaved text in the comment field. Please add the entry or clear it before submitting.',
          color: 'yellow'
        });
        return;
      }
      
      if (image !== null) {
        notifications.show({
          title: 'Cannot Submit',
          message: 'You have an image selected but not added. Please add the entry or clear it before submitting.',
          color: 'yellow'
        });
        return;
      }
      
      // Check if there's at least one journal entry
      if (!journal.entries || journal.entries.length === 0) {
        notifications.show({
          title: 'Cannot Submit',
          message: 'You must add at least one journal entry before submitting.',
          color: 'yellow'
        });
        return;
      }
      
      // Then ensure completion percentage is 100
      if (typeof completionPercentage === 'number' && completionPercentage < 100) {
        notifications.show({
          title: 'Cannot Submit',
          message: 'Completion percentage must be 100% to submit the journal',
          color: 'yellow'
        });
        return;
      }
      
      setIsSubmittingJournal(true);
      
      // Update completion to 100% if needed
      if (typeof completionPercentage !== 'number' || completionPercentage < 100) {
        const recaptchaToken = await verify('update_journal_completion');
        await patternTestJournalsApi.updateCompletionPercentage(patternId!, 100, recaptchaToken);
      }
      
      // Get reCAPTCHA token for submission
      const recaptchaToken = await verify('submit_journal');
      
      // Submit the journal
      await patternTestJournalsApi.submitJournal(journal.id, recaptchaToken);
      
      notifications.show({
        title: 'Journal Submitted',
        message: 'Your testing journal has been submitted for review',
        color: 'green'
      });
      
      // Refresh data
      queryClient.invalidateQueries({ queryKey: ['pattern-test-journal', patternId] });
      queryClient.invalidateQueries({ queryKey: ['pattern-test-journals', 'my'] });
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to submit journal',
        color: 'red'
      });
    } finally {
      setIsSubmittingJournal(false);
    }
  };

  const handleUnsubmitJournal = async () => {
    if (!journal) return;
    
    try {
      setIsSubmittingJournal(true);
      
      // Get reCAPTCHA token for unsubmission
      const recaptchaToken = await verify('unsubmit_journal');
      
      // Call the API to unsubmit the journal
      await patternTestJournalsApi.unsubmitJournal(journal.id, recaptchaToken);
      
      notifications.show({
        title: 'Journal Unsubmitted',
        message: 'Your testing journal has been returned to draft status',
        color: 'blue'
      });
      
      // Refresh queries to update UI
      queryClient.invalidateQueries({ queryKey: ['pattern-test-journal', patternId] });
      queryClient.invalidateQueries({ queryKey: ['pattern-test-journals', 'my'] });
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to unsubmit journal',
        color: 'red'
      });
    } finally {
      setIsSubmittingJournal(false);
    }
  };

  if (isLoading) {
    return (
      <Container size="md" py="xl">
        <LoadingOverlay visible={true} />
      </Container>
    );
  }

  // Sort entries by date (newest first)
  const sortedEntries = journal && 'entries' in journal && Array.isArray(journal.entries)
    ? [...journal.entries].sort((a, b) => 
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    : [];

  return (
    <>
      <Helmet>
        <title>Pattern Testing Journal - The Fiber Collective</title>
      </Helmet>

      <Container size="md" py="xl">
        {/* Add the VerificationAlert at the top of the content */}
        {VerificationAlert}
        
        <Paper shadow="md" p="xl" radius="lg" withBorder style={{ background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)' }}>
          <Stack gap="xs">
            <Group justify="space-between" align="flex-start" mb={0}>
              <Group>
                <Button
                  variant="subtle"
                  size="lg"
                  onClick={handleBack}
                  leftSection={<IconArrowLeft />}
                  style={{ marginBottom: 0, paddingBottom: 4 }}
                >
                  Back to Pattern
                </Button>
              </Group>
              
              {journal && journal.isSubmitted === true ? (
                <Group>
                  {journal.isRejected === true ? (
                    <Badge color="red" size="lg" variant="filled">Rejected</Badge>
                  ) : journal.isApproved === true ? (
                    <Badge color="teal" size="lg" variant="filled">Approved</Badge>
                  ) : (
                    <Badge color="green" size="lg" variant="filled">Submitted</Badge>
                  )}
                  {!journal.isApproved && !journal.isRejected && <Text fz="sm" fs="italic" c="dimmed">Waiting for Approval</Text>}
                </Group>
              ) : (
                <Button
                  color="red"
                  variant="light"
                  leftSection={<IconAlertTriangle size={16} />}
                  onClick={openStopTestingModal}
                >
                  Stop Testing
                </Button>
              )}
            </Group>

            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '10px',
              marginTop: 0,
              marginBottom: '15px'
            }}>
              <Title 
                order={1}
                style={{
                  background: 'linear-gradient(45deg, #228BE6, #40C057)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  letterSpacing: '0.3px',
                  margin: 0,
                  padding: 0,
                  lineHeight: 1.2
                }}
              >
                Testing Journal: {journal && 'patternName' in journal ? journal.patternName : 'Pattern'}
              </Title>
              
              <Group style={{ marginTop: 0, marginBottom: 0 }} justify={!(journal && journal.isSubmitted === true && (journal.isApproved || journal.isRejected)) ? "space-between" : "flex-end"} align="center">
                {/* Show the Submit button when journal is not submitted,
                    Show the Unsubmit button only when journal is submitted but not approved/rejected,
                    Hide the button completely when journal is approved or rejected */}
                {!(journal && journal.isSubmitted === true && (journal.isApproved || journal.isRejected)) && (
                  <Button 
                    variant={journal && journal.isSubmitted === true ? "outline" : "gradient"}
                    color={journal && journal.isSubmitted === true ? "blue" : undefined}
                    gradient={journal && journal.isSubmitted === true ? undefined : { from: 'indigo', to: 'cyan' }}
                    onClick={journal && journal.isSubmitted === true ? handleUnsubmitJournal : handleSubmitJournal}
                    loading={isSubmittingJournal}
                    size="md"
                  >
                    {journal && journal.isSubmitted === true ? "Unsubmit" : "Finish and Submit"}
                  </Button>
                )}
                
                <div style={{ marginLeft: journal && journal.isSubmitted === true && (journal.isApproved || journal.isRejected) ? '0' : 'auto' }}>
                  <Text fw={500} size="sm" mb={3}>% Complete:</Text>
                  <NumberInput
                    placeholder="0-100"
                    min={0}
                    max={100}
                    value={completionPercentage}
                    onChange={handlePercentageChange}
                    w={100}
                    hideControls
                    readOnly={journal?.isSubmitted === true}
                    disabled={journal?.isSubmitted === true}
                    rightSection={
                      <div style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        height: '100%', 
                        marginLeft: '-8px',
                        paddingBottom: '1px'
                      }}>
                        <span style={{ 
                          color: '#868e96',
                          fontSize: '14px',
                          lineHeight: 1
                        }}>
                          %
                        </span>
                      </div>
                    }
                    rightSectionWidth={20}
                    styles={{
                      input: {
                        textAlign: 'right',
                        paddingRight: '22px'
                      },
                      wrapper: {
                        width: '100%'
                      }
                    }}
                  />
                </div>
              </Group>
            </div>

            {!(journal && journal.isSubmitted === true) && (
              <Paper withBorder p="lg" radius="md" shadow="sm">
                <Stack gap="md">
                  <Title order={3}>Add New Entry</Title>
                  
                  <Textarea
                    label="Comment"
                    placeholder="Add your notes, feedback, or questions about this pattern..."
                    minRows={3}
                    value={comment}
                    onChange={(e) => setComment(e.currentTarget.value)}
                  />

                  <FileInput
                    label="Upload Image"
                    placeholder="Select image file"
                    accept="image/*"
                    value={image}
                    onChange={setImage}
                    leftSection={<IconUpload size={16} />}
                    ref={fileInputRef}
                  />

                  <Group justify="flex-end">
                    <Button 
                      variant="gradient" 
                      gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
                      onClick={handleSubmit}
                      loading={isSubmitting}
                    >
                      Add Entry
                    </Button>
                  </Group>
                </Stack>
              </Paper>
            )}

            <Divider label="Journal Entries" labelPosition="center" />

            {sortedEntries.length === 0 ? (
              <Text c="dimmed" ta="center" fs="italic">No entries yet. Add your first entry above.</Text>
            ) : (
              <Stack gap="lg">
                {sortedEntries.map((entry) => (
                  <Card key={entry.id} shadow="sm" p="lg" radius="md" withBorder>
                    {editingEntryId === entry.id ? (
                      // Edit mode
                      <Stack gap="md">
                        <Textarea
                          label="Edit Comment"
                          placeholder="Update your notes, feedback, or questions about this pattern..."
                          minRows={3}
                          value={editComment}
                          onChange={(e) => setEditComment(e.currentTarget.value)}
                        />
                        
                        <FileInput
                          label="Change Image (optional)"
                          placeholder="Select new image file"
                          accept="image/*"
                          value={editImage}
                          onChange={setEditImage}
                          leftSection={<IconUpload size={16} />}
                        />
                        
                        <Group justify="flex-end">
                          <Button variant="light" onClick={handleCancelEdit}>Cancel</Button>
                          <Button 
                            variant="gradient" 
                            gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
                            onClick={handleSaveEdit}
                            loading={isEditing}
                          >
                            Save Changes
                          </Button>
                        </Group>
                      </Stack>
                    ) : (
                      // View mode - Replace with JournalEntryCard
                      <JournalEntryCard 
                        entry={entry} 
                        canEdit={!(journal && journal.isSubmitted === true)}
                        onEdit={handleEditEntry}
                        onDelete={handleDeleteClick}
                      />
                    )}
                  </Card>
                ))}
              </Stack>
            )}
          </Stack>
        </Paper>
      </Container>
      
      {/* Confirmation modal for entry deletion */}
      <ConfirmDeleteModal
        opened={deleteModalOpened}
        onClose={closeDeleteModal}
        onConfirm={handleConfirmDelete}
        title="Delete Entry"
        message="Are you sure you want to delete this journal entry? This action cannot be undone."
        isLoading={isDeleting}
      />
      
      {/* Stop Testing Modal */}
      <StopTestingModal
        opened={stopTestingModalOpened}
        onClose={closeStopTestingModal}
        onConfirm={handleStopTesting}
        isLoading={isStoppingTest}
      />
    </>
  );
} 