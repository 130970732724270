import { apiClient } from './client';
import type { CrochetPattern } from '../types/api';
import { API_BASE_URL } from './config';
import { HeartResponse } from '../types/HeartResponse';
import { Comment } from '../types/Comment';
import { PatternCategory } from '../types/api';
import { getAuthHeaders } from '../utils/authHeaders';
import { handleApiError } from '../utils/apiUtils';
import { SortOption } from '../types/api';
import { ProjectType } from '../types/api';
import { queryClient } from '../lib/queryClient';

interface PatternFilterRequest {
  page: number;
  pageSize: number;
  difficulties?: string[];
  searchTerm?: string;
  categories?: PatternCategory[];
  hearted?: boolean;
  sortBy?: SortOption;
  ownedByUser?: boolean;
  viewedByUser?: boolean;
  projectType?: string;
}

export const patternsApi = {
  getAll: async (): Promise<CrochetPattern[]> => {
    try {
      console.log('Fetching all patterns');
      const { data } = await apiClient.get<CrochetPattern[]>('/patterns');
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  
  getById: async (id: string): Promise<CrochetPattern> => {
    try {
      let headers = {};
      try {
        headers = getAuthHeaders();
      } catch {
        // Continue without auth headers if not available
      }

      const { data } = await apiClient.get<CrochetPattern>(`/patterns/${id}`, { headers });
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  
  generateFromImage: async (image: File, hint?: string, targetDifficulty?: string | null, projectType?: ProjectType) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        throw new Error('No authentication token found');
    }

    // Get reCAPTCHA token
    const recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY!, {
      action: 'generate_pattern'
    });

    const formData = new FormData();
    formData.append('image', image);
    if (hint?.trim()) {
        formData.append('hint', hint.trim());
    }
    if (targetDifficulty) {
        formData.append('targetDifficulty', targetDifficulty);
    }
    if (projectType !== undefined) {
        formData.append('projectType', projectType.toString());
    }
    
    const url = new URL(`${API_BASE_URL}/api/patterns/generate`);
    if (hint?.trim()) {
        url.searchParams.append('hint', hint.trim());
    }
    if (targetDifficulty) {
        url.searchParams.append('targetDifficulty', targetDifficulty);
    }
    if (projectType !== undefined) {
        url.searchParams.append('projectType', projectType.toString());
    }
    
    return apiClient.post<CrochetPattern>(url.toString(), formData, {
      headers: { 
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        'X-Recaptcha-Token': recaptchaToken
      },
    }).then(res => {
      // Convert the imageUrl to full Cloudflare URL if needed
      if (res.data.imageUrl && !res.data.imageUrl.startsWith('http')) {
        res.data.imageUrl = `https://imagedelivery.net/ttFx7iDF7Oy_JTGDnnWSmA/${res.data.imageUrl}/public`;
      }
      // Invalidate auth query to refresh user data
      queryClient.invalidateQueries({ queryKey: ['auth', 'validate'] });
      return res.data;
    })
    .catch(error => {
      if (error.response?.data?.message) {
        throw error.response.data;
      }
      throw error;
    });
  },

  toggleHeart: async (patternId: string, recaptchaToken: string): Promise<HeartResponse> => {
    try {
      const { data } = await apiClient.post<HeartResponse>(
        `/heart/${patternId}/heart`,
        {},
        { 
          headers: {
            ...getAuthHeaders(),
            'X-Recaptcha-Token': recaptchaToken,
          }
        }
      );
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  addComment: async (patternId: string, text: string, recaptchaToken: string): Promise<Comment> => {
    try {
      const { data } = await apiClient.post<Comment>(
        `/comment/pattern/${patternId}`,
        { text },
        {
          headers: {
            ...getAuthHeaders(),
            'X-Recaptcha-Token': recaptchaToken,
          }
        }
      );
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getPaginated: async (request: PatternFilterRequest) => {
    const params = new URLSearchParams();
    params.append('page', request.page.toString());
    params.append('pageSize', request.pageSize.toString());

    if (request.searchTerm) {
      params.append('searchTerm', request.searchTerm);
    }
    if (request.difficulties?.length) {
      request.difficulties.forEach(d => params.append('difficulties', d));
    }
    if (request.categories?.length) {
      request.categories.forEach(c => params.append('categories', c.toString()));
    }
    if (request.sortBy) {
      params.append('sortBy', request.sortBy);
    }
    if (request.projectType !== undefined && request.projectType !== null) {
      params.append('projectType', request.projectType.toString());
    }

    try {
      let headers = {};
      try {
        headers = getAuthHeaders();
        // Only add user-specific filters if we have auth headers
        if (request.hearted) {
          params.append('hearted', 'true');
        }
        if (request.ownedByUser) {
          params.append('ownedByUser', 'true');
        }
        if (request.viewedByUser) {
          params.append('viewedByUser', 'true');
        }
      } catch {
        // If no auth token, skip user-specific filters
        console.log('No auth token available, skipping user-specific filters');
      }

      const { data } = await apiClient.get(`/patterns/summaries?${params}`, { headers });
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  update: async (id: string, pattern: CrochetPattern, imageFile?: File | null, recaptchaToken?: string): Promise<void> => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        throw new Error('No authentication token found');
    }

    // Get reCAPTCHA token if not provided
    const captchaToken = recaptchaToken || await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY!, {
      action: 'edit_pattern'
    });

    // If we have an image file, use FormData
    if (imageFile) {
        const formData = new FormData();
        
        // Convert pattern properties to form fields
        formData.append('Id', pattern.id);
        formData.append('OwnerId', pattern.ownerId);
        formData.append('Name', pattern.name);
        formData.append('Description', pattern.description);
        // Extract just the image ID if it exists
        if (pattern.imageUrl) {
            // Handle both full URLs and raw IDs
            const imageId = pattern.imageUrl.includes('/')
                ? pattern.imageUrl.split('/').pop()?.split('?')[0]
                : pattern.imageUrl;
            formData.append('ImageUrl', imageId || '');
        } else {
            formData.append('ImageUrl', '');
        }
        formData.append('DifficultyLevel', pattern.difficultyLevel);
        formData.append('Category', pattern.category.toString());
        formData.append('AccessLevel', pattern.accessLevel.toString());
        formData.append('ProjectType', pattern.projectType.toString());
        
        // Add isVerified field
        formData.append('IsVerified', pattern.isVerified ? 'true' : 'false');
        
        // Handle arrays and objects
        pattern.materialsNeeded?.forEach((material, index) => {
            formData.append(`MaterialsNeeded[${index}].Name`, material.name);
            formData.append(`MaterialsNeeded[${index}].Quantity`, material.quantity);
            formData.append(`MaterialsNeeded[${index}].Notes`, material.notes || '');
            formData.append(`MaterialsNeeded[${index}].PurchaseUrl`, material.purchaseUrl || '');
        });

        pattern.sections?.forEach((section, index) => {
            formData.append(`Sections[${index}].Name`, section.name);
            section.instructions.forEach((instruction, instrIndex) => {
                formData.append(`Sections[${index}].Instructions[${instrIndex}]`, instruction);
            });
        });

        pattern.assemblyInstructions?.forEach((instruction, index) => {
            formData.append(`AssemblyInstructions[${index}]`, instruction);
        });

        pattern.specialNotes?.forEach((note, index) => {
            formData.append(`SpecialNotes[${index}]`, note);
        });

        if (pattern.notes) {
            formData.append('Notes', pattern.notes);
        }

        formData.append('Image', imageFile);

        await apiClient.put(`/patterns/${id}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
                'X-Recaptcha-Token': captchaToken
            }
        });
    } else {
        // If no image file, use JSON
        const patternToSend = {
            ...pattern,
            imageUrl: pattern.imageUrl ? 
                (pattern.imageUrl.includes('/') 
                    ? pattern.imageUrl.split('/').pop()?.split('?')[0] 
                    : pattern.imageUrl)
                : ''
        };

        await apiClient.put(`/patterns/${id}`, patternToSend, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-Recaptcha-Token': captchaToken
            }
        });
    }
  },

  getPendingComments: async (patternId: string): Promise<Comment[]> => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        throw new Error('No authentication token found');
    }

    const response = await fetch(
      `${API_BASE_URL}/api/comment/pattern/${patternId}/pending`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    if (!response.ok) {
      if (response.status === 403) {
        throw new Error('Not authorized to view pending comments');
      }
      throw new Error('Failed to fetch pending comments');
    }

    return response.json();
  },

  approveComment: async (patternId: string, commentId: string): Promise<void> => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        throw new Error('No authentication token found');
    }

    const response = await fetch(
      `${API_BASE_URL}/api/comment/pattern/${patternId}/approve/${commentId}`,
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    if (!response.ok) {
      throw new Error('Failed to approve comment');
    }
  },

  deleteComment: async (patternId: string, commentId: string): Promise<void> => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        throw new Error('No authentication token found');
    }

    const response = await fetch(
        `${API_BASE_URL}/api/comment/pattern/${patternId}/${commentId}`,
        {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );

    if (!response.ok) {
        throw new Error('Failed to delete comment');
    }
  },

  editComment: async (patternId: string, commentId: string, text: string, recaptchaToken: string): Promise<void> => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        throw new Error('No authentication token found');
    }

    const response = await fetch(
        `${API_BASE_URL}/api/comment/pattern/${patternId}/${commentId}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-Recaptcha-Token': recaptchaToken
            },
            body: JSON.stringify({ text })
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update comment');
    }
  },

  rejectComment: async (patternId: string, commentId: string): Promise<void> => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        throw new Error('No authentication token found');
    }

    const response = await fetch(
        `${API_BASE_URL}/api/comment/pattern/${patternId}/reject/${commentId}`,
        {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );

    if (!response.ok) {
        throw new Error('Failed to reject comment');
    }
  },

  deletePattern: async (id: string): Promise<void> => {
    try {
      await apiClient.delete(
        `/patterns/${id}`,
        { headers: getAuthHeaders() }
      );
    } catch (error) {
      throw handleApiError(error);
    }
  },

  signupAsTester: async (patternId: string): Promise<void> => {
    try {
      await apiClient.post(
        `${API_BASE_URL}/api/pattern-test-journals/register-tester/${patternId}`,
        {},
        { headers: getAuthHeaders() }
      );
    } catch (error: any) {
      throw handleApiError(error);
    }
  },

  async generatePattern(imageFile: File): Promise<CrochetPattern> {
    try {
      const formData = new FormData();
      formData.append('image', imageFile);

      const response = await apiClient.post<CrochetPattern>(
        `${API_BASE_URL}/api/patterns/generate`,
        formData
      );

      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async getPattern(id: string): Promise<CrochetPattern> {
    try {
      const response = await apiClient.get<CrochetPattern>(
        `${API_BASE_URL}/api/patterns/${id}`
      );
      
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getSummaries: async (filters: PatternFilterRequest): Promise<CrochetPattern[]> => {
    const params = new URLSearchParams({
      page: filters.page.toString(),
      pageSize: filters.pageSize.toString(),
    });

    if (filters.difficulties?.length) {
      filters.difficulties.forEach(d => params.append('difficulties', d));
    }
    if (filters.categories?.length) {
      filters.categories.forEach(c => params.append('categories', c.toString()));
    }

    try {
      let headers = {};
      try {
        headers = getAuthHeaders();
      } catch {
        // Continue without auth headers if not available
      }

      const response = await fetch(
        `${API_BASE_URL}/api/patterns/summaries?${params}`,
        { headers }
      );
      if (!response.ok) throw new Error('Failed to fetch patterns');
      const patterns = await response.json();
      return patterns;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getAllPendingComments: async (): Promise<Comment[]> => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await fetch(
      `${API_BASE_URL}/api/comment/pending`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    if (!response.ok) {
      if (response.status === 403) {
        throw new Error('Not authorized to view pending comments');
      }
      throw new Error('Failed to fetch pending comments');
    }

    return response.json();
  },

  async duplicateAndRefine(id: string): Promise<CrochetPattern> {
    try {
      const headers = getAuthHeaders();
      const response = await apiClient.post<CrochetPattern>(
        `${API_BASE_URL}/api/patterns/${id}/duplicate-and-refine`,
        {},
        { headers }
      );
      
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
}; 