const isProd = process.env.NODE_ENV === 'production';
const DEV_API_URL = 'http://localhost:5000';

// Production URLs
const PROD_API_URL = 'https://api.thefibercollective.com';

// Cloudflare Images configuration
const CLOUDFLARE_ACCOUNT_ID = process.env.REACT_APP_CLOUDFLARE_ACCOUNT_ID;
const CLOUDFLARE_HASH = process.env.REACT_APP_CLOUDFLARE_HASH;

// Ensure trailing slash is consistent
export const API_BASE_URL = (isProd ? PROD_API_URL : DEV_API_URL).replace(/\/$/, '');
export const CF_ACCOUNT_ID = CLOUDFLARE_ACCOUNT_ID;
export const CF_HASH = CLOUDFLARE_HASH;

// Add API token
export const CF_API_TOKEN = process.env.REACT_APP_CLOUDFLARE_API_TOKEN;