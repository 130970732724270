import { Table, Paper, TextInput, LoadingOverlay, Text, Group, Badge, Button, Modal } from '@mantine/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState, useMemo } from 'react';
import { adminApi } from '../../api/admin';
import { AdminEvent } from '../../types/Events';
import { IconCheck, IconX, IconExternalLink, IconTrash } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { eventsApi } from '../../api/events';
import { formatDate } from '../../utils/dateUtils';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';

export function EventsTable() {
    const [search, setSearch] = useState('');
    const queryClient = useQueryClient();
    const [updatingEventId, setUpdatingEventId] = useState<string | null>(null);
    const [selectedEvent, setSelectedEvent] = useState<AdminEvent | null>(null);
    const [approvalModalOpened, { open: openApprovalModal, close: closeApprovalModal }] = useDisclosure(false);
    const [claimModalOpened, { open: openClaimModal, close: closeClaimModal }] = useDisclosure(false);
    const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);
    const navigate = useNavigate();

    const { data: events, isLoading } = useQuery({
        queryKey: ['admin', 'events'],
        queryFn: () => adminApi.getAllEventsNeedingAttention()
    });

    const filteredEvents = useMemo(() => {
        if (!events) return [];
        
        return events.filter((event: AdminEvent) => {
            const matchesSearch = search === '' || 
                event.title.toLowerCase().includes(search.toLowerCase()) ||
                (event.organizerName && event.organizerName.toLowerCase().includes(search.toLowerCase()));
            
            return matchesSearch;
        });
    }, [events, search]);

    const handleApproveClick = async () => {
        if (!selectedEvent) return;
        
        try {
            setUpdatingEventId(selectedEvent.id);
            await eventsApi.approve(selectedEvent.id);
            
            // Invalidate queries to refresh data
            queryClient.invalidateQueries({ queryKey: ['admin', 'events'] });
            queryClient.invalidateQueries({ queryKey: ['events'] });
            
            notifications.show({
                title: 'Event Approved',
                message: `Event "${selectedEvent.title}" has been approved.`,
                color: 'green'
            });
            
            closeApprovalModal();
        } catch (error) {
            notifications.show({
                title: 'Error',
                message: error instanceof Error ? error.message : 'Failed to approve event',
                color: 'red'
            });
        } finally {
            setUpdatingEventId(null);
        }
    };

    const handleApproveClaimClick = async () => {
        if (!selectedEvent) return;
        
        try {
            setUpdatingEventId(selectedEvent.id);
            await eventsApi.approveClaim(selectedEvent.id);
            
            // Invalidate queries to refresh data
            queryClient.invalidateQueries({ queryKey: ['admin', 'events'] });
            queryClient.invalidateQueries({ queryKey: ['events'] });
            
            notifications.show({
                title: 'Claim Approved',
                message: `Claim for event "${selectedEvent.title}" has been approved.`,
                color: 'green'
            });
            
            closeClaimModal();
        } catch (error) {
            notifications.show({
                title: 'Error',
                message: error instanceof Error ? error.message : 'Failed to approve claim',
                color: 'red'
            });
        } finally {
            setUpdatingEventId(null);
        }
    };

    const handleRejectClaimClick = async () => {
        if (!selectedEvent) return;
        
        try {
            setUpdatingEventId(selectedEvent.id);
            await eventsApi.rejectClaim(selectedEvent.id);
            
            // Invalidate queries to refresh data
            queryClient.invalidateQueries({ queryKey: ['admin', 'events'] });
            queryClient.invalidateQueries({ queryKey: ['events'] });
            
            notifications.show({
                title: 'Claim Rejected',
                message: `Claim for event "${selectedEvent.title}" has been rejected.`,
                color: 'yellow'
            });
            
            closeClaimModal();
        } catch (error) {
            notifications.show({
                title: 'Error',
                message: error instanceof Error ? error.message : 'Failed to reject claim',
                color: 'red'
            });
        } finally {
            setUpdatingEventId(null);
        }
    };
    
    const handleApprovalBadgeClick = (event: AdminEvent) => {
        setSelectedEvent(event);
        openApprovalModal();
    };
    
    const handleClaimBadgeClick = (event: AdminEvent) => {
        setSelectedEvent(event);
        openClaimModal();
    };
    
    const handleEventTitleClick = (eventId: string) => {
        navigate(`/events/${eventId}/edit`);
    };

    const handleDeleteClick = (event: AdminEvent) => {
        setSelectedEvent(event);
        openDeleteModal();
    };

    const handleConfirmDelete = async () => {
        if (!selectedEvent) return;
        
        try {
            setUpdatingEventId(selectedEvent.id);
            await eventsApi.delete(selectedEvent.id);
            
            // Invalidate queries to refresh data
            queryClient.invalidateQueries({ queryKey: ['admin', 'events'] });
            queryClient.invalidateQueries({ queryKey: ['events'] });
            
            notifications.show({
                title: 'Event Deleted',
                message: `Event "${selectedEvent.title}" has been deleted.`,
                color: 'green'
            });
            
            closeDeleteModal();
        } catch (error) {
            notifications.show({
                title: 'Error',
                message: error instanceof Error ? error.message : 'Failed to delete event',
                color: 'red'
            });
        } finally {
            setUpdatingEventId(null);
        }
    };

    return (
        <Paper shadow="sm" p="md" radius="md" withBorder>
            {/* Approval Modal */}
            <Modal
                opened={approvalModalOpened}
                onClose={closeApprovalModal}
                title={<Text fw={700} size="lg">Event Approval</Text>}
                centered
            >
                {selectedEvent && (
                    <div>
                        <Text fw={500} size="lg" mb="xs">{selectedEvent.title}</Text>
                        
                        {selectedEvent.organizerName && (
                            <Text size="sm" mb="md">Organizer: {selectedEvent.organizerName}</Text>
                        )}
                        
                        <Text mb="lg">Do you want to approve this event?</Text>
                        
                        <Group justify="flex-end" mt="xl">
                            <Button 
                                variant="outline" 
                                color="gray" 
                                onClick={closeApprovalModal}
                            >
                                Cancel
                            </Button>
                            <Button 
                                variant="filled" 
                                color="green" 
                                onClick={handleApproveClick}
                                loading={updatingEventId === selectedEvent.id}
                                leftSection={<IconCheck size={16} />}
                            >
                                Approve
                            </Button>
                        </Group>
                    </div>
                )}
            </Modal>
            
            {/* Claim Modal */}
            <Modal
                opened={claimModalOpened}
                onClose={closeClaimModal}
                title={<Text fw={700} size="lg">Event Claim Request</Text>}
                centered
            >
                {selectedEvent && (
                    <div>
                        <Text fw={500} size="lg" mb="xs">{selectedEvent.title}</Text>
                        
                        {selectedEvent.organizerName && (
                            <Text size="sm" mb="md">Organizer: {selectedEvent.organizerName}</Text>
                        )}
                        
                        <Text mb="md">Claim requested by:</Text>
                        
                        {selectedEvent.claimantFirstName && selectedEvent.claimantLastName && (
                            <Text fw={500}>
                                {selectedEvent.claimantFirstName} {selectedEvent.claimantLastName}
                            </Text>
                        )}
                        
                        {selectedEvent.claimantDisplayName && (
                            <Text size="sm">
                                @{selectedEvent.claimantDisplayName}
                            </Text>
                        )}
                        
                        {selectedEvent.claimantEmail && (
                            <Text size="sm" mb="lg">
                                {selectedEvent.claimantEmail}
                            </Text>
                        )}
                        
                        <Text mt="md" mb="lg">How do you want to handle this claim request?</Text>
                        
                        <Group justify="flex-end" mt="xl">
                            <Button 
                                variant="outline" 
                                color="gray" 
                                onClick={closeClaimModal}
                            >
                                Cancel
                            </Button>
                            <Button 
                                variant="filled" 
                                color="red" 
                                onClick={handleRejectClaimClick}
                                loading={updatingEventId === selectedEvent.id}
                                leftSection={<IconX size={16} />}
                            >
                                Reject Claim
                            </Button>
                            <Button 
                                variant="filled" 
                                color="green" 
                                onClick={handleApproveClaimClick}
                                loading={updatingEventId === selectedEvent.id}
                                leftSection={<IconCheck size={16} />}
                            >
                                Approve Claim
                            </Button>
                        </Group>
                    </div>
                )}
            </Modal>
            
            {/* Delete Modal */}
            <Modal
                opened={deleteModalOpened}
                onClose={closeDeleteModal}
                title={<Text fw={700} size="lg">Delete Event</Text>}
                centered
            >
                {selectedEvent && (
                    <div>
                        <Text fw={500} size="lg" mb="xs">{selectedEvent.title}</Text>
                        
                        <Text mb="md" color="red">
                            Are you sure you want to delete this event? This action cannot be undone.
                        </Text>
                        
                        <Group justify="flex-end" mt="xl">
                            <Button 
                                variant="outline" 
                                color="gray" 
                                onClick={closeDeleteModal}
                            >
                                Cancel
                            </Button>
                            <Button 
                                variant="filled" 
                                color="red" 
                                onClick={handleConfirmDelete}
                                loading={updatingEventId === selectedEvent.id}
                                leftSection={<IconTrash size={16} />}
                            >
                                Delete
                            </Button>
                        </Group>
                    </div>
                )}
            </Modal>
            
            <TextInput
                placeholder="Search by event title or organizer..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                mb="md"
            />
            
            {isLoading ? (
                <div style={{ position: 'relative', height: '200px' }}>
                    <LoadingOverlay visible={true} />
                </div>
            ) : (
                <>
                    <Text size="sm" fw={500} mb="xs">
                        {filteredEvents.length} events need attention
                    </Text>
                    
                    <Table striped highlightOnHover style={{ tableLayout: 'fixed' }}>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>Title</Table.Th>
                                <Table.Th>Status</Table.Th>
                                <Table.Th>Claimant</Table.Th>
                                <Table.Th>Date</Table.Th>
                                <Table.Th>External URL</Table.Th>
                                <Table.Th style={{ width: '80px' }}>Actions</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {filteredEvents.length === 0 ? (
                                <Table.Tr>
                                    <Table.Td colSpan={6} style={{ textAlign: 'center' }}>
                                        No events found that need attention
                                    </Table.Td>
                                </Table.Tr>
                            ) : (
                                filteredEvents.map((event: AdminEvent) => (
                                    <Table.Tr 
                                        key={event.id}
                                    >
                                        <Table.Td>
                                            <Text 
                                                fw={500}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleEventTitleClick(event.id)}
                                                c="blue"
                                                td="underline"
                                            >
                                                {event.title}
                                            </Text>
                                            {event.organizerName && (
                                                <Text size="xs" c="dimmed">Organizer: {event.organizerName}</Text>
                                            )}
                                        </Table.Td>
                                        <Table.Td>
                                            <Group gap={5}>
                                                {!event.isApproved && (
                                                    <Badge 
                                                        color="yellow"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleApprovalBadgeClick(event)}
                                                    >
                                                        Pending Approval
                                                    </Badge>
                                                )}
                                                {event.isClaimPending && (
                                                    <Badge 
                                                        color="blue"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleClaimBadgeClick(event)}
                                                    >
                                                        Claim Pending
                                                    </Badge>
                                                )}
                                            </Group>
                                        </Table.Td>
                                        <Table.Td>
                                            {event.claimantId ? (
                                                <div>
                                                    {event.claimantFirstName && event.claimantLastName && (
                                                        <Text size="sm" fw={500}>
                                                            {event.claimantFirstName} {event.claimantLastName}
                                                        </Text>
                                                    )}
                                                    {event.claimantDisplayName && (
                                                        <Text size="xs" c="dimmed">
                                                            @{event.claimantDisplayName}
                                                        </Text>
                                                    )}
                                                    {event.claimantEmail && (
                                                        <Text size="xs" c="dimmed" style={{ wordBreak: 'break-all' }}>
                                                            {event.claimantEmail}
                                                        </Text>
                                                    )}
                                                </div>
                                            ) : (
                                                <Text size="xs" c="dimmed">None</Text>
                                            )}
                                        </Table.Td>
                                        <Table.Td>
                                            <Text>{formatDate(event.startDate, false)}</Text>
                                            {event.endDate && (
                                                <Text size="xs" c="dimmed">to {formatDate(event.endDate, false)}</Text>
                                            )}
                                            {event.isRecurring && (
                                                <Badge size="xs" variant="light" color="blue">
                                                    {event.recurrenceFrequency || 'Recurring'}
                                                </Badge>
                                            )}
                                        </Table.Td>
                                        <Table.Td>
                                            {event.eventUrl ? (
                                                <Button
                                                    component="a"
                                                    href={event.eventUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    size="xs"
                                                    variant="light"
                                                    leftSection={<IconExternalLink size={16} />}
                                                >
                                                    Visit
                                                </Button>
                                            ) : (
                                                <Text size="xs" c="dimmed">None</Text>
                                            )}
                                        </Table.Td>
                                        <Table.Td>
                                            <Button
                                                color="red"
                                                size="xs"
                                                variant="subtle"
                                                onClick={() => handleDeleteClick(event)}
                                                p={6}
                                            >
                                                <IconTrash size={16} />
                                            </Button>
                                        </Table.Td>
                                    </Table.Tr>
                                ))
                            )}
                        </Table.Tbody>
                    </Table>
                </>
            )}
        </Paper>
    );
} 