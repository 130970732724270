import { PatternCategory } from '../types/api';

export const CATEGORY_OPTIONS = [
  { value: '0', label: 'Apparel' },
  { value: '1', label: 'Accessories' },
  { value: '2', label: 'Toys' },
  { value: '3', label: 'Granny Squares' },
  { value: '4', label: 'Home Decor' },
  { value: '5', label: 'Other' }
] as const;

export const DIFFICULTY_OPTIONS = [
  { value: 'Beginner', label: 'Beginner' },
  { value: 'Intermediate', label: 'Intermediate' },
  { value: 'Advanced', label: 'Advanced' },
  { value: 'Expert', label: 'Expert' }
] as const;

export const getCategoryDisplayName = (category: PatternCategory): string => {
  const found = CATEGORY_OPTIONS.find(opt => opt.value === category.toString());
  return found?.label ?? 'Other';
};

export const getDifficultyColor = (level: string): string => {
  const colors: Record<string, string> = {
    beginner: 'green',
    intermediate: 'yellow',
    advanced: 'orange',
    expert: 'red'
  };
  return colors[level.toLowerCase()] ?? 'gray';
}; 