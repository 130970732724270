/**
 * Utility functions for handling date and time formatting without timezone conversions
 */

/**
 * Extracts only year, month, day from any date format
 * Returns a Date object at noon UTC to avoid timezone issues
 * 
 * @param date - Date object or date string
 * @returns Date object with only year, month, day components
 */
export function extractDateOnly(date: Date | string | null | undefined): Date | null {
  if (!date) return null;
  
  // Parse the date
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  
  // Extract only date components - no timezone or time concerns
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const day = dateObj.getDate();
  
  // Create new UTC date with noon time to avoid timezone crossing
  return new Date(Date.UTC(year, month, day, 12, 0, 0, 0));
}

/**
 * Converts a Date to ISO string with noon UTC time
 * 
 * @param date - Date object
 * @returns ISO string with noon UTC time
 */
export function dateToISOString(date: Date | null | undefined): string | undefined {
  if (!date) return undefined;
  return date.toISOString();
}

/**
 * Creates a standardized date string for consistent date handling
 * Ignores time component and timezone, only maintains year, month, day
 * Sets time to noon UTC to avoid timezone issues
 * 
 * @param date - Date object or date string
 * @returns ISO date string with time set to noon UTC
 */
export function createDateOnly(date: Date | string | null | undefined): string {
  if (!date) return new Date().toISOString();
  
  const standardDate = extractDateOnly(date);
  return standardDate ? standardDate.toISOString() : new Date().toISOString();
}

/**
 * Creates a Date object from a UTC date string without timezone conversion
 * This ensures the date is displayed exactly as stored in the database
 * 
 * @param utcDate - UTC date string from the API
 * @returns Date object without timezone adjustment
 */
export function utcToLocal(utcDate: string | null | undefined): Date | undefined {
  if (!utcDate) return undefined;
  
  // Parse the date string and create a new Date object
  // Using this approach to avoid automatic timezone conversion
  const parts = new Date(utcDate).toISOString().split('T')[0].split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are 0-indexed in JavaScript
  const day = parseInt(parts[2], 10);
  
  return new Date(Date.UTC(year, month, day, 0, 0, 0));
}

/**
 * Ensures a date object includes timezone information
 * Use this when sending dates to the API
 * 
 * @param localDate - Date object
 * @returns Date object with timezone information preserved
 */
export function localToUTC(localDate: Date | null | undefined): string | undefined {
  if (!localDate) return undefined;
  
  // Creates an ISO string that includes timezone information
  return localDate.toISOString();
}

/**
 * Formats a date for display to users without timezone conversion
 * 
 * @param date - Date object or ISO string
 * @param includeTime - Whether to include time in the formatted string
 * @returns Formatted date string without timezone adjustments
 */
export function formatDate(date: Date | string | null | undefined, includeTime = false): string {
  if (!date) return '';
  
  // If it's a string, create a Date object without timezone conversion  
  const dateObj = typeof date === 'string' ? extractDateOnly(date) : date;
  if (!dateObj) return '';
  
  // Format options that don't include timezone
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  
  if (includeTime) {
    options.hour = '2-digit';
    options.minute = '2-digit';
    // Force UTC timezone to prevent conversion
    options.timeZone = 'UTC';
  }
  
  // Use toLocaleDateString with UTC timezone to prevent conversion
  return dateObj.toLocaleDateString('en-US', {
    ...options,
    timeZone: 'UTC'
  });
}

/**
 * Formats a date string as a short date without time and without timezone conversion
 * @param dateString - The ISO date string to format
 * @returns Formatted short date string (e.g., "Jan 15, 2024")
 */
export function formatShortDate(dateString: string): string {
  const date = extractDateOnly(dateString);
  if (!date) return '';
  
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC'
  });
}

/**
 * Returns a relative time string (e.g., "2 hours ago", "yesterday", "3 days ago")
 * @param dateString - The ISO date string to format
 * @returns Relative time string
 */
export function getRelativeTimeString(dateString: string): string {
  // Create a Date object without timezone conversion
  const date = extractDateOnly(dateString);
  if (!date) return '';
  
  // Also create a "now" in UTC to compare without timezone differences
  const now = new Date();
  const utcNow = new Date(Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  ));
  
  const diffInSeconds = Math.floor((utcNow.getTime() - date.getTime()) / 1000);
  
  if (diffInSeconds < 60) {
    return 'just now';
  }
  
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
  }
  
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
  }
  
  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays === 1) {
    return 'yesterday';
  }
  
  if (diffInDays < 30) {
    return `${diffInDays} days ago`;
  }
  
  // For older dates, return the formatted date
  return formatShortDate(dateString);
} 