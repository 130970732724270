import { useEffect, useState, useMemo, useCallback } from 'react';
import { Container, Group, Center, Loader, Button, Title, Box, Alert, Modal, Text, Stack, Paper, TextInput, Select, MultiSelect, SegmentedControl, Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { eventsApi } from '../api/events';
import { EventCard } from '../components/EventCard';
import { TagFilter } from '../components/TagFilter';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { ApiError } from '../utils/apiUtils';
import { IconAlertCircle, IconX, IconSearch, IconRefresh } from '@tabler/icons-react';
import { gradientText, contentBox } from '../styles/common';
import { DatePicker, MonthPicker } from '@mantine/dates';
import { useFilters } from '../hooks/useFilters';
import { useInfinitePagination } from '../hooks/useInfinitePagination';
import { EventPaginationParams, LocationPaginatedResult } from '../types/common';
import { EventSummary } from '../types/Events';
import { invalidateEventQueries } from '../utils/eventCache';
import InArticleAd from '../components/AdSense/InArticleAd';

// Define our filter type
type EventFilterState = {
  searchTerm: string;
  states: string[];
  country: string;
  date: Date | undefined;
  dateFilterType: 'day' | 'month' | 'year';
  isVirtual: boolean | undefined;
  tag: string | undefined;
  showPastEvents: boolean;
  isApproved: boolean | undefined;
};

// Define filter key type
type EventFilterKey = keyof EventFilterState;

// Event Card with Ads component
interface EventCardWithAdsProps {
  event: EventSummary;
  index: number;
  onApprove?: (id: string) => void;
  onDelete?: (id: string) => void;
  isLastEvent: boolean;
  totalEvents: number;
  searchTerm?: string;
}

// Component for duplicate event action buttons
interface DuplicateEventActionsProps {
  event: EventSummary;
}

function DuplicateEventActions({ event }: DuplicateEventActionsProps) {
  return (
    <Group gap="xs">
      <Button 
        onClick={() => {
          // Open a new window with the search term
          const apiUrl = `${window.location.origin}/events`;
          
          // Create a form and submit it to properly set the search term
          const form = document.createElement('form');
          form.method = 'get';
          form.action = apiUrl;
          form.target = '_blank';
          
          // Add search term input
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = 'searchTerm';
          input.value = event.title;
          form.appendChild(input);
          
          // Add to DOM, submit, and remove
          document.body.appendChild(form);
          form.submit();
          document.body.removeChild(form);
        }}
        size="xs" 
        variant="light"
      >
        View
      </Button>
      <Button 
        component="a" 
        href={`${window.location.origin}/events/${event.id}/edit`}
        size="xs" 
        variant="light"
        target="_blank"
        rel="noopener noreferrer"
      >
        Edit
      </Button>
    </Group>
  );
}

// Component for duplicate events section
interface DuplicateEventSectionProps {
  title: string;
  events: EventSummary[];
  showEventUrl?: boolean;
}

function DuplicateEventSection({ title, events, showEventUrl = false }: DuplicateEventSectionProps) {
  return (
    <Box mt={showEventUrl ? "xl" : undefined}>
      <Title order={3} mb="md">{title}</Title>
      {events.length > 0 ? (
        events.map(event => (
          <Paper key={event.id} p="sm" mb="sm" withBorder>
            <Group justify="space-between" wrap="nowrap">
              {showEventUrl ? (
                <Stack gap={4}>
                  <Text>{event.title}</Text>
                  <Text size="xs" color="dimmed">{event.eventUrl}</Text>
                </Stack>
              ) : (
                <Text>{event.title}</Text>
              )}
              <DuplicateEventActions event={event} />
            </Group>
          </Paper>
        ))
      ) : (
        <Text>No {title.toLowerCase()} found</Text>
      )}
    </Box>
  );
}

function EventCardWithAds({ 
  event, 
  index, 
  onApprove, 
  onDelete, 
  isLastEvent, 
  totalEvents,
  searchTerm 
}: EventCardWithAdsProps) {
  const { user } = useAuth();
  const isSubscriber = user?.role === 'Subscriber';
  
  // Show ad after every 5th card (0-indexed, so this is actually the 5th, 10th, etc.)
  const showAd = !isSubscriber && ((index + 1) % 5 === 0);

  return (
    <>
      <EventCard 
        event={event} 
        onApprove={onApprove} 
        onDelete={onDelete}
        searchTerm={searchTerm}
      />
      {showAd && <InArticleAd index={index} />}
    </>
  );
}

export function EventsListPage() {
  const pageSize = 9;
  const { user, isEmailVerified } = useAuth();
  const isAdmin = user?.role === 'Admin';
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const selectedTag = searchParams.get('tag');
  const navigate = useNavigate();
  const [showVerificationAlert, setShowVerificationAlert] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<string | null>(null);
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);
  
  // Add state for duplicate finder modal
  const [duplicateFinderModalOpened, { open: openDuplicateFinderModal, close: closeDuplicateFinderModal }] = useDisclosure(false);
  const [duplicateEvents, setDuplicateEvents] = useState<{ titleDuplicates: EventSummary[], urlDuplicates: EventSummary[] }>({ 
    titleDuplicates: [], 
    urlDuplicates: [] 
  });
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  
  // Use our custom filter hook
  const { 
    filters, 
    handleFilterChange: updateFilter, 
    clearFilters: resetFilters,
    updateFilters
  } = useFilters<EventFilterState, EventFilterKey>({
    searchTerm: '',
    states: [],
    country: '',
    date: undefined,
    dateFilterType: 'day',
    isVirtual: undefined,
    tag: selectedTag || undefined,
    showPastEvents: false,
    isApproved: undefined
  });
  
  // Add a state for the actual search term used in queries
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState('');
  
  // Read search parameters from URL when component mounts
  useEffect(() => {
    const urlSearchTerm = searchParams.get('searchTerm');
    if (urlSearchTerm) {
      // Update both the filter and the submitted search term
      updateFilter('searchTerm', urlSearchTerm);
      setSubmittedSearchTerm(urlSearchTerm);
    }
  }, [searchParams, updateFilter]);
  
  // Get tags
  const { data: tags = [] } = useQuery({
    queryKey: ['event-tags'],
    queryFn: () => eventsApi.getTags(),
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  // Type-safe adapter for our filter changes
  const handleFilterChange = useCallback((
    type: EventFilterKey,
    value: string | string[] | Date | boolean | undefined
  ) => {
    if (type === 'country' && typeof value === 'string') {
      // Update country without resetting states
      updateFilter(type, value);
    } else if (type === 'dateFilterType' && typeof value === 'string') {
      const newType = value as 'day' | 'month' | 'year';
      const prevType = filters.dateFilterType;
      
      // Always reset date when switching filter types
      if (newType !== prevType) {
        // Set appropriate default date based on the new filter type
        let defaultDate: Date | undefined = undefined;
        
        if (newType === 'year') {
          defaultDate = new Date();
          defaultDate.setMonth(0);
          defaultDate.setDate(1);
          defaultDate.setHours(0, 0, 0, 0);
        } else if (newType === 'month') {
          defaultDate = new Date();
          defaultDate.setDate(1);
          defaultDate.setHours(0, 0, 0, 0);
        }
        
        updateFilters({
          dateFilterType: newType,
          date: defaultDate
        });
      }
    } else {
      updateFilter(type, value as any);
    }
  }, [updateFilter, updateFilters, filters.dateFilterType]);
  
  // Handle date change specifically to properly handle null values
  const handleDateChange = (value: Date | null) => {
    updateFilter('date', value || undefined as any);
    if (value === null) {
      closeDatePicker();
    }
  };
  
  // Handle month select
  const handleMonthSelect = (value: Date | null) => {
    updateFilter('date', value || undefined as any);
    if (value) {
      closeDatePicker();
    }
  };
  
  // Handle year select
  const handleYearSelect = (value: Date | null) => {
    updateFilter('date', value || undefined as any);
    if (value) {
      closeDatePicker();
    }
  };
  
  // Format the date for display based on the selected filter type
  const formatDateDisplay = (date: Date): string => {
    if (!date) return 'Select date';
    
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'UTC' // Force UTC timezone to prevent conversion
    };
    
    switch (filters.dateFilterType) {
      case 'day':
        return date.toLocaleDateString('en-US', { 
          ...options,
          month: 'short', 
          day: 'numeric', 
          year: 'numeric' 
        });
      case 'month': {
        return date.toLocaleDateString('en-US', {
          ...options,
          year: 'numeric', 
          month: 'long'
        });
      }
      case 'year':
        return `${date.getUTCFullYear()}`;
      default:
        return date.toLocaleDateString('en-US', { 
          ...options,
          month: 'short', 
          day: 'numeric', 
          year: 'numeric' 
        });
    }
  };
  
  // Format date for API requests based on filter type
  const getFormattedDate = (date: Date | undefined): string | undefined => {
    if (!date) return undefined;
    
    switch (filters.dateFilterType) {
      case 'day':
        // Format as MM/DD/YYYY for day precision (e.g., 4/12/2025)
        return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
      case 'month':
        // Format as MM/YYYY for month precision (e.g., 3/2025)
        return `${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
      case 'year':
        // Format as YYYY for year precision (e.g., 2025)
        return `${date.getUTCFullYear()}`;
      default:
        return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
    }
  };
  
  // Generate a key for the date input component to force re-render when value changes
  const dateInputKey = `date-input-${filters.dateFilterType}-${filters.date?.toString() || 'empty'}`;
  
  // Control the date picker popover visibility
  const [datePickerOpened, { close: closeDatePicker, toggle: toggleDatePicker }] = useDisclosure(false);
  
  // Clear all filters
  const clearFilters = () => {
    // Check if there are any active filters or search term
    const hasActiveFilters = 
      !!filters.searchTerm || 
      !!submittedSearchTerm ||
      filters.states.length > 0 || 
      !!filters.country || 
      !!filters.date || 
      filters.isVirtual !== undefined || 
      !!filters.tag || 
      filters.showPastEvents || 
      filters.isApproved !== undefined;
      
    // Reset filters state
    resetFilters();
    // Reset the submitted search term
    setSubmittedSearchTerm('');
    // Remove tag from URL
    navigate('/events');
    
    // If there were active filters, invalidate events queries to force a refresh
    if (hasActiveFilters) {
      invalidateEventQueries(queryClient);
    }
  };
  
  // Use our custom infinite pagination hook
  const {
    data,
    items: eventsList,
    hasNextPage,
    isLoading,
    ref: intersectionRef
  } = useInfinitePagination<EventSummary, EventPaginationParams>({
    queryKey: ['events', { ...filters, searchTerm: submittedSearchTerm }],
    fetchFn: eventsApi.getPaginated,
    baseParams: {
      page: 1,
      pageSize,
      tag: filters.tag,
      states: filters.states.length > 0 ? filters.states : undefined,
      country: filters.country || undefined,
      isVirtual: filters.isVirtual,
      date: getFormattedDate(filters.date),
      showPastEvents: filters.showPastEvents,
      isApproved: filters.isApproved,
      searchTerm: submittedSearchTerm
    },
    pageSize
  });
  
  // Get all available countries and states from the response
  const allCountries = useMemo(() => {
    if (!data?.pages) return [];
    // Deduplicate and sort countries from all pages
    const countriesSet = new Set<string>();
    data.pages.forEach(page => {
      if ('countries' in page) {
        const locationPage = page as LocationPaginatedResult<EventSummary>;
        if (locationPage.countries) {
          locationPage.countries.forEach((country: string) => countriesSet.add(country));
        }
      }
    });
    return Array.from(countriesSet).sort();
  }, [data?.pages]);
  
  // Get states for the selected country
  const availableStates = useMemo(() => {
    if (!data?.pages) return [];
    // Deduplicate and sort states from all pages
    const statesSet = new Set<string>();
    data.pages.forEach(page => {
      if ('states' in page) {
        const locationPage = page as LocationPaginatedResult<EventSummary>;
        if (locationPage.states) {
          locationPage.states.forEach((state: string) => statesSet.add(state));
        }
      }
    });
    return Array.from(statesSet).sort();
  }, [data?.pages]);

  // Handle tag selection from URL
  useEffect(() => {
    if (selectedTag !== filters.tag) {
      handleFilterChange('tag', selectedTag || undefined);
    }
  }, [selectedTag, filters.tag, handleFilterChange]);

  // Remove client-side filtering by search term
  const events = eventsList;

  // Approve event mutation
  const { mutate: approveEvent } = useMutation({
    mutationFn: (eventId: string) => eventsApi.approve(eventId),
    onSuccess: (_, eventId) => {
      notifications.show({
        title: 'Success',
        message: 'Event has been approved',
        color: 'green'
      });
      invalidateEventQueries(queryClient, eventId);
    },
    onError: (error: ApiError) => {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to approve event',
        color: 'red'
      });
    }
  });

  // Delete event mutation
  const { mutate: deleteEvent } = useMutation({
    mutationFn: (eventId: string) => eventsApi.delete(eventId),
    onSuccess: (_, eventId) => {
      notifications.show({
        title: 'Success',
        message: 'Event has been deleted',
        color: 'green'
      });
      invalidateEventQueries(queryClient, eventId);
      closeDeleteModal();
    },
    onError: (error: ApiError) => {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to delete event',
        color: 'red'
      });
      closeDeleteModal();
    }
  });

  // Handle approve/reject actions
  const handleApprove = (id: string) => {
    approveEvent(id);
  };

  // Handle delete actions
  const handleDelete = (id: string) => {
    setEventToDelete(id);
    openDeleteModal();
  };

  const confirmDelete = () => {
    if (eventToDelete) {
      deleteEvent(eventToDelete);
    }
  };

  const handleAddNewEvent = () => {
    // If user is admin or has verified email, go to create page
    if (isAdmin || isEmailVerified) {
      navigate('/events/create');
    } else {
      // Show alert for email verification
      setShowVerificationAlert(true);
      
      // Show notification
      notifications.show({
        title: 'Email verification required',
        message: 'You need to verify your email before creating events. Redirecting to profile page...',
        color: 'yellow',
        icon: <IconAlertCircle size={16} />,
      });
      
      // Redirect to profile page after a short delay
      setTimeout(() => {
        navigate('/profile');
      }, 2000);
    }
  };

  const refreshEvents = () => {
    notifications.show({
      title: 'Refreshing Events',
      message: 'Updating event data from the server...',
      loading: true,
      autoClose: false,
      id: 'refresh-notification'
    });
    
    invalidateEventQueries(queryClient);
    
    // Set timeout to allow query invalidation to complete
    setTimeout(() => {
      notifications.update({
        id: 'refresh-notification',
        title: 'Refresh Complete',
        message: 'Event data has been updated',
        color: 'green',
        loading: false,
        autoClose: 2000
      });
    }, 1000);
  };

  // Add a function to handle duplicate finder
  const handleFindDuplicates = async () => {
    setIsDuplicateLoading(true);
    try {
      const duplicates = await eventsApi.findDuplicates();
      setDuplicateEvents(duplicates);
      openDuplicateFinderModal();
    } catch (error) {
      notifications.show({
        title: 'Error finding duplicates',
        message: 'There was an error finding duplicate events. Please try again.',
        color: 'red',
      });
    } finally {
      setIsDuplicateLoading(false);
    }
  };

  const [isTagsLoading, setIsTagsLoading] = useState(false);
  
  // Add handler for cleaning up tags
  const handleCleanupTags = async () => {
    try {
      setIsTagsLoading(true);
      const result = await eventsApi.cleanupTags();
      notifications.show({
        title: 'Tags Cleaned Up',
        message: result.message,
        color: 'green',
      });
      
      // If any tags were updated, refresh the events list
      if (result.updatedCount > 0) {
        refreshEvents();
      }
    } catch (error) {
      const apiError = error as ApiError;
      notifications.show({
        title: 'Error Cleaning Up Tags',
        message: apiError.message || 'An unexpected error occurred',
        color: 'red',
      });
    } finally {
      setIsTagsLoading(false);
    }
  };

  // Handler for search form submission
  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmittedSearchTerm(filters.searchTerm);
  };

  return (
    <>
      <Helmet>
        <title>Fiber Collective | Events</title>
        <meta name="description" content="Browse crochet events, meetups, and workshops around the world" />
      </Helmet>

      {/* Delete Confirmation Modal */}
      <Modal 
        opened={deleteModalOpened} 
        onClose={closeDeleteModal}
        title={
          <Text 
            size="lg" 
            fw={600}
            style={gradientText}
          >
            Delete Event
          </Text>
        }
        centered
      >
        <Stack>
          <Text>Are you sure you want to delete this event? This action cannot be undone.</Text>
          <Group mt="md" justify="flex-end">
            <Button variant="light" onClick={closeDeleteModal}>Cancel</Button>
            <Button color="red" onClick={confirmDelete}>Delete</Button>
          </Group>
        </Stack>
      </Modal>

      {/* Duplicate Finder Modal */}
      <Modal
        opened={duplicateFinderModalOpened}
        onClose={closeDuplicateFinderModal}
        title={
          <Text
            size="lg"
            fw={600}
            style={gradientText}
          >
            Duplicate Events
          </Text>
        }
        centered
        size="xl"
      >
        <Stack>
          <DuplicateEventSection title="Events with Duplicate Titles" events={duplicateEvents.titleDuplicates} />
          <DuplicateEventSection title="Events with Duplicate URLs" events={duplicateEvents.urlDuplicates} showEventUrl />
        </Stack>
      </Modal>

      <Container size="lg" py="xl">
        <Group justify="space-between" mb="md">
          <Group>
            <Title 
              order={2}
              style={gradientText}
            >
              {filters.showPastEvents ? 'All Events' : 'Upcoming Events'}
            </Title>
            {isAdmin && (
              <>
                <Button
                  leftSection={<IconRefresh size={16} />}
                  variant="subtle"
                  onClick={refreshEvents}
                  ml="xs"
                  size="sm"
                >
                  Refresh
                </Button>
                <Button
                  variant="subtle"
                  onClick={handleFindDuplicates}
                  ml="xs"
                  size="sm"
                  loading={isDuplicateLoading}
                >
                  Duplicate Finder
                </Button>
                <Button
                  variant="subtle"
                  onClick={handleCleanupTags}
                  ml="xs"
                  size="sm"
                  loading={isTagsLoading}
                >
                  Fix Tags
                </Button>
                <Button
                  variant={filters.isApproved === false ? "filled" : "light"}
                  onClick={() => handleFilterChange('isApproved', filters.isApproved === false ? undefined : false)}
                  ml="xs"
                  size="sm"
                  color="blue"
                >
                  Pending Approval
                </Button>
              </>
            )}
          </Group>
          {user && (
            <Button
              onClick={handleAddNewEvent}
              variant="light"
            >
              Add New Event
            </Button>
          )}
        </Group>
        
        <Paper p="md" mb="lg" shadow="xs" radius="md">
          <Group align="flex-start" gap="md" wrap="nowrap" style={{ overflowX: 'auto', display: 'flex', justifyContent: 'space-between' }}>
            <Group align="flex-start" gap="md" wrap="nowrap" style={{ flex: 1 }}>
              <form onSubmit={handleSearchSubmit} style={{ display: 'flex' }}>
                <TextInput
                  placeholder="Search events..."
                  value={filters.searchTerm}
                  onChange={(e) => handleFilterChange('searchTerm', e.currentTarget.value)}
                  leftSection={<IconSearch size={16} />}
                  styles={{ 
                    root: { width: 'auto' }
                  }}
                />
              </form>
              <MultiSelect
                placeholder="State/Province"
                value={filters.states}
                onChange={(value) => handleFilterChange('states', value)}
                data={availableStates.map(state => ({ value: state, label: state }))}
                searchable={true}
                clearable
                styles={{ 
                  root: { width: 'auto' },
                  input: { 
                    width: '150px', 
                    pointerEvents: 'auto',
                  },
                  pill: { 
                    background: '#228be6', 
                    color: 'white',
                    padding: '2px 8px'
                  },
                  dropdown: { 
                    zIndex: 200,
                    padding: '8px',
                    minWidth: '200px' 
                  }
                }}
                maxDropdownHeight={280}
                w={150}
                comboboxProps={{ transitionProps: { transition: 'pop', duration: 150 } }}
              />
              <Select
                placeholder="Country"
                value={filters.country || null}
                onChange={(value) => handleFilterChange('country', value || '')}
                data={allCountries.map(country => ({ 
                  value: country, 
                  label: country === "United States of America" ? "USA" : country 
                }))}
                searchable
                clearable
                styles={{ 
                  root: { width: 'auto' },
                  input: { width: '150px', pointerEvents: 'auto' }
                }}
                w={150}
              />

              <Box>
                <Select            
                  placeholder="Event Type"
                  value={filters.isVirtual === true ? 'true' : filters.isVirtual === false ? 'false' : null}
                  onChange={(value) => {
                    if (value === null) {
                      handleFilterChange('isVirtual', undefined);
                    } else if (value === 'true') {
                      handleFilterChange('isVirtual', true);
                    } else if (value === 'false') {
                      handleFilterChange('isVirtual', false);
                    }
                  }}
                  data={[
                    { value: 'true', label: 'Virtual' },
                    { value: 'false', label: 'In Person' }
                  ]}
                  searchable={false}
                  clearable
                  styles={{ 
                    root: { width: 'auto' },
                    input: { 
                      width: '150px', 
                      pointerEvents: 'auto',
                    },
                    dropdown: { 
                      zIndex: 200,
                      minWidth: '150px' 
                    }
                  }}
                  w={150}
                />
              </Box>

              <Box>
                <Popover position="bottom" shadow="md" width="auto" opened={datePickerOpened} onClose={closeDatePicker}>
                  <Popover.Target>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 12px',
                        borderRadius: '4px',
                        border: '1px solid #dee2e6',
                        backgroundColor: 'white',
                        cursor: 'pointer',
                        minWidth: '200px',
                        width: '200px',
                        fontSize: '14px',
                      }}
                      onClick={toggleDatePicker}
                    >
                      <span style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {filters.date ? formatDateDisplay(filters.date) : 'Select date'}
                      </span>
                      {filters.date && (
                        <div 
                          style={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: '#f1f3f5',
                            cursor: 'pointer',
                            marginLeft: '8px',
                            flexShrink: 0
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDateChange(null);
                          }}
                        >
                          <IconX size={14} />
                        </div>
                      )}
                    </div>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Stack gap="xs">
                      <SegmentedControl
                        value={filters.dateFilterType}
                        onChange={(value) => handleFilterChange('dateFilterType', value)}
                        data={[
                          { value: 'day', label: 'Day' },
                          { value: 'month', label: 'Month' },
                          { value: 'year', label: 'Year' }
                        ]}
                        size="xs"
                        fullWidth
                      />
                      
                      {filters.dateFilterType === 'day' && (
                        <DatePicker
                          value={filters.date ? filters.date : null}
                          onChange={(date) => {
                            // Create a UTC date to avoid timezone conversion
                            if (date) {
                              const utcDate = new Date(Date.UTC(
                                date.getFullYear(),
                                date.getMonth(),
                                date.getDate(),
                                0, 0, 0
                              ));
                              handleDateChange(utcDate);
                            } else {
                              handleDateChange(null);
                            }
                            closeDatePicker();
                          }}
                          key={`day-${dateInputKey}`}
                        />
                      )}
                      
                      {filters.dateFilterType === 'month' && (
                        <MonthPicker
                          value={filters.date ? filters.date : null}
                          onChange={(date) => {
                            // Create a UTC date to avoid timezone conversion
                            if (date) {
                              const utcDate = new Date(Date.UTC(
                                date.getFullYear(),
                                date.getMonth(),
                                1,
                                0, 0, 0
                              ));
                              handleMonthSelect(utcDate);
                            } else {
                              handleMonthSelect(null);
                            }
                          }}
                        />
                      )}
                      
                      {filters.dateFilterType === 'year' && (
                        <Box>
                          <Text size="sm" mb={4} fw={500} c="dimmed">Select Year</Text>
                          <div style={{ 
                            display: 'grid', 
                            gridTemplateColumns: '1fr 1fr', 
                            gap: '8px'
                          }}>
                            {Array.from({ length: 4 }).map((_, i) => {
                              const year = new Date().getFullYear() + i;
                              const isSelected = filters.date && filters.date.getUTCFullYear() === year;
                              
                              return (
                                <div
                                  key={year}
                                  style={{
                                    padding: '8px',
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: isSelected ? '#228be6' : '#f1f3f5',
                                    color: isSelected ? 'white' : 'inherit',
                                    fontWeight: isSelected ? 'bold' : 'normal'
                                  }}
                                  onClick={() => {
                                    // Create a UTC date to avoid timezone conversion
                                    const selectedDate = new Date(Date.UTC(year, 0, 1, 0, 0, 0));
                                    handleYearSelect(selectedDate);
                                  }}
                                >
                                  {year}
                                </div>
                              );
                            })}
                          </div>
                        </Box>
                      )}
                      
                      {filters.date && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            backgroundColor: '#f1f3f5',
                            cursor: 'pointer',
                            fontSize: '12px',
                            gap: '4px',
                            width: 'fit-content'
                          }}
                          onClick={() => handleDateChange(null)}
                        >
                          <IconX size={14} />
                          <span style={{ fontSize: '12px' }}>Clear</span>
                        </div>
                      )}
                    </Stack>
                  </Popover.Dropdown>
                </Popover>
              </Box>

              <Box>
                <Button
                  variant={filters.showPastEvents ? "filled" : "light"}
                  onClick={() => handleFilterChange('showPastEvents', !filters.showPastEvents)}
                  color="blue"
                >
                  Past Events
                </Button>
              </Box>
            </Group>
            <Button 
              variant="light" 
              color="gray" 
              size="xs" 
              onClick={clearFilters}
              ml="auto"
              style={{ alignSelf: 'center', flexShrink: 0 }}
            >
              Clear
            </Button>
          </Group>
        </Paper>
        
        <TagFilter tags={tags} baseUrl="/events" />

        {filters.tag && (
          <Box mb="xl">
            <Group justify="space-between" align="center">
              <Title 
                order={2}
                style={gradientText}
              >
                Events tagged with "{filters.tag}"
              </Title>
              <Button 
                variant="subtle"
                component={Link}
                to="/events"
                onClick={() => handleFilterChange('tag', undefined)}
              >
                Clear tag filter
              </Button>
            </Group>
          </Box>
        )}

        {showVerificationAlert && (
          <Alert 
            icon={<IconAlertCircle size={16} />} 
            title="Email verification required" 
            color="yellow" 
            mb="lg"
          >
            You need to verify your email before creating events. Please check your email for a verification link or request a new one from your profile page.
          </Alert>
        )}

        {isLoading ? (
          <Center py="xl">
            <Loader />
          </Center>
        ) : events.length > 0 ? (
          <>
            <Stack gap="lg">
              {events.map((event, index) => (
                <EventCardWithAds 
                  key={event.id} 
                  event={event} 
                  index={index}
                  isLastEvent={index === events.length - 1}
                  totalEvents={events.length}
                  onApprove={isAdmin ? handleApprove : undefined}
                  onDelete={(isAdmin || event.isOwner) ? handleDelete : undefined}
                  searchTerm={submittedSearchTerm}
                />
              ))}
            </Stack>
            
            {hasNextPage && (
              <Center py="xl" ref={intersectionRef}>
                <Loader />
              </Center>
            )}
            
            {!hasNextPage && events.length > 0 && (
              <Text ta="center" mt="xl" c="dimmed">
                No more events to load
              </Text>
            )}
          </>
        ) : (
          <Paper p="xl" my="xl" radius="md" style={contentBox}>
            <Stack align="center" gap="md">
              <Text size="lg" fw={500} ta="center">
                No events found matching your criteria
              </Text>
              {Object.values(filters).some(val => val !== '' && val !== undefined) && (
                <Button variant="light" onClick={clearFilters}>
                  Clear Filters
                </Button>
              )}
              {user && (
                <Button onClick={handleAddNewEvent}>
                  Add an Event
                </Button>
              )}
            </Stack>
          </Paper>
        )}
      </Container>
    </>
  );
} 