import { useState } from 'react';
import { Card, Image, Stack, Text, Group, Badge, Button, Box, Flex, Anchor } from '@mantine/core';
import { ContentEntity, ModeratableEntity } from '../../types/common';
import { cardBase, gradientText } from '../../styles/common';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { highlightSearchTerms } from '../../utils/textUtils';

interface ContentCardProps<T extends ContentEntity> {
  item: T;
  imagePlaceholder?: string;
  imageAlt?: string;
  imageAspectRatio?: number;
  onEdit?: (item: T) => void;
  onDelete?: (item: T) => void;
  onApprove?: (item: T & ModeratableEntity) => void;
  onReject?: (item: T & ModeratableEntity) => void;
  onRemoveOwner?: (item: T) => void;
  tags?: string[];
  renderMeta?: (item: T) => React.ReactNode;
  renderSubheading?: (item: T) => React.ReactNode;
  renderFooter?: (item: T) => React.ReactNode;
  renderAdditionalContent?: (item: T) => React.ReactNode;
  onClick?: (item: T) => void;
  maxDescriptionLines?: number;
  layout?: 'vertical' | 'horizontal';
  imagePosition?: 'top' | 'right';
  width?: string;
  imageHeight?: number;
  disableExpand?: boolean;
  imageObjectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  searchTerm?: string;
}

export function ContentCard<T extends ContentEntity>({
  item,
  imagePlaceholder = '/placeholder.jpg',
  imageAlt,
  imageAspectRatio = 1.5,
  onEdit,
  onDelete,
  onApprove,
  onReject,
  onRemoveOwner,
  tags = item.tags || [],
  renderMeta,
  renderSubheading,
  renderFooter,
  renderAdditionalContent,
  onClick,
  maxDescriptionLines = 2,
  layout = 'vertical',
  imagePosition = 'top',
  width = 'auto',
  imageHeight = 240,
  disableExpand = false,
  imageObjectFit = 'cover',
  searchTerm
}: ContentCardProps<T>) {
  const [expanded, setExpanded] = useState(false);
  const isExpandable = !disableExpand && item.description.length > 120;
  
  // Check if item is moderatable
  const moderatableItem = item as unknown as (T & ModeratableEntity);
  const isModeratableEntity = 'isApproved' in item;
  const isPending = isModeratableEntity && !moderatableItem.isApproved;
  
  const handleEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onEdit) onEdit(item);
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onDelete) onDelete(item);
  };

  const handleApprove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onApprove && isModeratableEntity) onApprove(moderatableItem);
  };

  const handleReject = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onReject && isModeratableEntity) onReject(moderatableItem);
  };

  const handleRemoveOwner = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onRemoveOwner) onRemoveOwner(item);
  };

  const handleClick = () => {
    if (onClick) onClick(item);
  };

  // Render image section
  const renderImage = () => (
    item.imageUrl ? (
      <Image
        src={item.imageUrl}
        height={imageHeight}
        alt={imageAlt || item.title}
        fallbackSrc={imagePlaceholder}
        style={{ 
          width: '100%', 
          objectFit: imageObjectFit,
          borderRadius: layout === 'horizontal' ? 'md' : undefined
        }}
      />
    ) : (
      <div style={{ 
        height: imageHeight, 
        width: '100%',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: layout === 'horizontal' ? 'md' : undefined
      }}>
        <Text c="dimmed">No image</Text>
      </div>
    )
  );

  // Vertical layout (default, like standard card)
  if (layout === 'vertical') {
    return (
      <Card
        padding="lg"
        radius="md"
        withBorder
        style={{ 
          ...cardBase, 
          cursor: onClick ? 'pointer' : 'default', 
          width,
          paddingBottom: 'var(--mantine-spacing-sm)'
        }}
        onClick={onClick ? handleClick : undefined}
      >
        <Card.Section>
          {renderImage()}
        </Card.Section>

        <Group mt="md" justify="space-between">
          <Group align="center">
            <Text 
              fw={600} 
              size="lg" 
              style={gradientText}
            >
              {searchTerm ? highlightSearchTerms(item.title, searchTerm) : item.title}
            </Text>
            {isPending && 
              !('isClaimPending' in item) && (
              <Badge color="yellow" w="fit-content">Pending Approval</Badge>
            )}
          </Group>
          
          {tags.length > 0 && (
            <Group gap={5}>
              {tags.map(tag => (
                <Badge key={tag} size="sm" variant="light">
                  {tag}
                </Badge>
              ))}
            </Group>
          )}
        </Group>

        {renderSubheading && (
          <Box mt={-8} mb={8}>
            {renderSubheading(item)}
          </Box>
        )}

        <Box mt="xs">
          <Text size="sm" c="dimmed" lineClamp={expanded ? undefined : maxDescriptionLines}>
            {searchTerm ? highlightSearchTerms(item.description, searchTerm) : item.description}
          </Text>
          {isExpandable && (
            <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
              <Anchor 
                size="sm" 
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setExpanded(!expanded);
                }}
              >
                {expanded ? 'Show less' : 'Show more'}
              </Anchor>
            </Box>
          )}
        </Box>

        {renderMeta && (
          <Box mt="xs" mb={-5}>
            {renderMeta(item)}
          </Box>
        )}

        {renderAdditionalContent && (
          <Box mt="md">
            {renderAdditionalContent(item)}
          </Box>
        )}

        {(onEdit || onDelete || (isModeratableEntity && isPending && (onApprove || onReject))) ? (
          <Group mt="md" justify="space-between">
            <Group gap="xs">
              {onEdit && (
                <Button 
                  variant="light" 
                  size="xs" 
                  leftSection={<IconEdit size={14} />}
                  onClick={handleEdit}
                >
                  Edit
                </Button>
              )}
              
              {onDelete && (
                <Button 
                  variant="light" 
                  color="red" 
                  size="xs" 
                  leftSection={<IconTrash size={14} />}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              )}
              
              {isModeratableEntity && isPending && onApprove && (
                <Button 
                  variant="light" 
                  color="green" 
                  size="xs" 
                  onClick={handleApprove}
                >
                  Approve
                </Button>
              )}
              
              {isModeratableEntity && isPending && onReject && (
                <Button 
                  variant="light" 
                  color="red" 
                  size="xs" 
                  onClick={handleReject}
                >
                  Reject
                </Button>
              )}
              
              {onRemoveOwner && (
                <Button 
                  variant="light" 
                  color="orange" 
                  size="xs" 
                  onClick={handleRemoveOwner}
                >
                  Remove Owner
                </Button>
              )}
            </Group>
            
            {renderFooter && <div>{renderFooter(item)}</div>}
          </Group>
        ) : renderFooter ? (
          <Box mt="auto" pt="xs">
            {renderFooter(item)}
          </Box>
        ) : null}
      </Card>
    );
  }
  
  // Horizontal layout (like original EventCard)
  return (
    <Card 
      padding="lg"
      radius="md"
      withBorder
      style={{ 
        ...cardBase, 
        width: width || '100%', 
        paddingBottom: 'var(--mantine-spacing-sm)' 
      }}
      onClick={onClick ? handleClick : undefined}
    >
      <Flex gap="md" direction={{ base: 'column', sm: 'row' }}>
        <Box style={{ flex: 3 }}>
          <Stack>
            <div>
              <Group align="center" mb="xs" justify="space-between">
                <Group align="center">
                  <Text 
                    fw={600} 
                    size="lg" 
                    style={gradientText}
                  >
                    {searchTerm ? highlightSearchTerms(item.title, searchTerm) : item.title}
                  </Text>
                  {isPending && 
                    !('isClaimPending' in item) && (
                    <Badge color="yellow" w="fit-content">Pending Approval</Badge>
                  )}
                </Group>
                
                {tags.length > 0 && (
                  <Group gap={5}>
                    {tags.map(tag => (
                      <Badge key={tag} size="sm" variant="light">
                        {tag}
                      </Badge>
                    ))}
                  </Group>
                )}
              </Group>

              {renderSubheading && (
                <Box mt={-8} mb={8}>
                  {renderSubheading(item)}
                </Box>
              )}

              <Box mb={4}>
                <Text size="sm" c="dimmed" lineClamp={expanded ? undefined : maxDescriptionLines}>
                  {searchTerm ? highlightSearchTerms(item.description, searchTerm) : item.description}
                </Text>
                {isExpandable && (
                  <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 0 }}>
                    <Anchor 
                      size="sm" 
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setExpanded(!expanded);
                      }}
                    >
                      {expanded ? 'Show less' : 'Show more'}
                    </Anchor>
                  </Box>
                )}
              </Box>

              {renderMeta && (
                <Box mt="xs" mb={-5}>
                  {renderMeta(item)}
                </Box>
              )}

              {renderAdditionalContent && (
                <Box mt="md">
                  {renderAdditionalContent(item)}
                </Box>
              )}
            </div>
            
            <Box>
              {(onEdit || onDelete || (isModeratableEntity && isPending && (onApprove || onReject))) ? (
                <Group mt={5} justify="space-between">
                  <Group gap="xs">
                    {onEdit && (
                      <Button 
                        variant="light" 
                        size="xs" 
                        leftSection={<IconEdit size={14} />}
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                    )}
                    
                    {onDelete && (
                      <Button 
                        variant="light" 
                        color="red" 
                        size="xs" 
                        leftSection={<IconTrash size={14} />}
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    )}
                    
                    {isModeratableEntity && isPending && onApprove && (
                      <Button 
                        variant="light" 
                        color="green" 
                        size="xs" 
                        onClick={handleApprove}
                      >
                        Approve
                      </Button>
                    )}
                    
                    {isModeratableEntity && isPending && onReject && (
                      <Button 
                        variant="light" 
                        color="red" 
                        size="xs" 
                        onClick={handleReject}
                      >
                        Reject
                      </Button>
                    )}
                    
                    {onRemoveOwner && (
                      <Button 
                        variant="light" 
                        color="orange" 
                        size="xs" 
                        onClick={handleRemoveOwner}
                      >
                        Remove Owner
                      </Button>
                    )}
                  </Group>
                  
                  {renderFooter && <div>{renderFooter(item)}</div>}
                </Group>
              ) : renderFooter ? (
                <Box mt="auto" pt="xs">
                  {renderFooter(item)}
                </Box>
              ) : null}
            </Box>
          </Stack>
        </Box>
        
        {item.imageUrl && (
          <Box style={{ flex: 1 }}>
            {renderImage()}
          </Box>
        )}
      </Flex>
    </Card>
  );
} 