export interface Material {
  name: string;
  quantity: string;
  notes?: string;
  purchaseUrl?: string;
}

export interface Section {
  name: string;
  instructions: string[];
}

export enum Role {
  User = 'User',
  Subscriber = 'Subscriber',
  Admin = 'Admin'
}

export interface CrochetPattern {
  id: string;
  ownerId: string;
  name: string;
  description: string;
  imageUrl?: string;
  createdAt: string;
  accessLevel: string;
  difficultyLevel: string;
  category: PatternCategory;
  projectType: ProjectType;
  materialsNeeded: Material[];
  tools: string[];
  sections: Section[];
  assemblyInstructions?: string[];
  specialNotes: string[];
  notes?: string;
  heartCount: number;
  isHearted: boolean;
  heartedByUsers: string[];
  wasCached?: boolean;
  categoryName: PatternCategory;
  commentCount: number;
  isVerified: boolean;
  isTester: boolean;
  testerCount: number;
}

export interface AdminUserDetails {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    displayName: string;
    profileImageUrl?: string;
    role: Role;
    patternGenerationCount: number;
    patternGenerationLastResetDate: string;
    viewedPatternIds: string[];
    viewedPatternsLastResetDate: string;
    stripeCustomerId?: string;
    createdAt: string;
    maxViews: number;
    maxGenerations: number;
    karma: number;
    lifetimeKarma: number;
}

export interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    displayName: string;
    profileImageUrl?: string;
    role: Role;
    patternGenerationCount: number;
    patternGenerationLastResetDate: string;
    remainingViews: number;
    maxViews: number;
    stripeCustomerId?: string;
    karma: number;
    lifetimeKarma: number;
    isTester?: boolean;
    isEmailVerified?: boolean;
}

export enum PatternCategory {
  Apparel = 0,
  Accessories = 1,
  Toys = 2,
  GrannySquares = 3,
  HomeDecor = 4,
  Other = 5
}

export type CrochetPatternSummary = {
  id: string;
  name: string;
  description: string;
  imageUrl?: string;
  difficultyLevel: string;
  commentCount: number;
  heartCount: number;
  isHearted: boolean;
  isCreator: boolean;
  category: PatternCategory;
  projectType: ProjectType;
  isVerified: boolean;
  isTester: boolean;
  testerCount: number;
}

export interface PaginatedResponse<T> {
    items: T[];
    totalCount: number;
    currentPage: number;
    pageSize: number;
}

export enum SortOption {
  None = 'None',
  TitleAsc = 'TitleAsc',
  TitleDesc = 'TitleDesc',
  DifficultyAsc = 'DifficultyAsc',
  DifficultyDesc = 'DifficultyDesc',
  CategoryAsc = 'CategoryAsc',
  CategoryDesc = 'CategoryDesc',
  Newest = 'Newest',
  Oldest = 'Oldest',
  MostLiked = 'MostLiked'
}

export enum ProjectType {
  Crochet = 'Crochet',
  Knit = 'Knit',
  Needlepoint = 'Needlepoint'
}

export interface FilterOptions {
  difficulty?: string | null;
  searchTerm?: string;
  categories?: PatternCategory[];
  hearted?: boolean;
  sortBy?: SortOption;
  ownedByUser?: boolean;
  viewedByUser?: boolean;
  [key: string]: any;
}