import { apiClient } from './client';
import { API_BASE_URL } from './config';
import { getAuthHeaders } from '../utils/authHeaders';
import { handleApiError } from '../utils/apiUtils';

export interface JournalEntry {
  id: string;
  comment: string;
  imageUrl?: string;
  createdAt: string;
}

export interface PatternTestJournal {
  id: string;
  userId: string;
  patternId: string;
  completionPercentage: number;
  createdAt: string;
  updatedAt: string;
  entries: JournalEntry[];
  patternName: string;
  userDisplayName: string;
  isSubmitted?: boolean;
  isApproved?: boolean;
  isRejected?: boolean;
}

interface AddJournalEntryRequest {
  comment: string;
  image?: File;
  recaptchaToken?: string;
}

interface UpdateJournalEntryRequest {
  comment: string;
  image?: File;
  recaptchaToken?: string;
}

export const patternTestJournalsApi = {
  // Get a journal for the current user and a specific pattern
  // Returns 404 if no journal exists
  getByPatternId: async (patternId: string): Promise<PatternTestJournal> => {
    try {
      const response = await apiClient.get<PatternTestJournal>(
        `${API_BASE_URL}/api/pattern-test-journals/by-user-pattern/${patternId}`,
        { headers: getAuthHeaders() }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  // Get all journals for the current user
  getMyJournals: async (): Promise<PatternTestJournal[]> => {
    try {
      const response = await apiClient.get<PatternTestJournal[]>(
        `${API_BASE_URL}/api/pattern-test-journals/my-journals`,
        { headers: getAuthHeaders() }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  // Add an entry to a journal for a specific pattern
  // Creates the journal if it doesn't exist
  addEntry: async (patternId: string, request: AddJournalEntryRequest): Promise<PatternTestJournal> => {
    try {
      const formData = new FormData();
      formData.append('comment', request.comment);
      
      if (request.image) {
        formData.append('image', request.image);
      }

      // Log the request details for debugging
      console.log('Adding journal entry:', {
        patternId,
        comment: request.comment,
        hasImage: !!request.image
      });

      // Use axios directly to avoid Content-Type header issues with FormData
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const headers: HeadersInit = {
        'Authorization': `Bearer ${token}`
      };

      if (request.recaptchaToken) {
        headers['X-Recaptcha-Token'] = request.recaptchaToken;
      }

      const response = await fetch(`${API_BASE_URL}/api/pattern-test-journals/entries/${patternId}`, {
        method: 'POST',
        headers,
        body: formData
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', {
          status: response.status,
          statusText: response.statusText,
          body: errorText
        });
        throw new Error(`API error: ${response.status} ${response.statusText}`);
      }

      return await response.json();
    } catch (error: any) {
      console.error('Error adding journal entry:', error);
      throw handleApiError(error);
    }
  },

  // Update the completion percentage for a journal for a specific pattern
  // Creates the journal if it doesn't exist
  updateCompletionPercentage: async (patternId: string, percentage: number, recaptchaToken?: string): Promise<void> => {
    try {
      const headers = {...getAuthHeaders()} as Record<string, string>;
      if (recaptchaToken) {
        headers['X-Recaptcha-Token'] = recaptchaToken;
      }

      // Use the new endpoint that will handle journal creation if needed
      await apiClient.put(
        `${API_BASE_URL}/api/pattern-test-journals/completion/${patternId}`,
        { completionPercentage: percentage },
        { headers }
      );
    } catch (error) {
      throw handleApiError(error);
    }
  },

  // Manually create a journal for a specific pattern
  // Usually not needed as other endpoints will create it automatically
  createJournal: async (patternId: string, recaptchaToken?: string): Promise<PatternTestJournal> => {
    try {
      const headers = {...getAuthHeaders()} as Record<string, string>;
      if (recaptchaToken) {
        headers['X-Recaptcha-Token'] = recaptchaToken;
      }

      const response = await apiClient.post<PatternTestJournal>(
        `${API_BASE_URL}/api/pattern-test-journals`,
        { patternId },
        { headers }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  // Update an existing journal entry
  updateEntry: async (journalId: string, entryId: string, request: UpdateJournalEntryRequest): Promise<PatternTestJournal> => {
    try {
      const formData = new FormData();
      formData.append('comment', request.comment);
      
      if (request.image) {
        formData.append('image', request.image);
      }

      // Use fetch to avoid Content-Type header issues with FormData
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const headers: HeadersInit = {
        'Authorization': `Bearer ${token}`
      };

      if (request.recaptchaToken) {
        headers['X-Recaptcha-Token'] = request.recaptchaToken;
      }

      const response = await fetch(`${API_BASE_URL}/api/pattern-test-journals/${journalId}/entries/${entryId}`, {
        method: 'PUT',
        headers,
        body: formData
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API error: ${response.status} ${response.statusText} - ${errorText}`);
      }

      return await response.json();
    } catch (error) {
      throw handleApiError(error);
    }
  },

  // Delete a journal entry
  deleteEntry: async (journalId: string, entryId: string, recaptchaToken?: string): Promise<void> => {
    try {
      const headers = {...getAuthHeaders()} as Record<string, string>;
      if (recaptchaToken) {
        headers['X-Recaptcha-Token'] = recaptchaToken;
      }

      await apiClient.delete(
        `${API_BASE_URL}/api/pattern-test-journals/${journalId}/entries/${entryId}`,
        { headers }
      );
    } catch (error) {
      throw handleApiError(error);
    }
  },
  
  // Delete an entire journal (stop testing)
  deleteJournal: async (journalId: string, recaptchaToken?: string): Promise<void> => {
    try {
      const headers = {...getAuthHeaders()} as Record<string, string>;
      if (recaptchaToken) {
        headers['X-Recaptcha-Token'] = recaptchaToken;
      }

      await apiClient.delete(
        `${API_BASE_URL}/api/pattern-test-journals/${journalId}`,
        { headers }
      );
    } catch (error) {
      throw handleApiError(error);
    }
  },
  
  // Submit a journal for review (mark as submitted)
  submitJournal: async (journalId: string, recaptchaToken?: string): Promise<PatternTestJournal> => {
    try {
      const headers = {...getAuthHeaders()} as Record<string, string>;
      if (recaptchaToken) {
        headers['X-Recaptcha-Token'] = recaptchaToken;
      }

      const response = await apiClient.post<PatternTestJournal>(
        `${API_BASE_URL}/api/pattern-test-journals/${journalId}/submit`,
        {},
        { headers }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  
  // Unsubmit a journal (return to draft state)
  unsubmitJournal: async (journalId: string, recaptchaToken?: string): Promise<PatternTestJournal> => {
    try {
      const headers = {...getAuthHeaders()} as Record<string, string>;
      if (recaptchaToken) {
        headers['X-Recaptcha-Token'] = recaptchaToken;
      }

      const response = await apiClient.post<PatternTestJournal>(
        `${API_BASE_URL}/api/pattern-test-journals/${journalId}/unsubmit`,
        {},
        { headers }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  }
}; 