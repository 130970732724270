import { Box, Paper, Stack, Text, Divider, Group } from '@mantine/core';
import { useState } from 'react';
import { GoogleSignIn } from '../GoogleSignIn';
import { LoginForm } from './LoginForm';
import { RegisterForm } from './RegisterForm';
import { ForgotPasswordForm } from './ForgotPasswordForm';

type AuthMode = 'login' | 'register' | 'forgot-password';

interface AuthContainerProps {
  defaultMode?: AuthMode;
  onSuccess?: () => void;
}

export function AuthContainer({ defaultMode = 'login', onSuccess }: AuthContainerProps) {
  const [mode, setMode] = useState<AuthMode>(defaultMode);

  const handleSwitchToLogin = () => setMode('login');
  const handleSwitchToRegister = () => setMode('register');
  const handleSwitchToForgotPassword = () => setMode('forgot-password');

  return (
    <Paper shadow="md" p="xl" radius="md" withBorder>
      {mode === 'login' && (
        <Stack gap="lg">
          <Text size="xl" fw={700} ta="center">
            Sign in to your account
          </Text>
          
          <Box maw={400} mx="auto" w="100%">
            <LoginForm 
              onSuccess={onSuccess}
              onSwitchToRegister={handleSwitchToRegister}
              onSwitchToForgotPassword={handleSwitchToForgotPassword}
            />
          </Box>

          <Divider label="Or continue with" labelPosition="center" />
          
          <Group justify="center">
            <GoogleSignIn onSuccess={onSuccess} />
          </Group>
        </Stack>
      )}

      {mode === 'register' && (
        <Stack gap="lg">
          <Text size="xl" fw={700} ta="center">
            Create an account
          </Text>
          
          <Box maw={400} mx="auto" w="100%">
            <RegisterForm 
              onSuccess={onSuccess}
              onSwitchToLogin={handleSwitchToLogin}
            />
          </Box>

          <Divider label="Or continue with" labelPosition="center" />
          
          <Group justify="center">
            <GoogleSignIn onSuccess={onSuccess} />
          </Group>
        </Stack>
      )}

      {mode === 'forgot-password' && (
        <Stack gap="lg">
          <Text size="xl" fw={700} ta="center">
            Forgot Password
          </Text>
          
          <Box maw={400} mx="auto" w="100%">
            <ForgotPasswordForm 
              onSwitchToLogin={handleSwitchToLogin}
            />
          </Box>
        </Stack>
      )}
    </Paper>
  );
} 