import { Button, Stack, Textarea, Alert, Group } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { useState, useEffect } from 'react';
import { commentsApi } from '../api/comments';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useReCaptcha } from '../hooks/useReCaptcha';
import { notifications } from '@mantine/notifications';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEmailVerification } from '../hooks/useEmailVerification';

interface CommentFormProps {
    patternId: string;
    onCommentAdded: () => void;
}

export function CommentForm({ patternId, onCommentAdded }: CommentFormProps) {
    const [text, setText] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const queryClient = useQueryClient();
    const { verify, isVerifying } = useReCaptcha();
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    
    // Use our email verification hook
    const { checkVerification, VerificationAlert } = useEmailVerification({
        actionName: 'adding a comment'
    });

    // Clear success message after 6 seconds
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (showSuccess) {
            timeoutId = setTimeout(() => {
                setShowSuccess(false);
            }, 6000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [showSuccess]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!text.trim()) return;

        // Redirect to signin page if user is not logged in
        if (!user) {
            // Save current location to redirect back after signin
            navigate('/signin', { 
                state: { from: location.pathname } 
            });
            return;
        }

        // Use our email verification hook
        if (!checkVerification()) {
            return;
        }

        try {
            const recaptchaToken = await verify('add_comment');
            addCommentMutation.mutate({ text, recaptchaToken });
        } catch (error) {
            notifications.show({
                title: 'Error',
                message: 'Failed to verify reCAPTCHA',
                color: 'red'
            });
        }
    };

    const addCommentMutation = useMutation({
        mutationFn: async ({ text, recaptchaToken }: { text: string; recaptchaToken: string }) => {
            return commentsApi.addComment(patternId, text, recaptchaToken);
        },
        onMutate: async ({ text }) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey: ['comments', patternId] });

            // Snapshot the previous values
            const previousPendingComments = queryClient.getQueryData(['comments', patternId, 'pending']);

            const tempId = 'temp-' + Date.now();
            // Create an optimistic comment
            const optimisticComment = {
                id: tempId,
                commenterId: user?.id || '',
                commenterName: `${user?.firstName} ${user?.lastName}` || '',
                commenterDisplayName: user?.displayName || '',
                text,
                createdAt: new Date().toISOString(),
                patternId,
                isOptimistic: true // Flag to identify optimistic comments
            };

            // Optimistically update pending comments
            queryClient.setQueryData(['comments', patternId, 'pending'], (old: any[] = []) => {
                return [...old, optimisticComment];
            });

            return { previousPendingComments, tempId };
        },
        onError: (error, _, context) => {
            // Revert optimistic update on error
            if (context?.previousPendingComments) {
                queryClient.setQueryData(['comments', patternId, 'pending'], context.previousPendingComments);
            }
            notifications.show({
                title: 'Error',
                message: 'Failed to add comment',
                color: 'red'
            });
        },
        onSuccess: (response, variables, context) => {
            // Invalidate and refetch comments
            queryClient.invalidateQueries({ queryKey: ['comments', patternId] });

            notifications.show({
                title: 'Success',
                message: 'Comment added successfully',
                color: 'green'
            });
            setText('');
            onCommentAdded?.();
        }
    });

    return (
        <Stack gap="sm">
            {showSuccess && (
                <Alert 
                    icon={<IconCheck size={16} />} 
                    title="Comment submitted!" 
                    color="green" 
                    variant="light"
                >
                    Your comment has been submitted and is pending approval.
                </Alert>
            )}
            
            {/* Use the VerificationAlert from our hook */}
            {VerificationAlert}
            
            <form onSubmit={handleSubmit}>
                <Stack gap="md">
                    <Textarea
                        value={text}
                        onChange={(e) => setText(e.currentTarget.value)}
                        placeholder={user ? "Write your comment here..." : "Sign in to leave a comment"}
                        minRows={3}
                        disabled={addCommentMutation.isPending || isVerifying}
                    />
                    <Group justify="flex-end">
                        <Button 
                            type="submit" 
                            disabled={!text.trim() || addCommentMutation.isPending || isVerifying}
                        >
                            {user ? "Add Comment" : "Sign In to Comment"}
                        </Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    );
} 