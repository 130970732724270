import { Event, EventSummary, EventFilter, LocationPaginatedResult } from '../types/Events';
import { getAuthHeaders } from '../utils/authHeaders';
import { API_BASE_URL } from './config';
import { handleApiError } from '../utils/apiUtils';
import { getFullImageUrl } from '../utils/imageUtils';

export const eventsApi = {
  getPaginated: async ({ page, pageSize, tag, isApproved, isVirtual, date, city, states, country, showPastEvents, searchTerm }: EventFilter): Promise<LocationPaginatedResult<EventSummary>> => {
    try {
      const url = new URL(`${API_BASE_URL}/api/event`);
      url.searchParams.append('page', page.toString());
      url.searchParams.append('pageSize', pageSize.toString());
      
      if (tag) url.searchParams.append('tag', tag);
      if (isApproved !== undefined) url.searchParams.append('isApproved', isApproved.toString());
      if (isVirtual !== undefined) url.searchParams.append('isVirtual', isVirtual.toString());
      if (date) url.searchParams.append('date', date);
      if (city) url.searchParams.append('city', city);
      if (showPastEvents !== undefined) url.searchParams.append('showPastEvents', showPastEvents.toString());
      if (searchTerm) url.searchParams.append('searchTerm', searchTerm);
      
      // Handle states parameter - add each state as a separate query parameter 
      if (states && states.length > 0) {
        states.forEach(state => {
          url.searchParams.append('states', state);
        });
      }
      
      if (country) url.searchParams.append('country', country);

      const headers: Record<string, string> = {};
      try {
        Object.assign(headers, getAuthHeaders());
      } catch (e) {
        // Continue without auth headers for anonymous users
      }

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const data = await response.json();
      return {
        items: data.items.map((item: EventSummary) => ({
          ...item,
          imageUrl: item.imageUrl ? getFullImageUrl(item.imageUrl) : undefined
        })),
        totalItems: data.totalItems,
        countries: data.countries || [],
        states: data.states || []
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getById: async (id: string): Promise<Event> => {
    try {
      const headers: Record<string, string> = {};
      try {
        Object.assign(headers, getAuthHeaders());
      } catch (e) {
        // Continue without auth headers for anonymous users
      }

      const response = await fetch(`${API_BASE_URL}/api/event/${id}`, {
        method: 'GET',
        headers
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const event = await response.json();
      return {
        ...event,
        imageUrl: event.imageUrl ? getFullImageUrl(event.imageUrl) : undefined
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getTags: async (): Promise<string[]> => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/event/tags`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      return await response.json();
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getCountries: async (): Promise<string[]> => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/event/countries`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      return await response.json();
    } catch (error) {
      throw handleApiError(error);
    }
  },

  create: async (data: FormData, recaptchaToken?: string): Promise<Event> => {
    try {
      // For FormData, we need to explicitly type and handle headers
      const headers: Record<string, string> = getAuthHeaders('');
      
      // Add recaptcha token if provided
      if (recaptchaToken) {
        headers['X-Recaptcha-Token'] = recaptchaToken;
      }
      
      // FormData sets its own Content-Type header automatically
      if ('Content-Type' in headers) {
        delete headers['Content-Type'];
      }
      
      const response = await fetch(`${API_BASE_URL}/api/event`, {
        method: 'POST',
        headers,
        body: data,
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const event = await response.json();
      return {
        ...event,
        imageUrl: event.imageUrl ? getFullImageUrl(event.imageUrl) : undefined
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  update: async (id: string, data: FormData, recaptchaToken?: string): Promise<Event> => {
    try {
      // For FormData, we need to explicitly type and handle headers
      const headers: Record<string, string> = getAuthHeaders('');
      
      // Add recaptcha token if provided
      if (recaptchaToken) {
        headers['X-Recaptcha-Token'] = recaptchaToken;
      }
      
      // FormData sets its own Content-Type header automatically
      if ('Content-Type' in headers) {
        delete headers['Content-Type'];
      }
      
      const response = await fetch(`${API_BASE_URL}/api/event/${id}`, {
        method: 'PUT',
        headers,
        body: data,
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const event = await response.json();
      return {
        ...event,
        imageUrl: event.imageUrl ? getFullImageUrl(event.imageUrl) : undefined
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  approve: async (id: string): Promise<Event> => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/event/${id}/approve`, {
        method: 'PUT',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const event = await response.json();
      return {
        ...event,
        imageUrl: event.imageUrl ? getFullImageUrl(event.imageUrl) : undefined
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  reject: async (id: string): Promise<Event> => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/event/${id}/reject`, {
        method: 'PUT',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const event = await response.json();
      return {
        ...event,
        imageUrl: event.imageUrl ? getFullImageUrl(event.imageUrl) : undefined
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  delete: async (id: string, recaptchaToken?: string): Promise<void> => {
    try {
      const headers: Record<string, string> = getAuthHeaders();
      
      // Add recaptcha token if provided
      if (recaptchaToken) {
        headers['X-Recaptcha-Token'] = recaptchaToken;
      }
      
      const response = await fetch(`${API_BASE_URL}/api/event/${id}`, {
        method: 'DELETE',
        headers,
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }
    } catch (error) {
      throw handleApiError(error);
    }
  },

  claim: async (id: string, recaptchaToken?: string): Promise<Event> => {
    try {
      const headers: Record<string, string> = getAuthHeaders();
      
      // Add recaptcha token if provided
      if (recaptchaToken) {
        headers['X-Recaptcha-Token'] = recaptchaToken;
      }
      
      const response = await fetch(`${API_BASE_URL}/api/event/${id}/claim`, {
        method: 'PUT',
        headers,
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      return await response.json();
    } catch (error) {
      throw handleApiError(error);
    }
  },

  approveClaim: async (id: string): Promise<Event> => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/event/${id}/approve-claim`, {
        method: 'PUT',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const event = await response.json();
      return {
        ...event,
        imageUrl: event.imageUrl ? getFullImageUrl(event.imageUrl) : undefined
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  rejectClaim: async (id: string): Promise<Event> => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/event/${id}/reject-claim`, {
        method: 'PUT',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const event = await response.json();
      return {
        ...event,
        imageUrl: event.imageUrl ? getFullImageUrl(event.imageUrl) : undefined
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  removeOwner: async (id: string): Promise<Event> => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/event/${id}/remove-owner`, {
        method: 'PUT',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const event = await response.json();
      return {
        ...event,
        imageUrl: event.imageUrl ? getFullImageUrl(event.imageUrl) : undefined
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  findDuplicates: async (): Promise<{ titleDuplicates: EventSummary[], urlDuplicates: EventSummary[] }> => {
    try {
      const headers: Record<string, string> = getAuthHeaders();
      
      const response = await fetch(`${API_BASE_URL}/api/event/find-duplicates`, {
        method: 'GET',
        headers
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const data = await response.json();
      
      // Process the data to add full image URLs
      return {
        titleDuplicates: data.titleDuplicates.map((item: EventSummary) => ({
          ...item,
          imageUrl: item.imageUrl ? getFullImageUrl(item.imageUrl) : undefined
        })),
        urlDuplicates: data.urlDuplicates.map((item: EventSummary) => ({
          ...item,
          imageUrl: item.imageUrl ? getFullImageUrl(item.imageUrl) : undefined
        }))
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  cleanupTags: async (): Promise<{ updatedCount: number, message: string }> => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/event/cleanup-tags`, {
        method: 'POST',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      return await response.json();
    } catch (error) {
      throw handleApiError(error);
    }
  },
}; 