import React from 'react';
import { Helmet } from 'react-helmet-async';
import StructuredData from './StructuredData';

/**
 * GlobalSEO component handles site-wide SEO configurations
 * Including Google Analytics, AdSense, reCAPTCHA, and global structured data
 */
const GlobalSEO: React.FC = () => {
  return (
    <>
      <Helmet>
        {/* Google Analytics - loaded with defer for better performance */}
        <script defer src="https://www.googletagmanager.com/gtag/js?id=G-19VN8H14V8"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-19VN8H14V8');
          `}
        </script>
        
        {/* Google AdSense - loaded with async */}
        <script 
          async 
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1849973068698538"
          crossOrigin="anonymous"
          referrerPolicy="no-referrer-when-downgrade"
        />
        
        {/* reCAPTCHA - loaded with defer */}
        <script defer src="https://www.google.com/recaptcha/api.js?render=6LdlLtsqAAAAAD8KoNLWx2TCOxEejrqUTFdXO0Lh"></script>
      </Helmet>
      
      {/* Global website structured data */}
      <StructuredData
        data={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "The Fiber Collective",
          "url": "https://thefibercollective.com",
          "description": "A community for crochet pattern sharing and fiber arts"
        }}
      />
    </>
  );
};

export default GlobalSEO; 