import { Container, Title, Text, Paper, Stack, List } from '@mantine/core';
import { gradientText, paperGradient } from '../styles/common';

export function TermsPage() {
  return (
    <Container size="md" py="xl">
      <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
        <Stack gap="xl">
          <Title 
            order={1}
            style={gradientText}
          >
            Terms of Service
          </Title>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              1. Acceptance of Terms
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              By accessing or using The Fiber Collective, you agree to be bound by these Terms of Service. 
              If you do not agree to these terms, please do not use our service.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              2. User Accounts
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              You must maintain the security of your account and promptly notify us of any unauthorized use. 
              Users are responsible for all activities that occur under their account.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              3. Content Guidelines
            </Title>
            <Text size="lg" mb="sm" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              Users agree not to post content that:
            </Text>
            <List size="lg" spacing="sm" style={{ color: '#2c3e50' }}>
              <List.Item>Infringes on intellectual property rights</List.Item>
              <List.Item>Contains harmful or malicious code</List.Item>
              <List.Item>Violates any applicable laws or regulations</List.Item>
              <List.Item>Contains hate speech or discriminatory content</List.Item>
              <List.Item>Harasses or bullies other users</List.Item>
            </List>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              4. Intellectual Property
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              All content and materials available on The Fiber Collective, including but not limited to 
              text, graphics, website name, code, images and logos are the intellectual property of 
              The Fiber Collective. All patterns generated through our service are subject to our 
              pattern usage terms.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              5. Subscription Terms
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              Subscription fees are billed in advance on a monthly basis. You can cancel your 
              subscription at any time, but we do not provide refunds for partial months of service.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              6. Affiliate Links
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              The Fiber Collective may include links to third-party products and services. 
              We may earn a commission when you click on these links and make a purchase. 
              These affiliate relationships do not influence our content or recommendations, 
              but we believe in transparency and want you to be aware that we may receive 
              compensation for these referrals.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              7. Termination
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              We reserve the right to terminate or suspend accounts that violate these terms 
              or engage in harmful behavior, at our sole discretion.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              8. Changes to Terms
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              We may modify these terms at any time. Continued use of the platform after changes 
              constitutes acceptance of the modified terms.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              9. Disclaimer
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              The Fiber Collective is provided "as is" without warranties of any kind. We are not 
              responsible for any damages resulting from the use of our service.
            </Text>
          </div>

          <Text c="dimmed" size="sm">
            Last updated: {new Date().toLocaleDateString()}
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
} 