import { Group, Paper, Badge, ScrollArea } from '@mantine/core';
import { Link, useSearchParams } from 'react-router-dom';

export interface TagFilterProps {
  tags: string[];
  baseUrl?: string;
}

export function TagFilter({ tags, baseUrl = '/blog' }: TagFilterProps) {
  const [searchParams] = useSearchParams();
  const selectedTag = searchParams.get('tag');

  if (tags.length === 0) return null;

  return (
    <Paper p="md" mb="lg" radius="md" withBorder>
      <ScrollArea h={48} scrollbarSize={8} type="always" offsetScrollbars scrollbars="y">
        <Group gap="xs" wrap="wrap">
          {tags.map((tag) => (
            <Badge
              key={tag}
              size="lg"
              variant={selectedTag === tag ? "filled" : "light"}
              color="blue"
              component={Link}
              to={selectedTag === tag ? baseUrl : `${baseUrl}?tag=${encodeURIComponent(tag)}`}
              style={{ cursor: 'pointer' }}
            >
              {tag}
              {selectedTag === tag && " ✕"}
            </Badge>
          ))}
        </Group>
      </ScrollArea>
    </Paper>
  );
} 