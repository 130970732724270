import { Table, Paper, TextInput, MultiSelect, Badge, LoadingOverlay, Text, Button, Modal, Select, Group, NumberInput } from '@mantine/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { adminApi } from '../../api/admin';
import { useState, useMemo, useEffect } from 'react';
import { AdminUserDetails, Role } from '../../types/api';
import { IconDeviceFloppy, IconTrash } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';

export function UsersTable() {
    const [search, setSearch] = useState('');
    const [roleFilters, setRoleFilters] = useState<Role[]>([]);
    const [roleModalOpen, setRoleModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [pendingRoleChanges, setPendingRoleChanges] = useState<Record<string, Role>>({});
    const [users, setUsers] = useState<AdminUserDetails[]>([]);
    const [editingKarmaUserId, setEditingKarmaUserId] = useState<string | null>(null);
    const [karmaAmount, setKarmaAmount] = useState<number>(0);
    const [isSubmittingKarma, setIsSubmittingKarma] = useState(false);
    const [deletingUserId, setDeletingUserId] = useState<string | null>(null);

    const { data: fetchedUsers, isLoading } = useQuery({
        queryKey: ['admin', 'users'],
        queryFn: adminApi.getAllUsers
    });

    const queryClient = useQueryClient();

    useEffect(() => {
        if (fetchedUsers) {
            setUsers(fetchedUsers);
        }
    }, [fetchedUsers]);

    const handleRoleClick = (userId: string) => {
        setSelectedUserId(userId);
        setRoleModalOpen(true);
    };

    const handleRoleChange = (newRole: Role) => {
        if (selectedUserId) {
            setPendingRoleChanges(prev => ({
                ...prev,
                [selectedUserId]: newRole
            }));
        }
        setRoleModalOpen(false);
    };

    const handleSaveChanges = async (user: AdminUserDetails) => {
        if (!pendingRoleChanges[user.id]) return;

        try {
            const updatedUser = await adminApi.updateUser(user.id, {
                ...user,
                role: pendingRoleChanges[user.id]
            });
            
            // Update the local state with the new user data
            setUsers(users.map(u => u.id === updatedUser.id ? updatedUser : u));
            
            // Clear the pending change
            setPendingRoleChanges(prev => {
                const newChanges = { ...prev };
                delete newChanges[user.id];
                return newChanges;
            });

            notifications.show({
                title: 'Success',
                message: 'User role updated successfully',
                color: 'green'
            });
        } catch (error) {
            notifications.show({
                title: 'Error',
                message: 'Failed to update user role',
                color: 'red'
            });
        }
    };

    const handleKarmaClick = (userId: string) => {
        setEditingKarmaUserId(userId);
        setKarmaAmount(0);
    };

    const handleKarmaCancel = () => {
        setEditingKarmaUserId(null);
        setKarmaAmount(0);
    };

    const handleKarmaSubmit = async (userId: string) => {
        if (karmaAmount <= 0) {
            notifications.show({
                title: 'Invalid Amount',
                message: 'Karma amount must be greater than 0',
                color: 'red'
            });
            return;
        }

        setIsSubmittingKarma(true);
        try {
            const result = await adminApi.addKarma(userId, karmaAmount);
            
            // Update the user in the local state
            setUsers(prevUsers => 
                prevUsers.map(user => 
                    user.id === userId 
                        ? { ...user, karma: result.karma, lifetimeKarma: result.lifetimeKarma } 
                        : user
                )
            );
            
            // Invalidate auth cache to ensure profile page shows updated data
            queryClient.invalidateQueries({ queryKey: ['auth', 'validate'] });
            
            notifications.show({
                title: 'Success',
                message: `Added ${karmaAmount} karma to user`,
                color: 'green'
            });
            
            setEditingKarmaUserId(null);
            setKarmaAmount(0);
        } catch (error) {
            console.error('Error adding karma:', error);
            notifications.show({
                title: 'Error',
                message: 'Failed to add karma. Please try again.',
                color: 'red'
            });
        } finally {
            setIsSubmittingKarma(false);
        }
    };

    const handleDeleteUser = (userId: string, userName: string) => {
        console.log('Delete user clicked:', userId, userName);
        
        modals.openConfirmModal({
            title: 'Delete User',
            children: (
                <Text size="sm">
                    Are you sure you want to delete the user <strong>{userName}</strong>? This action cannot be undone.
                </Text>
            ),
            labels: { confirm: 'Delete', cancel: 'Cancel' },
            confirmProps: { color: 'red' },
            onConfirm: async () => {
                try {
                    setDeletingUserId(userId);
                    await adminApi.deleteUser(userId);
                    
                    // Update the local state by removing the deleted user
                    setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
                    
                    // Invalidate the users query to refresh the data
                    queryClient.invalidateQueries({ queryKey: ['admin', 'users'] });
                    
                    notifications.show({
                        title: 'Success',
                        message: 'User deleted successfully',
                        color: 'green'
                    });
                } catch (error) {
                    console.error('Error deleting user:', error);
                    notifications.show({
                        title: 'Error',
                        message: 'Failed to delete user. Please try again.',
                        color: 'red'
                    });
                } finally {
                    setDeletingUserId(null);
                }
            }
        });
    };

    const filteredUsers = useMemo<AdminUserDetails[]>(() => {
        if (!users) return [];
        
        return users.filter(user => {
            const matchesSearch = search === '' || 
                user.displayName.toLowerCase().includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase());
            
            const matchesRole = roleFilters.length === 0 || roleFilters.includes(user.role);
            
            return matchesSearch && matchesRole;
        });
    }, [users, search, roleFilters]);

    if (isLoading) {
        return (
            <Paper p="md" style={{ position: 'relative', minHeight: '200px' }}>
                <LoadingOverlay visible={true} />
            </Paper>
        );
    }

    if (!users?.length) {
        return <Text c="dimmed" ta="center">No users found</Text>;
    }

    const selectedUser = selectedUserId ? users?.find(u => u.id === selectedUserId) : null;

    return (
        <>
            <Modal 
                opened={roleModalOpen} 
                onClose={() => setRoleModalOpen(false)}
                title="Change User Role"
                size="sm"
            >
                <Select
                    label="Select new role"
                    defaultValue={selectedUser?.role}
                    data={[
                        { value: Role.Admin, label: 'Admin' },
                        { value: Role.Subscriber, label: 'Subscriber' },
                        { value: Role.User, label: 'User' },
                    ]}
                    onChange={(value) => handleRoleChange(value as Role)}
                    styles={{
                        input: { pointerEvents: 'auto' }
                    }}
                />
            </Modal>

            <Paper p="md" withBorder>
                <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
                    <TextInput
                        placeholder="Search users..."
                        value={search}
                        onChange={(e) => setSearch(e.currentTarget.value)}
                        style={{ flex: 1 }}
                    />
                    <MultiSelect
                        placeholder="Filter by roles"
                        value={roleFilters}
                        onChange={(values) => setRoleFilters(values as Role[])}
                        data={[
                            { value: 'Admin', label: 'Admin' },
                            { value: 'Subscriber', label: 'Subscriber' },
                            { value: 'User', label: 'User' },
                        ]}
                        style={{ width: '200px' }}
                        styles={{
                            input: { pointerEvents: 'auto' }
                        }}
                        clearable
                    />
                </div>

                <Table striped highlightOnHover>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Display Name</Table.Th>
                            <Table.Th>Email</Table.Th>
                            <Table.Th>Role</Table.Th>
                            <Table.Th>Pattern Views</Table.Th>
                            <Table.Th>Pattern Generations</Table.Th>
                            <Table.Th>Karma</Table.Th>
                            <Table.Th>Joined</Table.Th>
                            <Table.Th>Actions</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {filteredUsers.map((user) => (
                            <Table.Tr key={user.id}>
                                <Table.Td>{user.displayName}</Table.Td>
                                <Table.Td>{user.email}</Table.Td>
                                <Table.Td>
                                    <Badge 
                                        color={
                                            (pendingRoleChanges[user.id] || user.role) === Role.Admin ? 'blue' : 
                                            (pendingRoleChanges[user.id] || user.role) === Role.Subscriber ? 'green' : 
                                            'gray'
                                        }
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleRoleClick(user.id)}
                                    >
                                        {pendingRoleChanges[user.id] || user.role}
                                    </Badge>
                                </Table.Td>
                                <Table.Td>
                                    {user.maxViews === -1 ? (
                                        'Unlimited'
                                    ) : (
                                        `${user.viewedPatternIds?.length || 0}/${user.maxViews}`
                                    )}
                                </Table.Td>
                                <Table.Td>
                                    {user.maxGenerations === -1 ? (
                                        'Unlimited'
                                    ) : (
                                        `${user.patternGenerationCount}/${user.maxGenerations}`
                                    )}
                                </Table.Td>
                                <Table.Td>
                                    {editingKarmaUserId === user.id ? (
                                        <Group>
                                            <NumberInput
                                                value={karmaAmount}
                                                onChange={(value) => setKarmaAmount(Number(value))}
                                                min={1}
                                                placeholder="Amount"
                                                style={{ width: '80px' }}
                                                disabled={isSubmittingKarma}
                                            />
                                            <Button 
                                                size="xs" 
                                                onClick={() => handleKarmaSubmit(user.id)}
                                                loading={isSubmittingKarma}
                                            >
                                                Add
                                            </Button>
                                            <Button 
                                                size="xs" 
                                                variant="outline" 
                                                onClick={handleKarmaCancel}
                                                disabled={isSubmittingKarma}
                                            >
                                                Cancel
                                            </Button>
                                        </Group>
                                    ) : (
                                        <Text onClick={() => handleKarmaClick(user.id)} style={{ cursor: 'pointer' }}>
                                            {user.karma} / {user.lifetimeKarma}
                                        </Text>
                                    )}
                                </Table.Td>
                                <Table.Td>{new Date(user.createdAt).toLocaleDateString()}</Table.Td>
                                <Table.Td>
                                    <Group>
                                        {pendingRoleChanges[user.id] && (
                                            <Button
                                                size="xs"
                                                variant="light"
                                                color="blue"
                                                leftSection={<IconDeviceFloppy size={16} />}
                                                onClick={() => handleSaveChanges(user)}
                                            >
                                                Save
                                            </Button>
                                        )}
                                        <Button
                                            size="xs"
                                            variant="light"
                                            color="red"
                                            leftSection={<IconTrash size={16} />}
                                            onClick={() => handleDeleteUser(user.id, user.displayName)}
                                            loading={deletingUserId === user.id}
                                        >
                                            Delete
                                        </Button>
                                    </Group>
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </Table>
            </Paper>
        </>
    );
} 