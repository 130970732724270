import { Container, Title, Text, Stack, Center, Paper, Box, Alert } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { contentBox, gradientText, paperGradient, secondaryPaperGradient } from '../styles/common';
import { AuthContainer } from '../components/auth/AuthContainer';
import { IconCheck } from '@tabler/icons-react';

export function SignInPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const isLoading = queryClient.isFetching({ queryKey: ['auth', 'validate'] });
  const from = location.state?.from || '/';
  const verificationMessage = location.state?.message;

  // Check if we're coming from a "register" link
  const defaultMode = location.search.includes('register=true') ? 'register' : 'login';

  // This useEffect only redirects already logged-in users
  useEffect(() => {
    if (user && !isLoading) {
      navigate(from, { replace: true });
    }
  }, [user, isLoading, navigate, from]);

  // This function is only called after successful authentication
  const handleAuthSuccess = () => {
    navigate(from);
  };

  return (
    <Container size="sm" py="xl">
      <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
        <Center>
          <Stack align="center" gap="xl" style={{ width: '100%' }}>
            <Title 
              order={1}
              style={gradientText}
            >
              Welcome to The Fiber Collective
            </Title>
            
            {verificationMessage && (
              <Alert 
                title="Email Verified" 
                color="green" 
                icon={<IconCheck size={16} />}
                variant="filled"
                radius="md"
                withCloseButton
                w="100%"
              >
                {verificationMessage}
              </Alert>
            )}
            
            <Box style={contentBox}>
              <AuthContainer 
                defaultMode={defaultMode}
                onSuccess={handleAuthSuccess}
              />
            </Box>
            
            <Paper 
              shadow="sm" 
              p="xl" 
              radius="md" 
              withBorder 
              style={secondaryPaperGradient}
            >
              <Stack align="center" gap="md">
                <Title 
                  order={2} 
                  size="h2" 
                  ta="center"
                  style={gradientText}
                >
                  Subscribers Get More
                </Title>
                <Stack gap="xs" ta="left" style={{ width: '100%' }}>
                  <Text fw={500}>• Unlimited pattern views</Text>
                  <Text fw={500}>• 20 AI pattern generations per month</Text>
                  <Text fw={500}>• Ad-free experience</Text>
                </Stack>
                <Text size="sm" c="dimmed" ta="center" mt="xs">
                  Sign in above and then press the subscribe button to get started
                </Text>
              </Stack>
            </Paper>
          </Stack>
        </Center>
      </Paper>
    </Container>
  );
} 