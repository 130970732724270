import { Container, Title, Text, Paper, Stack } from '@mantine/core';
import { gradientText, paperGradient } from '../styles/common';

export function PrivacyPage() {
  return (
    <Container size="md" py="xl">
      <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
        <Stack gap="xl">
          <Title 
            order={1}
            style={gradientText}
          >
            Privacy Policy
          </Title>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              1. Information We Collect
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              We collect information you provide directly to us, including your name, email, 
              and profile information. We also collect data about your usage of our platform 
              and any patterns you generate or view.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              2. How We Use Your Information
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              We use the information we collect to provide and improve our services, 
              communicate with you, and ensure the security of our platform. This includes 
              pattern generation, view tracking, and subscription management.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              3. Information Sharing
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              We do not sell your personal information. We may share your information with 
              service providers who assist in our operations, or when required by law. Any 
              shared data is protected by confidentiality agreements.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              4. Data Security
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              We implement appropriate security measures to protect your personal information. 
              However, no method of transmission over the internet is 100% secure, and we 
              cannot guarantee absolute security.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              5. Cookies and Tracking
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              We use cookies and similar tracking technologies to improve your experience 
              on our platform. You can control cookie settings through your browser, but 
              disabling cookies may limit some features.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              6. Your Rights
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              You have the right to access, correct, or delete your personal information. 
              You can also request a copy of your data or opt out of certain data collection. 
              Contact us to exercise these rights.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              7. Children's Privacy
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              Our service is not intended for children under 13. We do not knowingly collect 
              personal information from children under 13. If you become aware that a child 
              has provided us with personal information, please contact us.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              8. Changes to Privacy Policy
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              We may update this privacy policy from time to time. We will notify you of any 
              significant changes through our platform or via email.
            </Text>
          </div>

          <div>
            <Title 
              order={2} 
              size="h3" 
              mb="md"
              style={gradientText}
            >
              9. Contact Us
            </Title>
            <Text size="lg" style={{ lineHeight: 1.6, color: '#2c3e50' }}>
              If you have questions about this privacy policy or your personal information, 
              please contact us at privacy@thefibercollective.com
            </Text>
          </div>

          <Text c="dimmed" size="sm">
            Last updated: {new Date().toLocaleDateString()}
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
} 