import { useState, useEffect } from 'react';
import { Container, Title, Text, Image, Button, Group, Paper, FileButton, List, Badge, Box, Textarea, Alert, Stack, Select } from '@mantine/core';
import { IconUpload, IconWand, IconAlertTriangle } from '@tabler/icons-react';
import { patternsApi } from '../api/patterns';
import { CrochetPattern, ProjectType } from '../types/api';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../contexts/AuthContext';
import { StripeCheckoutButton } from '../components/StripeCheckoutButton';
import { DIFFICULTY_OPTIONS } from '../utils/categoryUtils';
import { contentBox, imageShadow } from '../styles/common';
import { useEmailVerification } from '../hooks/useEmailVerification';

declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (siteKey: string, options: { action: string }) => Promise<string>;
    };
  }
}

export function GeneratePatternPage() {
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isImageTooLarge, setIsImageTooLarge] = useState(false);
  const [generatedPattern, setGeneratedPattern] = useState<CrochetPattern | null>(null);
  const [hint, setHint] = useState('');
  const [targetDifficulty, setTargetDifficulty] = useState<string | null>(null);
  const [projectType, setProjectType] = useState<ProjectType>(ProjectType.Crochet);
  const queryClient = useQueryClient();
  const { user } = useAuth();
  
  // Use our email verification hook
  const { checkVerification, VerificationAlert } = useEmailVerification({
    actionName: 'generating patterns'
  });

  useEffect(() => {
    // Load reCAPTCHA script
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const remainingGenerations = user ? (user.monthlyGenerationLimit - user.patternGenerationCount) : 0;

  const handleFileSelect = (selectedFile: File | null) => {
    if (selectedFile) {
      const maxSize = 307200; // 300KB in bytes
      const isTooLarge = selectedFile.size > maxSize;
      
      setFile(selectedFile);
      setIsImageTooLarge(isTooLarge);
      const url = URL.createObjectURL(selectedFile);
      setPreviewUrl(url);
      
      if (isTooLarge) {
        setError('Image must be less than 300KB');
      } else {
        setError(null);
      }
    }
  };

  const handleGenerate = async () => {
    if (!file) {
      setError('Please upload an image first');
      return;
    }

    if (isImageTooLarge) {
      setError('Please select a smaller image (less than 300KB)');
      return;
    }

    // Check if email is verified using our hook
    if (!checkVerification()) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const pattern = await patternsApi.generateFromImage(file, hint, targetDifficulty, projectType);
      setGeneratedPattern(pattern);
      queryClient.invalidateQueries({ queryKey: ['patterns'] });
    } catch (err: any) {
      if (err.message) {
        setError(err.message);
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container size="lg" py="xl">
      <Paper shadow="md" p="xl" radius="lg" withBorder style={{ background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)' }}>
        <Stack gap="lg">
          <Group justify="space-between" align="center" mb="lg">
            <Title 
              order={1} 
              style={{
                background: 'linear-gradient(45deg, #228BE6, #40C057)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                color: 'transparent',
                letterSpacing: '0.3px',
                fontSize: '2.5rem',
                fontWeight: 700
              }}
            >
              Generate Pattern
            </Title>
            <Group>
              <Stack gap={4} align="center">
                <Text fw={500} size="sm" color="blue" ta="center">
                  {remainingGenerations === Infinity ? (
                    'Unlimited generations available'
                  ) : (
                    `${remainingGenerations}/${user?.monthlyGenerationLimit} generations remaining`
                  )}
                </Text>
                {user?.role === 'User' && remainingGenerations <= 1 && (
                  <Text size="xs" c="dimmed" ta="center">
                    Subscribe to get 20 generations per month!
                  </Text>
                )}
              </Stack>
              {user?.role === 'User' && remainingGenerations < 2 && (
                <StripeCheckoutButton />
              )}
            </Group>
          </Group>
          <Text mb="lg" size="lg" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
            Upload a photo of any item to automatically generate a detailed pattern for crochet, knitting, or needlepoint projects.
          </Text>

          {!previewUrl && (
            <Alert 
              variant="light" 
              color="yellow" 
              mb="lg" 
              p="lg" 
              radius="md"
              icon={<IconAlertTriangle size={24} />}
              title={
                <Group align="center" gap="xs">
                  <Badge variant="filled" color="yellow">BETA</Badge>
                  <Text fw={700} size="lg">AI-Powered Pattern Generation</Text>
                </Group>
              }
              styles={{
                title: {
                  marginBottom: '0.8rem'
                },
                message: {
                  color: '#2c3e50'
                }
              }}
            >
              <Stack gap="md">
                <Text>
                  This is a beta feature that uses artificial intelligence to create patterns inspired by your inputs. Please note:
                </Text>
                <List>
                  <List.Item>The AI will generate patterns based on your image, hints, and selections, but results may vary from your exact expectations</List.Item>
                  <List.Item>For best results, provide clear images and detailed hints about your desired outcome</List.Item>
                  <List.Item>Review and test the pattern thoroughly before starting your project</List.Item>
                </List>
                <Text size="sm" c="dimmed">
                  We're continuously improving our AI to provide better and more accurate patterns. Your feedback helps us enhance this feature!
                </Text>
              </Stack>
            </Alert>
          )}

          <Text mb="xl" c="red.7" size="sm">
            Warning: Images containing inappropriate content or copyrighted material will be rejected and deleted. Pattern generation credits used for rejected images will not be refunded.
          </Text>

          {VerificationAlert}

          {!generatedPattern ? (
            <Box 
              style={{ 
                width: '100%',
                background: 'white',
                padding: '32px',
                borderRadius: '12px',
                boxShadow: '0 4px 20px rgba(34, 139, 230, 0.15)'
              }}
            >
              <Group justify="center" mb="xl">
                <Stack align="center" gap="md" style={{ width: '100%', maxWidth: '500px' }}>
                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <FileButton 
                      onChange={handleFileSelect} 
                      accept="image/png,image/jpeg,image/webp,image/gif"
                    >
                      {(props) => (
                        <Button 
                          {...props} 
                          leftSection={<IconUpload size={20} />}
                          size="md"
                          variant="gradient"
                          gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
                          styles={{
                            root: {
                              padding: '12px 24px',
                              height: '42px',
                              width: '250px'
                            },
                          }}
                        >
                          Upload Your Image
                        </Button>
                      )}
                    </FileButton>
                  </Box>
                  <Text size="sm" c="dimmed">Supported formats: PNG, JPEG, WebP, GIF (max 300KB)</Text>
                </Stack>
              </Group>

              {previewUrl && (
                <Stack gap="xl">
                  <Group grow align="flex-start" wrap="nowrap" gap="xl">
                    <Box style={{ flex: '1 1 60%' }}>
                      <Image
                        src={previewUrl}
                        height="auto"
                        w="100%"
                        fit="contain"
                        radius="md"
                        bg="transparent"
                        style={{
                          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                        }}
                      />
                    </Box>
                    <Box style={{ flex: '1 1 40%' }}>
                      <Stack gap="lg" align="center">
                        <Box style={{ width: '100%', maxWidth: '500px' }}>
                          <Select
                            label="Target Difficulty Level (Optional)"
                            placeholder="Choose difficulty level"
                            data={DIFFICULTY_OPTIONS}
                            value={targetDifficulty}
                            onChange={setTargetDifficulty}
                            size="md"
                            styles={{
                              input: { pointerEvents: 'auto' }
                            }}
                          />

                          <Select
                            label="Project Type"
                            placeholder="Choose project type"
                            data={[
                              { value: ProjectType.Crochet, label: 'Crochet' },
                              { value: ProjectType.Knit, label: 'Knit' },
                              { value: ProjectType.Needlepoint, label: 'Needle Point' }
                            ]}
                            value={projectType}
                            onChange={(value) => setProjectType(value as ProjectType)}
                            size="md"
                            styles={{
                              input: { pointerEvents: 'auto' }
                            }}
                            mt="md"
                          />

                          <Textarea
                            label="Generation Hint (Optional)"
                            description="Provide additional details or specific requirements"
                            placeholder="E.g., 'Make it suitable for beginners' or 'Include color work techniques'"
                            value={hint}
                            onChange={(event) => setHint(event.currentTarget.value)}
                            minRows={3}
                            size="md"
                            mt="md"
                          />

                          <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Button
                              onClick={handleGenerate}
                              size="md"
                              leftSection={<IconWand size={20} />}
                              loading={loading}
                              disabled={!file || isImageTooLarge}
                              variant="gradient"
                              gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
                              styles={{
                                root: {
                                  padding: '12px 24px',
                                  height: '42px',
                                  width: '250px'
                                }
                              }}
                              mt="xl"
                            >
                              Generate Pattern
                            </Button>
                          </Box>

                          {loading && (
                            <Alert
                              color="blue"
                              variant="light"
                              mt="md"
                              styles={{
                                message: {
                                  color: '#2c3e50',
                                  textAlign: 'center'
                                }
                              }}
                            >
                              Pattern generation can take up to a minute to complete. Please be patient and do not navigate away or refresh the page.
                            </Alert>
                          )}

                        {error && (
                          <Text color="red" size="sm">
                            {error}
                          </Text>
                        )}
                        </Box>
                      </Stack>
                    </Box>
                  </Group>
                </Stack>
              )}
            </Box>
          ) : (
            <Box 
              style={contentBox}
            >
              <Group justify="space-between" mb="xl">
                <Title 
                  order={2}
                  style={{
                    color: '#2c3e50',
                    letterSpacing: '0.3px',
                    fontSize: '2rem',
                    fontWeight: 700
                  }}
                >
                  {generatedPattern.name}
                </Title>
                <Button
                  onClick={() => {
                    setGeneratedPattern(null);
                    setFile(null);
                    setPreviewUrl(null);
                  }}
                  variant="gradient"
                  gradient={{ from: '#228BE6', to: '#40C057', deg: 45 }}
                  size="md"
                  styles={{
                    root: {
                      height: '42px',
                      padding: '12px 24px',
                      minWidth: '180px'
                    }
                  }}
                >
                  Generate Another
                </Button>
              </Group>

              {generatedPattern.imageUrl && (
                <Box maw="100%" m="auto">
                  <Image
                    src={generatedPattern.imageUrl}
                    height="auto"
                    w="100%"
                    fit="contain"
                    radius="md"
                    mb="xl"
                    mah={600}
                    bg="#f5f5f5"
                    style={imageShadow}
                  />
                </Box>
              )}

              <Group mb="xl">
                <Badge size="lg" color="pink" variant="light">
                  {generatedPattern.difficultyLevel}
                </Badge>
              </Group>

              <Text size="lg" mb="xl" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
                {generatedPattern.description}
              </Text>

              <Title 
                order={2} 
                mb="md"
                style={{
                  color: '#2c3e50',
                  letterSpacing: '0.3px'
                }}
              >
                Materials Needed
              </Title>
              <List spacing="xs" mb="xl" style={{ color: '#2c3e50', lineHeight: 1.6 }}>
                {generatedPattern.materialsNeeded?.map((material, index) => (
                  <List.Item key={index}>
                    {material.purchaseUrl ? (
                      <>
                        <Text 
                          component="a"
                          href={material.purchaseUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Group>
                            <Text fw={500}>{material.quantity} {material.name}</Text>
                            {material.notes && (
                              <Text size="sm" c="dimmed">({material.notes})</Text>
                            )}
                          </Group>
                        </Text>
                        <Text 
                          component="a" 
                          href={material.purchaseUrl} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          size="sm" 
                          c="blue"
                        >
                          Purchase Link
                        </Text>
                      </>
                    ) : (
                      <Group>
                        <Text fw={500}>{material.quantity} {material.name}</Text>
                        {material.notes && (
                          <Text size="sm" c="dimmed">({material.notes})</Text>
                        )}
                      </Group>
                    )}
                  </List.Item>
                ))}
              </List>

              <Title order={2} mb="md">Instructions</Title>
              {generatedPattern.sections?.map((section, index) => (
                <div key={index} style={{ marginBottom: '2rem' }}>
                  <Title order={3} mb="sm">{section.name}</Title>
                  <List spacing="xs" mb="xl">
                    {section.instructions.map((instruction: string, idx: number) => (
                      <List.Item key={idx}>
                        {instruction}
                      </List.Item>
                    ))}
                  </List>
                </div>
              ))}

              {generatedPattern.assemblyInstructions && generatedPattern.assemblyInstructions.length > 0 && (
                <>
                  <Title order={2} mb="md">Assembly Instructions</Title>
                  <List spacing="xs" mb="xl">
                    {generatedPattern.assemblyInstructions.map((instruction: string, idx: number) => (
                      <List.Item key={idx}>
                        {instruction}
                      </List.Item>
                    ))}
                  </List>
                </>
              )}

              {generatedPattern.specialNotes && generatedPattern.specialNotes.length > 0 && (
                <>
                  <Title order={2} mb="md">Special Notes</Title>
                  <List spacing="xs" mb="xl">
                    {generatedPattern.specialNotes.map((note: string, idx: number) => (
                      <List.Item key={idx}>
                        {note}
                      </List.Item>
                    ))}
                  </List>
                </>
              )}
            </Box>
          )}
        </Stack>
      </Paper>
    </Container>
  );
} 