import { createContext, useContext, useState } from 'react';
import type { Stripe } from '@stripe/stripe-js';

interface StripeContextType {
  stripe: Stripe | null;
  isLoading: boolean;
  initializeStripe: () => Promise<Stripe | null>;
}

const StripeContext = createContext<StripeContextType>({
  stripe: null,
  isLoading: false,
  initializeStripe: async () => null,
});

export const useStripe = () => useContext(StripeContext);

export function StripeProvider({ children }: { children: React.ReactNode }) {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const initializeStripe = async () => {
    if (stripe) return stripe;
    
    setIsLoading(true);
    try {
      // Dynamically import the loadStripe function only when needed
      const { loadStripe } = await import('@stripe/stripe-js');
      const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
      setStripe(stripeInstance);
      return stripeInstance;
    } catch (error) {
      console.error('Failed to load Stripe:', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StripeContext.Provider value={{ stripe, isLoading, initializeStripe }}>
      {children}
    </StripeContext.Provider>
  );
} 