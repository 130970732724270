import { BlogPost, BlogPostSummary } from '../types/Blog';
import { getAuthHeaders } from '../utils/authHeaders';
import { API_BASE_URL } from './config';
import { handleApiError } from '../utils/apiUtils';
import { getFullImageUrl } from '../utils/imageUtils';
import { PaginatedResult, PaginationParams } from '../types/common';

export const blogsApi = {
  getPaginated: async (params: PaginationParams): Promise<PaginatedResult<BlogPostSummary>> => {
    try {
      const url = new URL(`${API_BASE_URL}/api/blog`);
      url.searchParams.append('page', params.page.toString());
      url.searchParams.append('pageSize', params.pageSize.toString());
      if (params.tag) url.searchParams.append('tag', params.tag);

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const data = await response.json();
      return {
        items: data.items.map((item: BlogPostSummary) => ({
          ...item,
          imageUrl: item.imageUrl ? getFullImageUrl(item.imageUrl) : ''
        })),
        totalItems: data.totalItems
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getTags: async (): Promise<string[]> => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/blog/tags`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      return await response.json();
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getBySlug: async (slug: string): Promise<BlogPost> => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/blog/${slug}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      const blog = await response.json();
      return {
        ...blog,
        imageUrl: blog.imageUrl ? getFullImageUrl(blog.imageUrl) : ''
      };
    } catch (error) {
      throw handleApiError(error);
    }
  },

  create: async (data: FormData): Promise<BlogPost> => {
    try {
      const authHeaders = getAuthHeaders();
      // Create a new headers object without Content-Type
      const headers = Object.fromEntries(
        Object.entries(authHeaders).filter(([key]) => key.toLowerCase() !== 'content-type')
      );
      
      const response = await fetch(`${API_BASE_URL}/api/blog`, {
        method: 'POST',
        headers,
        body: data,
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      return await response.json();
    } catch (error) {
      throw handleApiError(error);
    }
  },

  update: async (slug: string, data: FormData): Promise<BlogPost> => {
    try {
      const authHeaders = getAuthHeaders();
      // Create a new headers object without Content-Type
      const headers = Object.fromEntries(
        Object.entries(authHeaders).filter(([key]) => key.toLowerCase() !== 'content-type')
      );
      
      const response = await fetch(`${API_BASE_URL}/api/blog/${slug}`, {
        method: 'PUT',
        headers,
        body: data,
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }

      return await response.json();
    } catch (error) {
      throw handleApiError(error);
    }
  },

  delete: async (slug: string): Promise<void> => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/blog/${slug}`, {
        method: 'DELETE',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw await handleApiError(response);
      }
    } catch (error) {
      throw handleApiError(error);
    }
  }
}; 