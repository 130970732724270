import React, { useEffect } from 'react';
import { Button } from '@mantine/core';
import { useAuth } from '../contexts/AuthContext';
import { notifications } from '@mantine/notifications';
import { useStripe } from '../contexts/StripeContext';

interface StripeCheckoutButtonProps {
  className?: string;
  fullWidth?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  inDrawer?: boolean;
}

export const StripeCheckoutButton: React.FC<StripeCheckoutButtonProps> = ({ 
  className, 
  fullWidth, 
  size = 'md',
  inDrawer = false 
}) => {
  const { user } = useAuth();
  const { stripe, isLoading, initializeStripe } = useStripe();

  useEffect(() => {
    initializeStripe();
  }, [initializeStripe]);

  const handleClick = async () => {
    try {
      if (!user?.stripeCustomerId) {
        notifications.show({
          title: 'Error',
          message: 'Unable to find your customer ID. Please try signing out and back in.',
          color: 'red',
        });
        return;
      }

      if (!stripe) {
        throw new Error('Stripe failed to initialize');
      }

      // Get Checkout Session ID from your API
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/stripe/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerId: user.stripeCustomerId }),
        credentials: 'include', // This is important for sending cookies
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { sessionId } = await response.json();

      // Redirect to Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (error) {
        console.error('Error:', error);
        notifications.show({
          title: 'Error',
          message: 'Failed to start checkout process. Please try again.',
          color: 'red',
        });
      }
    } catch (err) {
      console.error('Error:', err);
      notifications.show({
        title: 'Error',
        message: 'An unexpected error occurred. Please try again.',
        color: 'red',
      });
    }
  };

  return (
    <Button
      variant="filled"
      onClick={handleClick}
      className={className}
      disabled={isLoading}
      fullWidth={fullWidth}
      size={inDrawer ? size : undefined}
      styles={(theme) => ({
        root: inDrawer ? {
          background: 'linear-gradient(45deg, #228be6, #4dabf7)',
          boxShadow: '0 2px 8px rgba(34, 139, 230, 0.25)',
          fontWeight: 600,
          transition: 'all 0.2s ease',
          padding: '12px 16px',
          '&:hover': {
            background: 'linear-gradient(45deg, #1c7ed6, #339af0)',
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 12px rgba(34, 139, 230, 0.35)'
          }
        } : undefined
      })}
    >
      Subscribe Now
    </Button>
  );
};

export default StripeCheckoutButton; 