import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
    const { user, isLoading } = useAuth();
    const location = useLocation();

    if (isLoading) {
        return null; // Or a loading spinner if you prefer
    }

    if (!user) {
        return <Navigate to="/signin" state={{ from: location.pathname }} replace />;
    }

    return <>{children}</>;
} 