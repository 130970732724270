import { ActionIcon, Tooltip } from '@mantine/core';
import { IconHeart } from '@tabler/icons-react';
import { patternsApi } from '../api/patterns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';
import { useNavigate, useLocation } from 'react-router-dom';
import { useReCaptcha } from '../hooks/useReCaptcha';

interface HeartButtonProps {
    patternId: string;
    heartCount: number;
    isHearted: boolean;
    onHeartToggle: () => void;
}

export function HeartButton({ patternId, heartCount, isHearted, onHeartToggle }: HeartButtonProps) {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();
    const { verify, isVerifying } = useReCaptcha();

    const { mutate: toggleHeart } = useMutation({
        mutationFn: async () => {
            const token = await verify('toggle_heart');
            return patternsApi.toggleHeart(patternId, token);
        },
        onMutate: async () => {
            // Cancel outgoing refetches
            await queryClient.cancelQueries({ queryKey: ['patterns'] });
            await queryClient.cancelQueries({ queryKey: ['pattern', patternId] });

            // Optimistically update the heart state
            const newHeartState = {
                heartCount: isHearted ? heartCount - 1 : heartCount + 1,
                isHearted: !isHearted
            };

            // Update pattern detail
            queryClient.setQueryData(['pattern', patternId], (old: any) => ({
                ...old,
                ...newHeartState
            }));

            // Update patterns list
            queryClient.setQueriesData({ queryKey: ['patterns'] }, (oldData: any) => {
                if (!oldData) return oldData;

                // Handle paginated data
                if (oldData.pages) {
                    return {
                        ...oldData,
                        pages: oldData.pages.map((page: any) => {
                            if (!Array.isArray(page)) return page;
                            return page.map(pattern =>
                                pattern.id === patternId
                                    ? { ...pattern, heartCount: newHeartState.heartCount, isHearted: newHeartState.isHearted }
                                    : pattern
                            );
                        })
                    };
                }

                // Handle array data
                if (Array.isArray(oldData)) {
                    return oldData.map(pattern =>
                        pattern.id === patternId
                            ? { ...pattern, heartCount: newHeartState.heartCount, isHearted: newHeartState.isHearted }
                            : pattern
                    );
                }

                return oldData;
            });

            return { previousHeartState: { heartCount, isHearted } };
        },
        onError: (error: any, _, context) => {
            if (error.message === 'No authentication token found') {
                navigate('/signin', { 
                    state: { from: location.pathname, search: location.search } 
                });
                return;
            }

            // Revert optimistic update
            if (context) {
                queryClient.setQueryData(['pattern', patternId], (old: any) => ({
                    ...old,
                    ...context.previousHeartState
                }));
                queryClient.setQueriesData({ queryKey: ['patterns'] }, (oldData: any) => {
                    if (!oldData) return oldData;

                    // Handle paginated data
                    if (oldData.pages) {
                        return {
                            ...oldData,
                            pages: oldData.pages.map((page: any) => {
                                if (!Array.isArray(page)) return page;
                                return page.map(pattern =>
                                    pattern.id === patternId
                                        ? { ...pattern, heartCount: context.previousHeartState.heartCount, isHearted: context.previousHeartState.isHearted }
                                        : pattern
                                );
                            })
                        };
                    }

                    // Handle array data
                    if (Array.isArray(oldData)) {
                        return oldData.map(pattern =>
                            pattern.id === patternId
                                ? { ...pattern, heartCount: context.previousHeartState.heartCount, isHearted: context.previousHeartState.isHearted }
                                : pattern
                        );
                    }

                    return oldData;
                });
            }

            notifications.show({
                title: 'Error',
                message: error?.message || 'Failed to toggle heart',
                color: 'red'
            });
        },
        onSettled: () => {
            // Refetch to ensure consistency
            queryClient.invalidateQueries({ queryKey: ['patterns'] });
            queryClient.invalidateQueries({ queryKey: ['pattern', patternId] });
        }
    });

    const handleClick = async (e: React.MouseEvent) => {
        e.stopPropagation();
        if (isVerifying) return;
        toggleHeart();
    };

    return (
        <Tooltip label={isHearted ? 'Unlike' : 'Like'}>
            <ActionIcon
                onClick={handleClick}
                variant="subtle"
                color={isHearted ? 'red' : 'dark'}
                size="lg"
                style={{
                    color: isHearted ? undefined : '#1A1B1E',
                    textShadow: '0 0 3px rgba(255,255,255,0.5)'
                }}
            >
                <IconHeart
                    style={{ 
                        width: '70%', 
                        height: '70%',
                        filter: isHearted ? undefined : 'drop-shadow(0 0 2px rgba(255,255,255,0.5))'
                    }}
                    fill={isHearted ? 'currentColor' : 'none'}
                    stroke={isHearted ? undefined : '#1A1B1E'}
                    strokeWidth={2}
                />
                <span style={{ 
                    marginLeft: '4px', 
                    fontSize: '0.9em',
                    textShadow: isHearted ? undefined : '0 0 3px rgba(255,255,255,0.5)'
                }}>
                    {heartCount}
                </span>
            </ActionIcon>
        </Tooltip>
    );
} 