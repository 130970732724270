import { Stack, Text, Badge, Group, Button, Textarea, Loader, Paper } from '@mantine/core';
import { Comment } from '../types/Comment';
import { useAuth } from '../contexts/AuthContext';
import { patternsApi } from '../api/patterns';
import { commentsApi } from '../api/comments';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useReCaptcha } from '../hooks/useReCaptcha';
import { useQueryClient } from '@tanstack/react-query';

interface CommentListProps {
    patternId: string;
    isLoading?: boolean;
    onCommentApproved?: () => void;
    comments?: Comment[]; // approved comments
    pendingComments?: Comment[]; // pending comments
}

export function CommentList({ patternId, isLoading = false, onCommentApproved, comments = [], pendingComments = [] }: CommentListProps) {
    const { user } = useAuth();
    const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
    const [editText, setEditText] = useState('');
    
    // Replace the useEffect pattern ownership check with useQuery
    const { data: pattern } = useQuery({
        queryKey: ['pattern', patternId],
        queryFn: () => patternsApi.getById(patternId),
        enabled: !!patternId
    });

    // Use pattern data directly
    const isPatternOwner = pattern?.ownerId === user?.id;
    const isAdmin = user?.role === 'Admin';
    
    // Show all pending comments for admins and pattern owners, or just the user's own pending comments
    const visiblePendingComments = isAdmin || isPatternOwner
        ? pendingComments 
        : pendingComments.filter(comment => comment.commenterId === user?.id);
    
    // All users can see approved comments, plus their relevant pending comments
    const allComments = [...(comments || []), ...visiblePendingComments].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    const queryClient = useQueryClient();

    const approveMutation = useMutation({
        mutationFn: (commentId: string) => patternsApi.approveComment(patternId, commentId),
        onMutate: async (commentId) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey: ['comments', patternId] });

            // Snapshot the previous value
            const previousPendingComments = queryClient.getQueryData(['comments', patternId, 'pending']);
            const previousApprovedComments = queryClient.getQueryData(['comments', patternId, 'approved']);

            // Optimistically update pending comments
            queryClient.setQueryData(['comments', patternId, 'pending'], (old: Comment[] = []) => 
                old.filter(comment => comment.id !== commentId)
            );

            // Optimistically update approved comments
            queryClient.setQueryData(['comments', patternId, 'approved'], (old: Comment[] = []) => {
                const commentToApprove = pendingComments.find(c => c.id === commentId);
                if (!commentToApprove) return old;
                return [...old, commentToApprove];
            });

            return { previousPendingComments, previousApprovedComments };
        },
        onError: (error, _, context) => {
            // Revert optimistic update on error
            if (context?.previousPendingComments) {
                queryClient.setQueryData(['comments', patternId, 'pending'], context.previousPendingComments);
            }
            if (context?.previousApprovedComments) {
                queryClient.setQueryData(['comments', patternId, 'approved'], context.previousApprovedComments);
            }
            notifications.show({
                title: 'Error',
                message: 'Failed to approve comment',
                color: 'red'
            });
        },
        onSuccess: () => {
            notifications.show({
                title: 'Success',
                message: 'Comment approved successfully',
                color: 'green'
            });
            onCommentApproved?.();
        }
    });

    const { verify, isVerifying } = useReCaptcha();

    const deleteMutation = useMutation({
        mutationFn: async (commentId: string) => {
            const token = await verify('delete_comment');
            return commentsApi.deleteComment(patternId, commentId, token);
        },
        onMutate: async (commentId) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey: ['comments', patternId] });

            // Snapshot the previous value
            const previousPendingComments = queryClient.getQueryData(['comments', patternId, 'pending']);
            const previousApprovedComments = queryClient.getQueryData(['comments', patternId, 'approved']);

            // Optimistically update pending comments
            queryClient.setQueryData(['comments', patternId, 'pending'], (old: Comment[] = []) => 
                old.filter(comment => comment.id !== commentId)
            );

            // Optimistically update approved comments
            queryClient.setQueryData(['comments', patternId, 'approved'], (old: Comment[] = []) => 
                old.filter(comment => comment.id !== commentId)
            );

            return { previousPendingComments, previousApprovedComments };
        },
        onError: (error, _, context) => {
            // Revert optimistic update on error
            if (context?.previousPendingComments) {
                queryClient.setQueryData(['comments', patternId, 'pending'], context.previousPendingComments);
            }
            if (context?.previousApprovedComments) {
                queryClient.setQueryData(['comments', patternId, 'approved'], context.previousApprovedComments);
            }
            notifications.show({
                title: 'Error',
                message: 'Failed to delete comment',
                color: 'red'
            });
        },
        onSuccess: () => {
            notifications.show({
                title: 'Success',
                message: 'Comment deleted successfully',
                color: 'green'
            });
        }
    });

    const rejectMutation = useMutation({
        mutationFn: (commentId: string) => patternsApi.rejectComment(patternId, commentId),
        onMutate: async (commentId) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey: ['comments', patternId] });

            // Snapshot the previous value
            const previousPendingComments = queryClient.getQueryData(['comments', patternId, 'pending']);

            // Optimistically update pending comments
            queryClient.setQueryData(['comments', patternId, 'pending'], (old: Comment[] = []) => 
                old.filter(comment => comment.id !== commentId)
            );

            return { previousPendingComments };
        },
        onError: (error, _, context) => {
            // Revert optimistic update on error
            if (context?.previousPendingComments) {
                queryClient.setQueryData(['comments', patternId, 'pending'], context.previousPendingComments);
            }
            notifications.show({
                title: 'Error',
                message: 'Failed to reject comment',
                color: 'red'
            });
        },
        onSuccess: () => {
            notifications.show({
                title: 'Success',
                message: 'Comment rejected successfully',
                color: 'green'
            });
        }
    });

    const editMutation = useMutation({
        mutationFn: async ({ commentId, text }: { commentId: string; text: string }) => {
            const token = await verify('edit_comment');
            return patternsApi.editComment(patternId, commentId, text, token);
        },
        onMutate: async ({ commentId, text }) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey: ['comments', patternId] });

            // Snapshot the previous values
            const previousPendingComments = queryClient.getQueryData(['comments', patternId, 'pending']);
            const previousApprovedComments = queryClient.getQueryData(['comments', patternId, 'approved']);

            // Update pending comments if the comment is pending
            queryClient.setQueryData(['comments', patternId, 'pending'], (old: Comment[] = []) => {
                return old.map(comment => 
                    comment.id === commentId 
                        ? { ...comment, text }
                        : comment
                );
            });

            // Update approved comments if the comment is approved
            queryClient.setQueryData(['comments', patternId, 'approved'], (old: Comment[] = []) => {
                return old.map(comment => 
                    comment.id === commentId 
                        ? { ...comment, text }
                        : comment
                );
            });

            return { previousPendingComments, previousApprovedComments };
        },
        onError: (error, _, context) => {
            // Revert optimistic update on error
            if (context?.previousPendingComments) {
                queryClient.setQueryData(['comments', patternId, 'pending'], context.previousPendingComments);
            }
            if (context?.previousApprovedComments) {
                queryClient.setQueryData(['comments', patternId, 'approved'], context.previousApprovedComments);
            }
            notifications.show({
                title: 'Error',
                message: 'Failed to update comment',
                color: 'red'
            });
        },
        onSuccess: () => {
            notifications.show({
                title: 'Success',
                message: 'Comment updated successfully',
                color: 'green'
            });
            setEditingCommentId(null);
            setEditText('');
        }
    });

    const handleApprove = (commentId: string) => {
        approveMutation.mutate(commentId);
    };

    const handleDelete = (commentId: string) => {
        if (isVerifying) return;
        deleteMutation.mutate(commentId);
    };

    const handleReject = (commentId: string) => {
        rejectMutation.mutate(commentId);
    };

    const handleEdit = (comment: Comment) => {
        // Don't allow editing of optimistic comments
        if (comment.isOptimistic) {
            notifications.show({
                title: 'Info',
                message: 'Please wait for the comment to be saved before editing',
                color: 'blue'
            });
            return;
        }
        setEditingCommentId(comment.id);
        setEditText(comment.text);
    };

    const handleCancelEdit = () => {
        setEditingCommentId(null);
        setEditText('');
    };

    const handleSaveEdit = (commentId: string) => {
        if (editText.trim()) {
            editMutation.mutate({ commentId, text: editText });
        }
    };

    if (allComments.length === 0) {
        return <Text c="dimmed">No comments yet</Text>;
    }

    return (
        <Stack gap="md">
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {allComments.map(comment => {
                        const isPending = pendingComments.some(pc => pc.id === comment.id);
                        return (
                            <Paper key={comment.id} p="md" withBorder>
                                <Group justify="space-between" mb={4}>
                                    <div style={{ flex: 1 }}>
                                        {editingCommentId === comment.id ? (
                                            <Stack gap="sm">
                                                <Textarea
                                                    value={editText}
                                                    onChange={(e) => setEditText(e.currentTarget.value)}
                                                    disabled={editMutation.isPending || isVerifying}
                                                />
                                            </Stack>
                                        ) : (
                                            <Text size="sm" fw={500}>{comment.text}</Text>
                                        )}
                                        <Text size="xs" c="dimmed" mt={4}>
                                            By {comment.commenterDisplayName || comment.commenterName} • {new Date(comment.createdAt).toLocaleDateString()}
                                            {comment.isOptimistic && (
                                                <Text component="span" ml={6} size="xs" c="blue">(Saving...)</Text>
                                            )}
                                        </Text>
                                    </div>
                                    
                                    {isPending ? (
                                        <>
                                            <Badge color="yellow" variant="light" size="sm">
                                                Pending Approval
                                            </Badge>
                                            <Group>
                                                {(user?.role === 'Admin' || isPatternOwner) && (
                                                    <>
                                                        <Button
                                                            size="xs"
                                                            variant="outline"
                                                            color="green"
                                                            onClick={() => handleApprove(comment.id)}
                                                        >
                                                            Approve
                                                        </Button>
                                                        <Button
                                                            size="xs"
                                                            variant="outline"
                                                            color="red"
                                                            onClick={() => handleReject(comment.id)}
                                                        >
                                                            Reject
                                                        </Button>
                                                    </>
                                                )}
                                                
                                                {comment.commenterId === user?.id && (
                                                    <>
                                                        {editingCommentId === comment.id ? (
                                                            <>
                                                                <Button
                                                                    size="xs"
                                                                    variant="filled"
                                                                    color="blue"
                                                                    onClick={() => handleSaveEdit(comment.id)}
                                                                >
                                                                    Save
                                                                </Button>
                                                                <Button
                                                                    size="xs"
                                                                    variant="subtle"
                                                                    color="gray"
                                                                    onClick={handleCancelEdit}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Button
                                                                    size="xs"
                                                                    variant="subtle"
                                                                    color="blue"
                                                                    onClick={() => handleEdit(comment)}
                                                                >
                                                                    Edit
                                                                </Button>
                                                                <Button
                                                                    size="xs"
                                                                    variant="subtle"
                                                                    color="red"
                                                                    onClick={() => handleDelete(comment.id)}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </Group>
                                        </>
                                    ) : (
                                        <>
                                            {(user?.role === 'Admin' || comment.commenterId === user?.id) && (
                                                <>
                                                    {editingCommentId === comment.id ? (
                                                        <>
                                                            <Button
                                                                size="xs"
                                                                variant="filled"
                                                                color="blue"
                                                                onClick={() => handleSaveEdit(comment.id)}
                                                            >
                                                                Save
                                                            </Button>
                                                            <Button
                                                                size="xs"
                                                                variant="subtle"
                                                                color="gray"
                                                                onClick={handleCancelEdit}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                size="xs"
                                                                variant="subtle"
                                                                color="blue"
                                                                onClick={() => handleEdit(comment)}
                                                            >
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                size="xs"
                                                                variant="subtle"
                                                                color="red"
                                                                onClick={() => handleDelete(comment.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </Group>
                                {comment.imageUrl && (
                                    <img 
                                        src={comment.imageUrl} 
                                        alt="Comment attachment" 
                                        style={{ maxWidth: '200px', marginTop: '8px' }}
                                    />
                                )}
                            </Paper>
                        );
                    })}
                </>
            )}
        </Stack>
    );
} 