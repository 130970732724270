import { Button } from '@mantine/core';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../contexts/AuthContext';
import { notifications } from '@mantine/notifications';

interface GoogleSignInProps {
    onSuccess?: () => void;
}

export function GoogleSignIn({ onSuccess }: GoogleSignInProps) {
    const { signIn } = useAuth();

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            await signIn(tokenResponse.access_token);
            onSuccess?.();
        },
        onError: () => {
            notifications.show({
                title: 'Error',
                message: 'Failed to sign in with Google. Please try again.',
                color: 'red',
            });
        },
        scope: 'openid email profile',
        flow: 'implicit'
    });

    return (
        <Button
            onClick={() => login()}
            variant="default"
            size="md"
            leftSection={
                <img 
                    src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                    alt=""
                    style={{ 
                        width: 18,
                        height: 18
                    }}
                />
            }
            styles={{
                root: {
                    backgroundColor: 'white',
                    border: '1px solid #dadce0',
                    color: '#3c4043',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: 500,
                    '&:hover': {
                        backgroundColor: '#f8f9fa'
                    }
                }
            }}
        >
            Sign in with Google
        </Button>
    );
}