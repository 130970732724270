import { Container, Title, Text, Paper, Center, Stack, Box } from '@mantine/core';
import { StripeCheckoutButton } from '../components/StripeCheckoutButton';
import { gradientText, paperGradient, secondaryPaperGradient } from '../styles/common';

export function SubscriptionCanceledPage() {
    return (
        <Container size="sm" py="xl">
            <Paper shadow="md" p="xl" radius="lg" withBorder style={paperGradient}>
                <Center>
                    <Stack align="center" gap="xl">
                        <Title 
                            order={1}
                            style={gradientText}
                        >
                            Subscription Not Completed
                        </Title>
                        <Text size="lg" c="dimmed" ta="center">
                            Something went wrong with your subscription process. 
                            Please try again, and if the problem persists, contact our support team.
                        </Text>
                        
                        <Paper 
                            shadow="sm" 
                            p="xl" 
                            radius="md" 
                            withBorder 
                            style={secondaryPaperGradient}
                        >
                            <Stack align="center" gap="md">
                                <Title 
                                    order={2} 
                                    size="h2" 
                                    ta="center"
                                    style={gradientText}
                                >
                                    Subscriber Benefits
                                </Title>
                                <Stack gap="xs" ta="left" style={{ width: '100%' }}>
                                    <Text fw={500}>• Unlimited pattern views</Text>
                                    <Text fw={500}>• 20 AI pattern generations per month</Text>
                                    <Text fw={500}>• Ad-free experience</Text>
                                </Stack>
                                <Box mt="md">
                                    <StripeCheckoutButton />
                                </Box>
                            </Stack>
                        </Paper>
                    </Stack>
                </Center>
            </Paper>
        </Container>
    );
} 