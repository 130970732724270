import { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { User } from '../types/api';
import { authApi } from '../api/auth';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';
import { ApiError } from '../utils/apiUtils';

interface RegisterData {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    recaptchaToken?: string;
}

interface LoginData {
    email: string;
    password: string;
    recaptchaToken?: string;
}

interface AuthContextType {
    user: User | null;
    isLoading: boolean;
    isAuthenticating: boolean;
    error: Error | null;
    signIn: (accessToken: string) => Promise<void>;
    signOut: () => void;
    refreshUser: () => Promise<void>;
    register: (data: RegisterData) => Promise<void>;
    login: (data: LoginData) => Promise<void>;
    verifyEmail: (token: string, email: string) => Promise<void>;
    forgotPassword: (email: string, recaptchaToken?: string) => Promise<void>;
    resetPassword: (token: string, email: string, password: string, recaptchaToken?: string) => Promise<void>;
    resendVerificationEmail: (email: string, recaptchaToken?: string) => Promise<void>;
    isEmailVerified: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<User | null>(null);
    const [error] = useState<Error | null>(null);
    const queryClient = useQueryClient();

    const { isLoading: isValidating } = useQuery({
        queryKey: ['auth', 'validate'],
        queryFn: async () => {
            const token = localStorage.getItem('authToken');
            if (!token) return null;
            
            try {
                const userData = await authApi.validateToken(token);
                setUser(userData);
                return userData;
            } catch (error) {
                console.error('Token validation failed:', error);
                localStorage.removeItem('authToken');
                setUser(null);
                return null;
            }
        },
        staleTime: 1000 * 60 * 5, // Cache for 5 minutes
        retry: false
    });

    const { mutate: performSignIn, isPending: isGoogleSigningIn } = useMutation({
        mutationFn: authApi.signInWithGoogle,
        onSuccess: (response) => {
            localStorage.setItem('authToken', response.token);
            setUser(response.user);
            // Invalidate pattern queries to refresh heart states
            queryClient.invalidateQueries({ queryKey: ['patterns'] });
            queryClient.invalidateQueries({ queryKey: ['pattern'] });
        },
        onError: (error: ApiError) => {
            notifications.show({
                title: 'Error',
                message: error.message,
                color: 'red'
            });
        }
    });

    const { mutate: performRegister, isPending: isRegistering } = useMutation({
        mutationFn: authApi.register,
        onSuccess: (response) => {
            localStorage.setItem('authToken', response.token);
            setUser(response.user);
            // Invalidate pattern queries to refresh heart states
            queryClient.invalidateQueries({ queryKey: ['patterns'] });
            queryClient.invalidateQueries({ queryKey: ['pattern'] });
            
            notifications.show({
                title: 'Success',
                message: 'Registration successful! Please check your email to verify your account.',
                color: 'green'
            });
        },
        onError: (error: ApiError) => {
            notifications.show({
                title: 'Error',
                message: error.message,
                color: 'red'
            });
            // Clear user state on error
            setUser(null);
            localStorage.removeItem('authToken');
            throw error;
        }
    });

    const { mutate: performLogin, isPending: isLoggingIn } = useMutation({
        mutationFn: authApi.login,
        onSuccess: (response) => {
            localStorage.setItem('authToken', response.token);
            setUser(response.user);
            // Invalidate pattern queries to refresh heart states
            queryClient.invalidateQueries({ queryKey: ['patterns'] });
            queryClient.invalidateQueries({ queryKey: ['pattern'] });
        },
        onError: (error: ApiError) => {
            notifications.show({
                title: 'Error',
                message: error.message,
                color: 'red'
            });
            // Clear user state on login error
            setUser(null);
            localStorage.removeItem('authToken');
            // Ensure error is thrown so it can be caught
            throw error;
        }
    });

    const { mutate: performVerifyEmail } = useMutation({
        mutationFn: ({ email, token }: { email: string; token: string }) => 
            authApi.verifyEmail({ email, token }),
        onSuccess: () => {
            // Update user info to reflect verified status
            refreshUser();
            
            notifications.show({
                title: 'Success',
                message: 'Email verified successfully!',
                color: 'green'
            });
        },
        onError: (error: ApiError) => {
            notifications.show({
                title: 'Error',
                message: error.message,
                color: 'red'
            });
        }
    });

    const { mutate: performForgotPassword } = useMutation({
        mutationFn: ({ email, recaptchaToken }: { email: string; recaptchaToken?: string }) => 
            authApi.forgotPassword({ email, recaptchaToken }),
        onSuccess: () => {
            notifications.show({
                title: 'Success',
                message: 'If your email is registered, you will receive password reset instructions.',
                color: 'green'
            });
        },
        onError: (error: ApiError) => {
            notifications.show({
                title: 'Error',
                message: error.message,
                color: 'red'
            });
        }
    });

    const { mutate: performResetPassword } = useMutation({
        mutationFn: ({ email, token, password, recaptchaToken }: { email: string; token: string; password: string; recaptchaToken?: string }) => 
            authApi.resetPassword({ email, token, password, recaptchaToken }),
        onSuccess: () => {
            notifications.show({
                title: 'Success',
                message: 'Password reset successfully! You can now log in with your new password.',
                color: 'green'
            });
        },
        onError: (error: ApiError) => {
            notifications.show({
                title: 'Error',
                message: error.message,
                color: 'red'
            });
        }
    });

    const { mutate: performResendVerificationEmail } = useMutation({
        mutationFn: ({ email, recaptchaToken }: { email: string; recaptchaToken?: string }) => 
            authApi.resendVerificationEmail({ email, recaptchaToken }),
        onSuccess: () => {
            notifications.show({
                title: 'Success',
                message: 'If your email is registered and not yet verified, a new verification link has been sent.',
                color: 'green'
            });
        },
        onError: (error: ApiError) => {
            notifications.show({
                title: 'Error',
                message: error.message,
                color: 'red'
            });
        }
    });

    const refreshUser = useCallback(async (): Promise<void> => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) return;
            
            const userData = await authApi.validateToken(token);
            setUser(userData);
            
            // Invalidate pattern queries to refresh heart states
            queryClient.invalidateQueries({ queryKey: ['patterns'] });
            queryClient.invalidateQueries({ queryKey: ['pattern'] });
        } catch (error) {
            console.error('Failed to refresh user:', error);
            localStorage.removeItem('authToken');
            setUser(null);
        }
    }, [queryClient]);

    const signIn = useCallback(async (googleAccessToken: string): Promise<void> => {
        await performSignIn(googleAccessToken);
    }, [performSignIn]);

    const register = useCallback(async (data: RegisterData): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            performRegister(data, {
                onSuccess: () => resolve(),
                onError: (error) => reject(error)
            });
        });
    }, [performRegister]);

    const login = useCallback(async (data: LoginData): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            performLogin(data, {
                onSuccess: () => resolve(),
                onError: (error) => reject(error)
            });
        });
    }, [performLogin]);

    const verifyEmail = useCallback(async (token: string, email: string): Promise<void> => {
        performVerifyEmail({ token, email });
    }, [performVerifyEmail]);

    const forgotPassword = useCallback(async (email: string, recaptchaToken?: string): Promise<void> => {
        performForgotPassword({ email, recaptchaToken });
    }, [performForgotPassword]);

    const resetPassword = useCallback(async (token: string, email: string, password: string, recaptchaToken?: string): Promise<void> => {
        performResetPassword({ token, email, password, recaptchaToken });
    }, [performResetPassword]);

    const resendVerificationEmail = useCallback(async (email: string, recaptchaToken?: string): Promise<void> => {
        performResendVerificationEmail({ email, recaptchaToken });
    }, [performResendVerificationEmail]);

    const signOut = useCallback(() => {
        // Clear auth token
        localStorage.removeItem('authToken');
        // Clear user state
        setUser(null);
        // Clear all queries from the cache to prevent stale data
        queryClient.clear();
        // Optionally, you can be more specific and only remove auth-related queries:
        // queryClient.removeQueries({ queryKey: ['auth'] });
        // queryClient.removeQueries({ queryKey: ['user'] });
        // queryClient.removeQueries({ queryKey: ['patterns'] });
        // queryClient.removeQueries({ queryKey: ['pattern'] });
    }, [queryClient]);

    const isEmailVerified = useMemo(() => user?.isEmailVerified ?? false, [user]);

    const isAuthenticating = isGoogleSigningIn || isRegistering || isLoggingIn;

    const value = useMemo(
        () => ({
            user,
            isLoading: isValidating,
            isAuthenticating,
            error,
            signIn,
            signOut,
            refreshUser,
            register,
            login,
            verifyEmail,
            forgotPassword,
            resetPassword,
            resendVerificationEmail,
            isEmailVerified,
        }),
        [user, isValidating, isAuthenticating, error, signIn, signOut, refreshUser, register, 
         login, verifyEmail, forgotPassword, resetPassword, resendVerificationEmail, isEmailVerified]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// Custom hook that wraps useContext
export function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    const token = localStorage.getItem('authToken');
    
    // Get role from JWT claims using the same method as UserClaims
    const { role, patternGenerationCount, monthlyGenerationLimit, isEmailVerified } = useMemo(() => {
        if (!token) return { role: null, patternGenerationCount: 0, monthlyGenerationLimit: 0, isEmailVerified: false };
        try {
            const claims = JSON.parse(atob(token.split('.')[1]));
            return {
                role: claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
                patternGenerationCount: parseInt(claims['patternGenerationCount'] || '0', 10),
                monthlyGenerationLimit: claims['monthlyGenerationLimit'] === 'unlimited' ? Infinity : parseInt(claims['monthlyGenerationLimit'] || '0', 10),
                isEmailVerified: claims['emailVerified'] === 'True'
            };
        } catch (e) {
            console.error('Error parsing JWT:', e);
            return { role: null, patternGenerationCount: 0, monthlyGenerationLimit: 0, isEmailVerified: false };
        }
    }, [token]);

    return {
        ...context,
        isEmailVerified,
        user: context.user ? {
            ...context.user,
            role,
            patternGenerationCount,
            monthlyGenerationLimit
        } : null
    };
} 