import { useEffect } from 'react';
import { useInfiniteQuery, InfiniteData, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useIntersection } from '@mantine/hooks';
import { PaginatedResult, PaginationParams } from '../types/common';

type FetchFunction<T, P extends PaginationParams> = (params: P) => Promise<PaginatedResult<T>>;

interface UseInfinitePaginationOptions<T, P extends PaginationParams> {
  queryKey: unknown[];
  fetchFn: FetchFunction<T, P>;
  baseParams: P;
  pageSize: number;
  enabled?: boolean;
  staleTime?: number;
  queryOptions?: Omit<UseInfiniteQueryOptions<PaginatedResult<T>, Error, InfiniteData<PaginatedResult<T>>, PaginatedResult<T>, unknown[], number>, 'queryKey' | 'queryFn' | 'initialPageParam' | 'getNextPageParam'>;
}

export function useInfinitePagination<T, P extends PaginationParams>(options: UseInfinitePaginationOptions<T, P>) {
  const {
    queryKey,
    fetchFn,
    baseParams,
    pageSize,
    enabled = true,
    staleTime = 1000 * 60 * 5, // Default 5 minutes
    queryOptions = {}
  } = options;

  // Set up intersection observer for infinite scroll
  const { ref, entry } = useIntersection({
    threshold: 0.5,
  });

  // Set up infinite query
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 1 }) => fetchFn({
      ...baseParams,
      page: pageParam as number,
      pageSize
    }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      const itemsLoadedSoFar = pages.reduce((acc, page) => acc + page.items.length, 0);
      
      // If we've loaded fewer items than the total, there are more pages
      return itemsLoadedSoFar < lastPage.totalItems ? pages.length + 1 : undefined;
    },
    staleTime,
    enabled,
    ...queryOptions
  });

  // Destructure the needed properties from query for the dependency array
  const { hasNextPage, isFetching, fetchNextPage } = query;

  // Handle infinite scroll
  useEffect(() => {
    if (entry?.isIntersecting && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [entry?.isIntersecting, hasNextPage, isFetching, fetchNextPage]);

  // Get all items from all pages
  const items = query.data?.pages.flatMap(page => page.items) ?? [];

  return {
    ...query,
    items,
    ref
  };
} 