import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Title, 
  TextInput, 
  Textarea, 
  Button, 
  Group, 
  Stack, 
  Paper,
  Select,
  Text,
  LoadingOverlay,
  Image,
  Box,
  FileButton,
  Checkbox,
  Affix
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { patternsApi } from '../api/patterns';
import type { CrochetPattern } from '../types/api';
import { PatternCategory, ProjectType, Role } from '../types/api';
import { IconPlus, IconTrash, IconUpload } from '@tabler/icons-react';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from '../utils/apiUtils';
import { getFullImageUrl } from '../utils/imageUtils';
import { useAuth } from '../contexts/AuthContext';
import { useReCaptcha } from '../hooks/useReCaptcha';

interface UpdatePatternParams {
  pattern: CrochetPattern;
  recaptchaToken?: string;
}

export function EditPatternPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [pattern, setPattern] = useState<CrochetPattern | null>(null);
  const [isImageTooLarge, setIsImageTooLarge] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();
  const isAdmin = user?.role === Role.Admin;
  const { verify } = useReCaptcha();

  // Fetch pattern data
  const { data: fetchedPattern, isLoading, error: queryError } = useQuery({
    queryKey: ['pattern', id],
    queryFn: () => id ? patternsApi.getById(id) : null,
    enabled: !!id
  });

  // Set pattern and preview URL when data is fetched
  useEffect(() => {
    if (fetchedPattern) {
      console.log('Fetched pattern:', fetchedPattern);
      // No need to filter out fields
      setPattern(fetchedPattern);
      setPreviewUrl(getFullImageUrl(fetchedPattern.imageUrl));
    }
  }, [fetchedPattern]);

  // Update mutation
  const { mutate: updatePattern, isPending: isUpdating } = useMutation({
    mutationFn: ({ pattern, recaptchaToken }: UpdatePatternParams) => 
      patternsApi.update(id!, pattern, undefined, recaptchaToken),
    onSuccess: () => {
      // Invalidate queries to refetch fresh data
      queryClient.invalidateQueries({ queryKey: ['pattern', id] });
      queryClient.invalidateQueries({ queryKey: ['patterns'] });

      notifications.show({
        title: 'Success',
        message: 'Pattern updated successfully',
        color: 'green'
      });
      navigate(`/patterns/${id}`);
    },
    onError: (error: ApiError) => {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to update pattern',
        color: 'red'
      });
    }
  });

  // Update the image preview when a new file is selected
  useEffect(() => {
    if (imageFile) {
      const url = URL.createObjectURL(imageFile);
      setPreviewUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [imageFile]);

  const handleFileSelect = (selectedFile: File | null) => {
    if (selectedFile) {
      const maxSize = 307200; // 300KB in bytes
      const isTooLarge = selectedFile.size > maxSize;
      
      setImageFile(selectedFile);
      setIsImageTooLarge(isTooLarge);
      const url = URL.createObjectURL(selectedFile);
      setPreviewUrl(url);
      
      if (isTooLarge) {
        setError('Image must be less than 300KB');
      } else {
        setError(null);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!pattern || !id) return;

    if (isImageTooLarge) {
      setError('Please select a smaller image (less than 300KB)');
      return;
    }

    console.log('Submitting pattern:', pattern);
    console.log('Pattern properties:', Object.keys(pattern));
    
    try {
      // Get reCAPTCHA token
      const recaptchaToken = await verify('edit_pattern');
      
      if (imageFile) {
        await patternsApi.update(id, pattern, imageFile, recaptchaToken);
        // Need to manually invalidate queries and show notification when not using mutation
        queryClient.invalidateQueries({ queryKey: ['pattern', id] });
        queryClient.invalidateQueries({ queryKey: ['patterns'] });
        notifications.show({
          title: 'Success',
          message: 'Pattern updated successfully',
          color: 'green'
        });
        navigate(`/patterns/${id}`);
      } else {
        // Use the mutation which handles success notifications and query invalidation
        updatePattern({ pattern, recaptchaToken });
      }
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to update pattern',
        color: 'red'
      });
    }
  };

  if (isLoading) return <Container size="lg"><LoadingOverlay visible /></Container>;
  if (queryError) return <Container size="lg"><Text color="red">Error: {queryError instanceof Error ? queryError.message : 'Failed to load pattern'}</Text></Container>;
  if (!pattern) return <Container size="lg"><Text>Pattern not found</Text></Container>;

  return (
    <Container size="md" py="xl">
      <Paper shadow="md" p="xl" radius="md" withBorder>
        <LoadingOverlay visible={isLoading || isUpdating} />
        <form onSubmit={handleSubmit}>
          <Stack>
            <Group justify="space-between" align="center">
              <Title order={1}>Edit Pattern</Title>
              {isAdmin && (
                <Checkbox
                  label="Verified Pattern"
                  checked={pattern.isVerified}
                  onChange={(event) => setPattern({
                    ...pattern,
                    isVerified: event.currentTarget.checked
                  })}
                />
              )}
            </Group>

            <Box maw="100%" m="auto">
              {previewUrl && (
                <>
                  <Image
                    src={previewUrl}
                    alt="Pattern preview"
                    fit="contain"
                    h={200}
                  />
                  {error && (
                    <Text color="red" size="sm" ta="center" mt="xs">
                      {error}
                    </Text>
                  )}
                  <Group justify="center" mb="xl">
                    <FileButton 
                      onChange={handleFileSelect}
                      accept="image/png,image/jpeg,image/webp,image/gif"
                    >
                      {(props) => (
                        <Button 
                          {...props} 
                          variant="light"
                          leftSection={<IconUpload size={16} />}
                        >
                          Replace Image
                        </Button>
                      )}
                    </FileButton>
                  </Group>
                </>
              )}
            </Box>

            <TextInput
              label="Pattern Name"
              value={pattern.name}
              onChange={(e) => setPattern({ ...pattern, name: e.target.value })}
              required
            />

            <Textarea
              label="Description"
              value={pattern.description}
              onChange={(e) => setPattern({ ...pattern, description: e.target.value })}
              minRows={3}
              required
            />

            <Select
              label="Difficulty Level"
              value={pattern.difficultyLevel}
              onChange={(value) => setPattern({ ...pattern, difficultyLevel: value || '' })}
              data={['Beginner', 'Intermediate', 'Advanced', 'Expert']}
              required
            />

            <Select
              label="Category"
              value={pattern.category.toString()}
              onChange={(value) => setPattern({ 
                ...pattern, 
                category: value ? (Number(value) as unknown as PatternCategory) : PatternCategory.Other 
              })}
              data={[
                { value: '0', label: 'Apparel' },
                { value: '1', label: 'Accessories' },
                { value: '2', label: 'Toys' },
                { value: '3', label: 'Granny Squares' },
                { value: '4', label: 'Home Decor' },
                { value: '5', label: 'Other' }
              ]}
              required
            />

            <Select
              label="Project Type"
              value={String(pattern.projectType)}
              onChange={(value) => {
                console.log('Selected value:', value);
                setPattern({ 
                  ...pattern, 
                  projectType: Number(value) as unknown as ProjectType
                });
              }}
              data={[
                { value: '0', label: 'Crochet' },
                { value: '1', label: 'Knit' },
                { value: '2', label: 'Needlepoint' }
              ]}
              styles={{
                input: { pointerEvents: 'auto' }
              }}
              required
            />

            <Title order={2}>Materials</Title>
            {pattern.materialsNeeded?.map((material, index) => (
              <Group key={index} align="flex-start">
                <Group grow>
                  <TextInput
                    label="Material Name"
                    value={material.name}
                    onChange={(e) => {
                      const newMaterials = [...(pattern.materialsNeeded || [])];
                      newMaterials[index] = { ...material, name: e.target.value };
                      setPattern({ ...pattern, materialsNeeded: newMaterials });
                    }}
                  />
                  <TextInput
                    label="Quantity"
                    value={material.quantity}
                    onChange={(e) => {
                      const newMaterials = [...(pattern.materialsNeeded || [])];
                      newMaterials[index] = { ...material, quantity: e.target.value };
                      setPattern({ ...pattern, materialsNeeded: newMaterials });
                    }}
                  />
                  <TextInput
                    label="Notes"
                    value={material.notes || ''}
                    onChange={(e) => {
                      const newMaterials = [...(pattern.materialsNeeded || [])];
                      newMaterials[index] = { ...material, notes: e.target.value };
                      setPattern({ ...pattern, materialsNeeded: newMaterials });
                    }}
                  />
                </Group>
                <Button
                  variant="subtle"
                  color="red"
                  size="sm"
                  mt={25}
                  onClick={() => {
                    const newMaterials = [...(pattern.materialsNeeded || [])];
                    newMaterials.splice(index, 1);
                    setPattern({ ...pattern, materialsNeeded: newMaterials });
                  }}
                >
                  <IconTrash size={16} />
                </Button>
              </Group>
            ))}
            <Button
              variant="light"
              leftSection={<IconPlus size={16} />}
              onClick={() => {
                const newMaterials = [...(pattern.materialsNeeded || [])];
                newMaterials.push({ name: '', quantity: '', notes: '' });
                setPattern({ ...pattern, materialsNeeded: newMaterials });
              }}
            >
              Add Material
            </Button>

            <Title order={2}>Instructions</Title>
            {pattern.sections?.map((section, sectionIndex) => (
              <Stack key={sectionIndex}>
                <Group align="flex-start">
                  <TextInput
                    label="Section Name"
                    value={section.name}
                    style={{ flex: 1 }}
                    onChange={(e) => {
                      const newSections = [...(pattern.sections || [])];
                      newSections[sectionIndex] = { ...section, name: e.target.value };
                      setPattern({ ...pattern, sections: newSections });
                    }}
                  />
                  <Button
                    variant="subtle"
                    color="red"
                    size="sm"
                    mt={25}
                    onClick={() => {
                      const newSections = [...(pattern.sections || [])];
                      newSections.splice(sectionIndex, 1);
                      setPattern({ ...pattern, sections: newSections });
                    }}
                  >
                    <IconTrash size={16} />
                  </Button>
                </Group>
                <Textarea
                  label="Instructions"
                  value={section.instructions.join('\n')}
                  onChange={(e) => {
                    const newSections = [...(pattern.sections || [])];
                    newSections[sectionIndex] = {
                      ...section,
                      instructions: e.target.value.split('\n')
                    };
                    setPattern({ ...pattern, sections: newSections });
                  }}
                  minRows={3}
                />
              </Stack>
            ))}
            <Button
              variant="light"
              leftSection={<IconPlus size={16} />}
              onClick={() => {
                const newSections = [...(pattern.sections || [])];
                newSections.push({ name: '', instructions: [''] });
                setPattern({ ...pattern, sections: newSections });
              }}
            >
              Add Section
            </Button>

            {pattern.assemblyInstructions && (
              <>
                <Title order={2}>Assembly Instructions</Title>
                <Textarea
                  value={pattern.assemblyInstructions.join('\n')}
                  onChange={(e) => setPattern({
                    ...pattern,
                    assemblyInstructions: e.target.value.split('\n')
                  })}
                  minRows={3}
                />
              </>
            )}

            {pattern.specialNotes && (
              <>
                <Title order={2}>Special Notes</Title>
                <Textarea
                  value={pattern.specialNotes.join('\n')}
                  onChange={(e) => setPattern({
                    ...pattern,
                    specialNotes: e.target.value.split('\n')
                  })}
                  minRows={3}
                />
              </>
            )}

            {/* Add padding at the bottom to ensure content isn't hidden behind the floating buttons */}
            <Box pb={80} />
          </Stack>
        </form>
      </Paper>
      
      {/* Floating action buttons */}
      <Affix position={{ bottom: 20, right: 120 }}>
        <Paper p="md" shadow="lg" radius="md" withBorder style={{ background: 'white' }}>
          <Group gap="md">
            <Button 
              variant="outline" 
              onClick={() => navigate(`/patterns/${id}`)}
              size="md"
            >
              Cancel
            </Button>
            <Button 
              onClick={handleSubmit}
              loading={isUpdating}
              disabled={isImageTooLarge}
              size="md"
              color="blue"
            >
              Save Changes
            </Button>
          </Group>
        </Paper>
      </Affix>
    </Container>
  );
} 