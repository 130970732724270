import axios from 'axios';
import { API_BASE_URL } from './config';
import { User } from '../types/api';
import { getAuthHeaders } from '../utils/authHeaders';
import { handleApiError } from '../utils/apiUtils';

interface UpdateProfileRequest {
    displayName: string;
    email: string;
    profileImageUrl?: string;
}

export const userApi = {
    getProfile: async (): Promise<User> => {
        try {
            const response = await axios.get<User>(
                `${API_BASE_URL}/api/user/me`,
                { headers: getAuthHeaders() }
            );
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },

    updateProfile: async (data: UpdateProfileRequest): Promise<User> => {
        try {
            const response = await axios.put<User>(
                `${API_BASE_URL}/api/user/me`,
                data,
                { headers: getAuthHeaders() }
            );
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    }
}; 